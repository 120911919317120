import React, { createContext, ReactNode, useEffect, useReducer, useState } from 'react';

import { CHANGE_THEME_MODE, SHOW_FULL_SCREEN_LOADER } from './types';
import { TemplateContextStateInterface } from './interfaces';
import reducerTemplate from './reducer';
import { LOCAL_STORAGE, TEMPLATE_CONFIG } from '../../config';
import { TemplateConfig, ThemeMode, ThemeType } from '../../types';

const initialState: TemplateContextStateInterface = {
    themeMode: ThemeType.LIGHT,
    showingFullScreenLoader: true
};

export const TemplateContext = createContext<TemplateContextStateInterface>(initialState);

const TemplateProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducerTemplate, initialState);
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        let config: TemplateConfig = TEMPLATE_CONFIG;
        const templateConfig = localStorage.getItem(LOCAL_STORAGE.TEMPLATE_CONFIG);
        if (templateConfig) {
            config = JSON.parse(templateConfig);
        }

        localStorage.setItem(LOCAL_STORAGE.TEMPLATE_CONFIG, JSON.stringify(config));
        changeBodyAttribute('data-layout-mode', config.THEME_MODE);
        setThemeMode(config.THEME_MODE as ThemeMode);
        showFullScreenLoader(false);
    }, []);

    useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    const showFullScreenLoader = (show: boolean) => {
        dispatch({
            type: SHOW_FULL_SCREEN_LOADER,
            payload: show
        });
    };

    const toggleSidebar = () => {
        const body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle('sidebar-enable');
        } else {
            body.classList.toggle('vertical-collpsed');
            body.classList.toggle('sidebar-enable');
        }
    };

    function toggleFullScreen() {
        if (isFullscreen) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    }

    const setThemeMode = (themeMode: ThemeMode) => {
        changeBodyAttribute('data-layout-mode', themeMode);
        setLocalStorageThemeMode(themeMode);
        dispatch({ type: CHANGE_THEME_MODE, payload: themeMode });
    };

    const changeBodyAttribute = (attribute: string, value: string) => {
        if (document.body) document.body.setAttribute(attribute, value);
        return true;
    };

    const getLocalStorageThemeMode = (): ThemeType => {
        const localTemplateConfig = localStorage.getItem(LOCAL_STORAGE.TEMPLATE_CONFIG);
        if (localTemplateConfig) {
            const templateConfig: TemplateConfig = JSON.parse(localTemplateConfig);
            return (templateConfig.THEME_MODE as ThemeType) ?? ThemeType.LIGHT;
        }
        return ThemeType.LIGHT;
    };

    const setLocalStorageThemeMode = (themeMode: ThemeMode) => {
        const localTemplateConfig = localStorage.getItem(LOCAL_STORAGE.TEMPLATE_CONFIG);
        if (localTemplateConfig) {
            const templateConfig: TemplateConfig = JSON.parse(localTemplateConfig);
            templateConfig.THEME_MODE = themeMode;
            localStorage.setItem(LOCAL_STORAGE.TEMPLATE_CONFIG, JSON.stringify(templateConfig));
        }
    };

    return (
        <TemplateContext.Provider
            value={{
                themeMode: state.themeMode,
                setThemeMode,
                toggleSidebar,
                toggleFullScreen,
                showingFullScreenLoader: state.showingFullScreenLoader,
                showFullScreenLoader
            }}
        >
            {children}
        </TemplateContext.Provider>
    );
};

export default TemplateProvider;
