import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    onClick: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
};

const ButtonCancelForm = ({
    onClick,
    title = 'Cancelar',
    loading = false,
    locked = false,
    message = 'No se puede cancelar este registro.'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !loading ? (
        <button
            type="button"
            className="waves-effect btn btn-outline-light btn-smm"
            onClick={locked ? showLockedMessage : onClick}
        >
            <i className="fa fa-times" /> {title}
        </button>
    ) : (
        <button type="button" className="waves-effect btn btn-outline-light btn-smm disabled">
            <i className="fa fa-times" /> {title}
        </button>
    );
};

export default ButtonCancelForm;
