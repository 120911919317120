import React from 'react';
import useFieldError from '../../hooks/useFieldError';
export type FieldSelectOption = {
    value: string | number;
    label: string;
};

type Props = {
    label?: string;
    name: string;
    nameFieldError?: string;
    options: FieldSelectOption[];
    id?: string;
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    errors?: object;
    disabled?: boolean;
    required?: boolean;
    className?: string;
    showSelectMessage?: boolean;
};

const FieldSelect = ({
    label,
    id,
    name,
    nameFieldError,
    options,
    value,
    onChange,
    errors,
    disabled,
    required,
    className,
    showSelectMessage = true
}: Props) => {
    const { error, clearError } = useFieldError(nameFieldError ?? name, errors);

    return (
        <div className={`${className ? className : ''} ${error ? 'has-danger' : ''}`}>
            {label && (
                <label htmlFor={id ?? name}>
                    {label} {required ? '*' : ''}
                </label>
            )}

            <select
                className={`form-select ${error ? 'is-invalid' : ''}`}
                id={id ?? name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={clearError}
                disabled={disabled}
                required={required}
            >
                {showSelectMessage && <option value="">Seleccione una opción</option>}
                {options.map((option: FieldSelectOption) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export default FieldSelect;
