import { useState } from 'react';
// import { Notification } from '../../app/models/Notification';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import { NotificationFetchRepository } from '../../app/services/NotificationFetchRepository';

const useNotificationsService = () => {
    const repository = new NotificationFetchRepository();

    const [fetchingGetNotifications, setFetchingGetNotifications] = useState(false);
    const [fetchingShowNotification, setFetchingShowNotification] = useState(false);
    const [fetchingGetNotReadNotifications, setFetchingGetNotReadNotifications] = useState(false);
    const [fetchingMarkAsRead, setFetchingMarkAsRead] = useState(false);
    const [fetchingMarkAllAsRead, setFetchingMarkAllAsRead] = useState(false);

    const getNotifications = async (events: ServiceEvents = {}) => {
        setFetchingGetNotifications(true);
        try {
            return repository.all(events).finally(() => {
                setFetchingGetNotifications(false);
            });
        } catch (error) {
            setFetchingGetNotifications(false);
        }
    };

    const showNotification = async (id: number, events: ServiceEvents = {}) => {
        setFetchingShowNotification(true);
        try {
            return repository.show(id, events).finally(() => {
                setFetchingShowNotification(false);
            });
        } catch (error) {
            setFetchingShowNotification(false);
        }
    };

    const getNotReadNotifications = async (events: ServiceEvents = {}) => {
        setFetchingGetNotReadNotifications(true);
        try {
            return repository.getNotifications(events).finally(() => {
                setFetchingGetNotReadNotifications(false);
            });
        } catch (error) {
            setFetchingGetNotReadNotifications(false);
        }
    };

    const markAsRead = async (id: number, events: ServiceEvents = {}) => {
        setFetchingMarkAsRead(true);
        try {
            return repository.markAsRead(id, events).finally(() => {
                setFetchingMarkAsRead(false);
            });
        } catch (error) {
            setFetchingMarkAsRead(false);
        }
    };

    const markAllAsRead = async (events: ServiceEvents = {}) => {
        setFetchingMarkAllAsRead(true);
        try {
            return repository.markAllAsRead(events).finally(() => {
                setFetchingMarkAllAsRead(false);
            });
        } catch (error) {
            setFetchingMarkAllAsRead(false);
        }
    };


    return {
        fetchingGetNotifications,
        fetchingShowNotification,
        fetchingGetNotReadNotifications,
        fetchingMarkAsRead,
        fetchingMarkAllAsRead,

        getNotifications,
        showNotification,
        getNotReadNotifications,
        markAsRead,
        markAllAsRead

    };
};

export default useNotificationsService;
