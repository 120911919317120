import {
    AuthContextState,
    ReducerAction,
    SET_AUTH,
    SET_AUTH_TOKEN,
    SET_PERMISSIONS,
    SET_ROLES
} from './types';

const reducerAuth = (state: AuthContextState, action: ReducerAction) => {
    switch (action.type) {
        case SET_AUTH:
            return {
                ...state,
                authUser: action.payload
            };
        case SET_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload
            };

        case SET_ROLES:
            return {
                ...state,
                roles: action.payload
            };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            };

        default:
            return state;
    }
};
export default reducerAuth;
