import React, { ChangeEvent, useEffect, useState } from 'react';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import Loading from '../../../template/components/ui/Loading';
import { ContractCreateForm, initialContractCreateForm } from '../../../app/models/Contract';
import { CostCenter } from '../../../app/models/CostCenter';
import { Currency } from '../../../app/models/Currency';
import { Lessor } from '../../../app/models/Lessor';
import useContractsService from '../../../hooks/services/useContractsService';
import DefaultCard from '../../../components/default/DefaultCard';
import ContractDataForm from '../components/ContractDataForm';
import RuleRentDataForm from '../components/RuleRentDataForm';
import RuleMonthlySaleDataForm from '../components/RuleMonthlySaleDataForm';
import WarrantyTypeDataForm from '../components/WarrantyTypeDataForm';
import OtherExpenseDataForm from '../components/OtherExpenseDataForm';
import ContractServiceDataForm from '../components/ContractServiceDataForm';
import ExitClauseDataForm from '../components/ExitClauseDataForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ContractDocumentsDataForm from '../components/ContractDocumentDataForm';
import { ContractEndpoint } from '../../../app/services/APIConnection';
import { ServiceResponse } from '../../../packages/generic-fetch/types';
import { LOCAL_STORAGE } from '../../../template/config';

type CustomChangeEvent = {
    target: {
        name: string;
        value: any;
    };
};

const ContractCreatePage = () => {
    const breadcrumbs = [
        {
            name: 'Contratos',
            url: '/contracts',
            isActive: false
        },
        {
            name: 'Createar contrato',
            url: `/contracts/create`,
            isActive: true
        }
    ];

    const history = useHistory();

    const { fetchingCreateContract, createContract, storeContract, fetchingStoreContract } =
        useContractsService();
    const [errors, setErrors] = useState<object>({});
    const [dataForm, setDataForm] = useState<ContractCreateForm>(initialContractCreateForm);
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [lessors, setLessors] = useState<Lessor[]>([]);
    const [services, setServices] = useState<any[]>([]);
    const [banks, setBanks] = useState<any[]>([]);
    const [typeMonthlySales, setTypeMonthlySales] = useState<any[]>([]);
    const [fetchingStoreDocumentContract, setFetchingStoreDocumentContract] =
        useState<boolean>(false);
    const [reformatNumber, setReformatNumber] = useState<number>(0);

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createContract({
            onSuccess: (response) => {
                setCostCenters(response.data.cost_centers);
                setCurrencies(response.data.currencies);
                setLessors(response.data.lessors);
                setServices(response.data.services);
                setBanks(response.data.banks);
                setTypeMonthlySales(response.data.type_monthly_sales);
                setDataForm({
                    ...dataForm,
                    other_expenses: response.data.other_expenses,
                    warranty_types: response.data.warranty_types,
                    exit_clauses: response.data.exit_clauses,
                    contract_services: response.data.contract_services
                });
            }
        }).then();
    };

    const store = () => {
        if (dataForm.rule_rents) {
            dataForm.rule_rents = dataForm.rule_rents.map((item, index) => {
                item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                return item;
            });
        }

        if (dataForm.rule_monthly_sales) {
            dataForm.rule_monthly_sales = dataForm.rule_monthly_sales.map((item, index) => {
                item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                item.monthly_rent_percentage = String(item.monthly_rent_percentage)
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                return item;
            });
        }

        if (dataForm.warranty_types) {
            dataForm.warranty_types = dataForm.warranty_types.map((item, index) => {
                if (item.amount) {
                    item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                }
                return item;
            });
        }

        if (dataForm.other_expenses) {
            dataForm.other_expenses = dataForm.other_expenses.map((item, index) => {
                if (item.amount) {
                    item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                }
                return item;
            });
        }

        if (dataForm.exit_clauses) {
            dataForm.exit_clauses = dataForm.exit_clauses.map((item, index) => {
                if (item.amount) {
                    item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                }
                return item;
            });
        }

        storeContract(dataForm, {
            onSuccess: (response) => {
                setFetchingStoreDocumentContract(true);
                const formData = new FormData();
                if (dataForm.document_contracts) {
                    dataForm.document_contracts.forEach((document) => {
                        if (document.file) {
                            formData.append('file[]', document.file as Blob);
                        }
                    });
                } else {
                    toast.success('Contrato creado con éxito');
                    history.push('/contracts');
                    return;
                }

                try {
                    fetch(ContractEndpoint.updateDocuments(response.data), {
                        method: 'POST',
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                LOCAL_STORAGE.AUTH_TOKEN
                            )}`
                        }
                    })
                        .then(async (response) => {
                            const result: ServiceResponse = await response.json();

                            toast.success('Contrato creado con éxito');
                            history.push('/contracts');
                        })
                        .then(() => {
                            setFetchingStoreDocumentContract(false);
                        });
                } catch (e) {
                    setFetchingStoreDocumentContract(false);
                }
            },
            onError: (response) => {
                setReformatNumber(reformatNumber + 1);
                toast.error(response.message);
            },
            onFieldError: (response) => {
                setReformatNumber(reformatNumber + 1);
                setErrors(response.data.errors);
            }
        });
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | CustomChangeEvent
    ) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        });
    };


    const handleArrayChange = (nameAttribute: string, updatedArray: any[]) => {
        setDataForm({
            ...dataForm,
            [nameAttribute]: updatedArray
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Contratos" breadcrumbs={breadcrumbs} />

            {fetchingCreateContract || fetchingStoreDocumentContract ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="DATOS DEL CONTRATO">
                                <ContractDataForm
                                    costCenters={costCenters}
                                    lessors={lessors}
                                    currencies={currencies}
                                    dataForm={dataForm}
                                    handleChange={handleChange}
                                    disabled={fetchingStoreContract}
                                    errors={errors}
                                />
                            </DefaultCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="ACTUALIZACIÓN DE ARRIENDO POR PERIODO ">
                                <RuleRentDataForm
                                    onUpdateRuleRents={(updatedRuleRents) =>
                                        handleArrayChange('rule_rents', updatedRuleRents)
                                    }
                                    currencies={currencies}
                                    ruleRents={dataForm.rule_rents ?? []}
                                    disabled={fetchingStoreContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="ACTUALIZACIÓN DE VPM">
                                <RuleMonthlySaleDataForm
                                    onUpdateRuleMonthlySales={(onUpdateRuleMonthlySales) =>
                                        handleArrayChange(
                                            'rule_monthly_sales',
                                            onUpdateRuleMonthlySales
                                        )
                                    }
                                    currencies={currencies}
                                    typeMonthlySales={typeMonthlySales ?? []}
                                    ruleMonthlySales={dataForm.rule_monthly_sales ?? []}
                                    disabled={fetchingStoreContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}

                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="TIPOS DE GARANTÍA">
                                <WarrantyTypeDataForm
                                    onUpdateWarrantyTypes={(updateWarrantyTypes) =>
                                        handleArrayChange('warranty_types', updateWarrantyTypes)
                                    }
                                    currencies={currencies}
                                    banks={banks}
                                    warrantyTypes={dataForm.warranty_types ?? []}
                                    disabled={fetchingStoreContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="OTROS GASTOS">
                                <OtherExpenseDataForm
                                    onUpdateOtherExpenses={(updateOtherExpenses) =>
                                        handleArrayChange('other_expenses', updateOtherExpenses)
                                    }
                                    currencies={currencies}
                                    otherExpenses={dataForm.other_expenses ?? []}
                                    disabled={fetchingStoreContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}

                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="GASTOS SERVICIOS">
                                <ContractServiceDataForm
                                    onUpdateContractServices={(updateContractServices) =>
                                        handleArrayChange(
                                            'contract_services',
                                            updateContractServices
                                        )
                                    }
                                    contractServices={dataForm.contract_services ?? []}
                                    disabled={fetchingStoreContract}
                                    errors={errors}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="CLAUSULAS DE SALIDA">
                                <ExitClauseDataForm
                                    onUpdateExitClauses={(updateExitClauses) =>
                                        handleArrayChange('exit_clauses', updateExitClauses)
                                    }
                                    currencies={currencies}
                                    exitClauses={dataForm.exit_clauses ?? []}
                                    disabled={fetchingStoreContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="DOCUMENTOS">
                                <ContractDocumentsDataForm
                                    contractDocuments={dataForm.document_contracts ?? []}
                                    disabled={false}
                                    contractId={-1}
                                    errors={errors}
                                    setContractDocuments={(updateDocuments) =>
                                        handleArrayChange('document_contracts', updateDocuments)
                                    }
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div>
                        <div className="row mt-auto justify-content-end mb-2">
                            <div className="col-auto">
                                <ButtonSaveForm onClick={store} loading={fetchingStoreContract} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ContractCreatePage;
