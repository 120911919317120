import React from 'react';
import useFieldError from '../../hooks/useFieldError';
import { Form } from 'react-bootstrap';

type Props = {
    label?: string;
    name: string;
    nameFieldError?: string;
    id?: string;
    type?: 'text' | 'email' | 'password';
    placeholder?: string;
    value?: boolean;
    labelRight?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errors?: object;
    disabled?: boolean;
    required?: boolean;
    className?: string;
    onFocusOut?: (e?: React.FocusEvent<HTMLInputElement>) => void;
};

const FieldSwitch = ({
    type,
    label,
    id,
    name,
    nameFieldError,
    placeholder,
    labelRight,
    value,
    onChange,
    errors,
    disabled,
    required,
    className,
    onFocusOut
}: Props) => {
    const { error, clearError } = useFieldError(nameFieldError ?? name, errors);

    return (
        <div className={`${className ? className : ''} ${error ? 'has-danger' : ''}`}>
            {label && (
                <label htmlFor={id ?? name}>
                    {label} {required ? '*' : ''}
                </label>
            )}

            <Form>
                <Form.Check
                    type="switch"
                    className={`mt-2 ${error ? 'is-invalid' : ''}`}
                    id="custom-switch"
                    label={labelRight}
                    checked={value}
                    onChange={onChange}
                    name={name}
                    onBlur={() => {
                        clearError();
                        onFocusOut && onFocusOut();
                    }}
                    disabled={disabled}
                    required={required}
                />
            </Form>
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export default FieldSwitch;
