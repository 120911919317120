// src/contexts/notifications/NotificationContext.tsx
import React, { createContext, useReducer, ReactNode, useEffect } from 'react';
import reducer from './reducer';
import { NotificationContextState, SET_NOTIFICATIONS, MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_ALL_AS_READ } from './types';
import { Notification } from '../../../app/models/Notification';
import useNotificationsService from '../../../hooks/services/useNotificationService';

const initialState: NotificationContextState = {
    notificationsNotViewed: [],
    setNotificationsNotViewed: (notifications: Notification[]) => { /* No hace nada */ },
    notificationsViewed: [],
    setNotificationsViewed: (notifications: Notification[]) => { /* No hace nada */ },

    markNotificationAsRead: (notificationId: number) => { /* No hace nada */ },
    markAllAsReadNotifications: () => { /* No hace nada */ }
};

export const NotificationContext = createContext<NotificationContextState>(initialState);

const NotificationProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { getNotifications, markAsRead, markAllAsRead } = useNotificationsService();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getNotifications({
            onSuccess: (response) => {
                dispatch({ type: SET_NOTIFICATIONS, payload: response.data });
            }
        }).then();
    };

    const markNotificationAsRead = (notificationId: number) => {
        markAsRead(notificationId, {
            onSuccess: () => {
                dispatch({ type: MARK_NOTIFICATION_AS_READ, payload: notificationId });
            }
        });
    };

    const markAllAsReadNotifications = () => {
        markAllAsRead({
            onSuccess: () => {
                dispatch({ type: MARK_NOTIFICATION_ALL_AS_READ, payload: null });
            }
        });
    }


    return (
        <NotificationContext.Provider value={{ ...state, markNotificationAsRead, markAllAsReadNotifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;