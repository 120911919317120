import React, { useContext, useEffect } from 'react';
import DefaultCard from '../../components/default/DefaultCard';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../template/contexts/auth';
import Breadcrumbs from '../../template/layouts/components/Breadcrumbs';

const Dashboard = () => {
    const history = useHistory();
    const { authUser } = useContext(AuthContext);

    const hasSubscription = authUser?.data.subscription;

    useEffect(() => {
        //redirect to /contracs
        window.location.href = '/contracts';
    }, []);

    return (
        <>
            <Breadcrumbs pageSection="Dashboard" breadcrumbs={[]} />
            <div className="row">
                <div className="col-12">
                    {/*<DefaultCard>*/}
                    {/*    <div className="row">*/}
                    {/*        /!*<div className="col-md-6">*!/*/}
                    {/*        /!*    <>*!/*/}
                    {/*        /!*        /!*Hola <b>Alejandro</b>, bienvenido a tu panel de administración*!/*!/*/}
                    {/*        /!*    </>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*        <div className="col-md-6 text-end"></div>*/}
                    {/*    </div>*/}
                    {/*</DefaultCard>*/}
                </div>
            </div>
        </>
    );
};

export default Dashboard;
