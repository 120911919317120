import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import {
    Provider,
    ProviderUpdateForm,
    initialProviderUpdateForm
} from '../../../app/models/Provider';
import useProvidersService from '../../../hooks/services/useProvidersService';
import DefaultTable from '../../../components/default/DefaultTable';
import ModalCreateProvider from '../components/ModalCreateProvider';
import ModalEditProvider from '../components/ModalEditProvider';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import useSweetAlert from '../../../hooks/useSweetAlert';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';
import {
    type MRT_ColumnDef,
} from 'material-react-table';

const ProvidersPage = () => {
    const history = useHistory();

    const breadcrumbs = [
        {
            name: 'Proveedores',
            url: '/providers',
            isActive: true
        }
    ];

    const {
        fetchingGetProviders,
        getProviders,
        fetchingDeleteProvider,
        deleteProvider,
        fetchingCreateProvider,
        fetchingEditProvider
    } = useProvidersService();

    const [providers, setProviders] = useState<Provider[]>([]);
    const [dataForm, setDataForm] = useState<ProviderUpdateForm>(initialProviderUpdateForm);

    const [showingCreateView, setShowingCreateView] = useState(false);
    const [showingEditView, setShowingEditView] = useState(false);
    const [idToEdit, setIdToEdit] = useState(0);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getProviders({
            onSuccess: (response) => {
                setDataForm(response.data.provider_rule);
                setProviders(response.data.providers);
            }
        }).then();
    };

    const showEditView = (id: number) => {
        setShowingEditView(true);
        setIdToEdit(id);
    };

    const closeEditView = () => {
        setShowingEditView(false);
        setIdToEdit(0);
    };

    const destroy = (id: number) => {
        const { requestConfirmation } = useSweetAlert();

        requestConfirmation({
            title: '¿Estás seguro?',
            text: 'No podrás revertir esta acción',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            onConfirm: () => {
                deleteProvider(id, {
                    onSuccess: () => {
                        toast.success('Proveedor eliminado con éxito');
                        init();
                    },
                    onError: () => {
                        toast.error('Ocurrió un error al eliminar el proveedor');
                    }
                }).then();
            }
        });
    };

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        });
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                header: 'Nombre',
                accessorKey: 'name',
            },
            {
                header: 'Servicio',
                accessorKey: 'service_name',
            },
            {
                header: 'Acciones',
                accessorKey: 'id',
                Cell: ({ cell }: { cell: any }) => {
                    return (
                        <div className="btn-group btn-group-sm">
                            <ButtonTableEdit onClick={() => showEditView(cell.getValue())} />
                        </div>
                    );
                },
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }
        ],
        [history]
    );

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Proveedores" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            onClick={() => setShowingCreateView(true)}
                            title="Nuevo Proveedor"
                        />
                    }
                />

                <DefaultTable
                    data={providers}
                    tableLoaded={fetchingGetProviders}
                    columns={columns}
                />

            </DefaultCard>

            {showingCreateView && (
                <ModalCreateProvider
                    show={showingCreateView}
                    onClose={() => setShowingCreateView(false)}
                    onSuccess={() => {
                        toast.success('Proveedor creado con éxito');
                        setShowingCreateView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al crear el proveedor');
                    }}
                    disabled={fetchingCreateProvider}
                />
            )}

            {showingEditView && (
                <ModalEditProvider
                    id={idToEdit}
                    show={showingEditView}
                    onClose={closeEditView}
                    onSuccess={() => {
                        toast.success('Proveedor editado con éxito');
                        setShowingEditView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al editar el proveedor');
                    }}
                    handleChange={handleChange}
                    disabled={fetchingEditProvider}
                />
            )}
        </>
    );
};

export default ProvidersPage;
