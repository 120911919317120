import React from 'react';
import useFieldError from '../../hooks/useFieldError';

type Props = {
    label?: string;
    name: string;
    nameFieldError?: string;
    id?: string;
    placeholder?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    errors?: object;
    disabled?: boolean;
    required?: boolean;
    rows?: number;
    resize?: boolean;
};

const FieldTextArea = ({
    label,
    id,
    name,
    nameFieldError,
    placeholder,
    value,
    onChange,
    onBlur,
    errors,
    disabled,
    required,
    rows,
    resize
}: Props) => {
    const { error, clearError } = useFieldError(nameFieldError ?? name, errors);

    return (
        <div className={`mb-3 ${error ? 'has-danger' : ''}`}>
            {label && (
                <label htmlFor={id ?? name}>
                    {label} {required ? '*' : ''}
                </label>
            )}

            <textarea
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={id ?? name}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={(event) => {
                    clearError();
                    onBlur && onBlur(event);
                }}
                disabled={disabled}
                required={required}
                rows={rows ?? 1}
                style={{ resize: resize ? 'both' : 'none' }}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export default FieldTextArea;
