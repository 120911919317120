import React, { ChangeEvent, useEffect, useState } from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { Currency } from '../../../app/models/Currency';
import FieldDate from '../../../components/fields/FieldDate';
import { OtherExpense } from '../../../app/models/OtherExpense';
import { Bank } from '../../../app/models/Bank';
import FieldSwitch from '../../../components/fields/FieldSwitch';
import FieldInput from '../../../components/fields/FieldInput';

type Props = {
    currencies: Currency[];
    otherExpenses: OtherExpense[];
    onUpdateOtherExpenses: (updatedOtherExpenses: OtherExpense[]) => void;
    disabled: boolean;
    errors: any;
    reformatNumber: number;
};

const OtherExpenseDataForm = ({
    onUpdateOtherExpenses,
    currencies,
    otherExpenses,
    disabled,
    errors,
    reformatNumber
}: Props) => {
    const [localOtherExpenses, setLocalOtherExpenses] = useState(otherExpenses);

    useEffect(() => {
        setLocalOtherExpenses(
            otherExpenses.map((otherExpense) => {
                if (!otherExpense.amount) {
                    return otherExpense;
                }
                const value = String(otherExpense.amount);
                const [integerPart, decimalPart] = value.split('.');
                const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                    Number(integerPart)
                );
                otherExpense.amount = String(
                    value.split('.').length > 1
                        ? `${formattedIntegerPart},${decimalPart}`
                        : formattedIntegerPart
                );
                return otherExpense;
            })
        );
    }, [reformatNumber]);

    const handleOtherExpenseChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        index: number,
        isUploadDataForm = true
    ) => {
        let value = event.target.value;
        const name = event.target.name;
        const updatedOtherExpenses = localOtherExpenses.map((otherExpense, i) => {
            if (i === index) {
                if (name === 'amount' && value !== '') {
                    if (!/^[0-9,.]+$/.test(value)) {
                        value = value.replace(/[^0-9,.]/g, '');
                    }
                    const [integerPart, decimalPart] = value.split(',');
                    // Limpiar y formatear la parte entera
                    const cleanedIntegerPart = integerPart.replace(/\./g, '');
                    const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                        Number(cleanedIntegerPart)
                    );

                    // Combinar las partes formateadas
                    const formattedValue =
                        value.split(',').length > 1
                            ? `${formattedIntegerPart},${decimalPart}`
                            : formattedIntegerPart;

                    return { ...otherExpense, [name]: String(formattedValue) };
                }
                const target = event.target as HTMLInputElement; // type assertion
                return {
                    ...otherExpense,
                    [target.name]: target.type === 'checkbox' ? target.checked : target.value
                };
            }
            return otherExpense;
        });

        setLocalOtherExpenses(updatedOtherExpenses);
        if (isUploadDataForm) {
            onUpdateOtherExpenses(updatedOtherExpenses);
        }
    };

    const changeBlurred = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | undefined
    ) => {
        if (e) {
            onUpdateOtherExpenses(localOtherExpenses);
        }
    };

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Moneda</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Incluido en el contrato</th>
                        <th scope="col">¿Obra en canon?</th>
                        <th scope="col">Porcentaje</th>
                        {/* Agrega más encabezados de columna aquí según sea necesario */}
                    </tr>
                </thead>
                <tbody>
                    {localOtherExpenses.map((otherExpense, index) => (
                        <tr key={index}>
                            <td>
                                <p>{otherExpense.name.toUpperCase()}</p>
                            </td>
                            <td>
                                <FieldSelect
                                    name={'currency'}
                                    value={otherExpense.currency}
                                    errors={errors}
                                    nameFieldError={'other_expenses.' + index + '.currency'}
                                    disabled={disabled}
                                    options={currencies.map(
                                        (currency) =>
                                            ({
                                                value: currency.value,
                                                label: currency.label
                                            } as FieldSelectOption)
                                    )}
                                    onChange={(event) => handleOtherExpenseChange(event, index)}
                                />
                            </td>
                            <td>
                                <FieldInput
                                    name={'amount'}
                                    value={otherExpense.amount}
                                    errors={errors}
                                    nameFieldError={'other_expenses.' + index + '.amount'}
                                    disabled={disabled}
                                    onBlur={changeBlurred}
                                    onChange={(event) =>
                                        handleOtherExpenseChange(event, index, false)
                                    }
                                />
                            </td>
                            <td>
                                <FieldSwitch
                                    name={'is_included_contract'}
                                    value={otherExpense.is_included_contract}
                                    errors={errors}
                                    nameFieldError={
                                        'other_expenses.' + index + '.is_included_contract'
                                    }
                                    disabled={disabled}
                                    onChange={(event) =>
                                        handleOtherExpenseChange(event, index, true)
                                    }
                                />
                            </td>
                            <td>
                                {otherExpense && otherExpense.name === 'gastos comunes' && (
                                    <FieldSwitch
                                        name={'is_work_in_canon'}
                                        value={otherExpense.is_work_in_canon}
                                        errors={errors}
                                        nameFieldError={
                                            'other_expenses.' + index + '.is_work_in_canon'
                                        }
                                        disabled={disabled}
                                        onChange={(event) =>
                                            handleOtherExpenseChange(event, index, true)
                                        }
                                    />
                                )}
                            </td>
                            <td>
                                {otherExpense &&
                                otherExpense.name === 'gastos comunes' &&
                                otherExpense.is_work_in_canon ? (
                                    <FieldNumber
                                        name={'percentage_work_in_canon'}
                                        value={otherExpense.percentage_work_in_canon}
                                        errors={errors}
                                        nameFieldError={
                                            'other_expenses.' + index + '.percentage_work_in_canon'
                                        }
                                        disabled={otherExpense.is_work_in_canon ? disabled : true}
                                        onBlur={changeBlurred}
                                        onChange={(event) =>
                                            handleOtherExpenseChange(event, index, false)
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </td>
                            {/* Renderiza otras celdas con campos de OtherExpense aquí */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default OtherExpenseDataForm;
