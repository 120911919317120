export function formatAmount(amount: number, currency: string) {
    if (currency === 'UF') {
        return Intl.NumberFormat('de-DE').format(Number(amount)) + ' UF';
    } else {
        return '$ ' + Intl.NumberFormat('de-DE').format(Math.round(Number(amount)));
    }
}

export function numberToInputText(value: string) {
    const [integerPart, decimalPart] = value.split('.');
    const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(Number(integerPart));
    return String(
        value.split('.').length > 1
            ? `${formattedIntegerPart},${decimalPart}`
            : formattedIntegerPart
    );
}
