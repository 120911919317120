import React, { useState } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import { initialUser, User } from '../../../app/models/User';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useUsersService from '../../../hooks/services/useUsersService';
import UserDataForm from './UserDataForm';
import useRut from '../../../hooks/useRut';

type Props = {
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
};

const ModalCreateUser = ({ show, onClose, onSuccess, onError }: Props) => {
    const { fetchingStoreUser, storeUser } = useUsersService();

    const [dataForm, setDataForm] = useState<User>(initialUser);
    const [errors, setErrors] = useState<object>({});
    const { validateRut, formatRut, isValidRut } = useRut();

    const store = () => {
        storeUser(dataForm, {
            onSuccess: (response) => {
                onSuccess();
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        if (e.target.name == 'rut') {
            if (isValidRut(e.target.value)) {
                setDataForm({
                    ...dataForm,
                    id_number: formatRut(e.target.value)
                });
                return;
            }
            setErrors({
                ...errors,
                [e.target.name]: ''
            });
        }

        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    };

    return (
        <DefaultModal
            show={show}
            handleClose={onClose}
            title="Crear usuario"
            centered={true}
            actionButton={<ButtonSaveForm onClick={store} loading={fetchingStoreUser} />}
        >
            <UserDataForm
                dataForm={dataForm}
                handleChange={handleChange}
                validateRut={validateRut}
                changePassword={true}
                disabled={false}
                errors={errors}
            />
        </DefaultModal>
    );
};

export default ModalCreateUser;
