import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    onClick: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
    className?: string;
    icon?: string;
};

const ButtonSaveForm = ({
    onClick,
    title = 'Guardar',
    locked = false,
    message = 'No se puede guardar este registro.',
    className = '',
    icon = 'fa fa-save'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !locked ? (
        <button
            type="button"
            className={`waves-effect btn btn-primary btn-smm ${className}`}
            onClick={locked ? showLockedMessage : onClick}
        >
            <i className={icon} /> {title}
        </button>
    ) : (
        <button
            type="button"
            className={`waves-effect btn btn-primary btn-smm ${className} disabled`}
            disabled={true}
        >
            <i className="fa fa-spinner" /> {title}
        </button>
    );
};

export default ButtonSaveForm;
