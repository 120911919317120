import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { LessorEndpoint } from './APIConnection';
import { Lessor } from '../models/Lessor';

export class LessorFetchRepository extends GenericFetch {
    all(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: LessorEndpoint.all()
        });
    }

    store(lessor: Lessor, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: LessorEndpoint.store(),
            body: lessor
        });
    }

    edit(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: LessorEndpoint.edit(id)
        });
    }

    create(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: LessorEndpoint.create()
        });
    }

    update(id: number, lessor: Lessor, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPut({
            ...events,
            url: LessorEndpoint.update(id),
            body: lessor
        });
    }

    delete(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doDelete({
            ...events,
            url: LessorEndpoint.delete(id)
        });
    }
}
