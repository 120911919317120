import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { UserEndpoint } from './APIConnection';
import { User } from '../models/User';
import { UserRepository } from '../models/User';

export class UserFetchRepository extends GenericFetch implements UserRepository {
    all(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: UserEndpoint.all()
        });
    }

    store(user: User, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: UserEndpoint.store(),
            body: user
        });
    }

    edit(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: UserEndpoint.edit(id)
        });
    }

    update(id: number, user: User, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPut({
            ...events,
            url: UserEndpoint.update(id),
            body: user
        });
    }

    delete(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doDelete({
            ...events,
            url: UserEndpoint.delete(id)
        });
    }
}
