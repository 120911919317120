import React, { useEffect, useState } from 'react';
import Wrapper from './components/Wrapper';
import useAuthService from '../../hooks/services/useAuthService';
import { Link, useHistory, useParams } from 'react-router-dom';
import ButtonSaveForm from '../../components/buttons/ButtonSaveForm';

type Params = {
    token: string;
};

const ConfirmAccountPage = () => {
    const params = useParams<Params>();
    const history = useHistory();
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    useEffect(() => {
        confirm();
    }, [params]);

    const { fetchingConfirmEmail, confirmEmail } = useAuthService();

    const confirm = () => {
        confirmEmail(params.token, {
            onSuccess: (response) => {
                setShowSuccess(true);
            },
            onError: (response) => {
                setShowError(true);
            }
        }).then();
    };

    return (
        <>
            <Wrapper
                title="Confirmando cuenta"
                description="Estamos confirmando tu cuenta, por favor espera un momento."
            >
                {fetchingConfirmEmail ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : null}
                {showError ? (
                    <>
                        <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Error!</h4>
                            <p>
                                Ha ocurrido un error al confirmar tu cuenta, por favor intenta
                                nuevamente.
                            </p>
                            <hr />
                            <p className="mb-0">
                                Si el problema persiste, por favor contacta a soporte.
                            </p>
                        </div>
                        <div className="text-center mt-2">
                            <Link to="/login" className="btn btn-link btn-block ">
                                ¿Ya tienes una cuenta? Inicia sesión
                            </Link>
                        </div>
                        <div className="text-center mt-2">
                            <Link to="/register" className="btn btn-link btn-block ">
                                ¿No tienes una cuenta? Regístrate
                            </Link>
                        </div>
                    </>
                ) : null}

                {showSuccess ? (
                    <>
                        <div className="alert alert-success" role="alert">
                            <h4 className="alert-heading">Bienvenido!</h4>
                            <p>Tu cuenta ha sido confirmada con éxito.</p>
                            <hr />
                            <p className="mb-0">Ahora puedes iniciar sesión.</p>
                        </div>
                        <ButtonSaveForm
                            onClick={() => history.push('/login')}
                            title="Iniciar sesión"
                            className="w-100"
                            icon="fas fa-sign-in-alt"
                        />
                    </>
                ) : null}
            </Wrapper>
        </>
    );
};

export default ConfirmAccountPage;
