import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import DefaultTable from '../../../components/default/DefaultTable';
import { toast } from 'react-toastify';
import {
    CostCenter,
    CostCenterRuleUpdateForm,
    initialCostCenterRuleUpdateForm
} from '../../../app/models/CostCenter';
import useCostCentersService from '../../../hooks/services/useCostCentersService';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import ModalEditCostCenter from '../components/ModalEditCostCenter';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import ModalCreateCostCenter from '../components/ModalCreateCostCenter';
import {
    type MRT_ColumnDef,
} from 'material-react-table';
const CostCentersPage = () => {
    const breadcrumbs = [
        {
            name: 'Razón social',
            url: '/cost_centers',
            isActive: true
        }
    ];

    const { fetchingGetCostCenters, getCostCenters } = useCostCentersService();

    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [dataForm, setDataForm] = useState<CostCenterRuleUpdateForm>(
        initialCostCenterRuleUpdateForm
    );

    const [idToEdit, setIdToEdit] = useState(0);
    const [showingEditView, setShowingEditView] = useState(false);
    const [showingCreateView, setShowingCreateView] = useState(false);

    useEffect(() => {
        init();
    }, []);

    const { fetchingEditCostCenter, fetchingCreateCostCenter } = useCostCentersService();

    const init = () => {
        getCostCenters({
            onSuccess: (response) => {
                setDataForm(response.data.cost_center_rule);
                setCostCenters(reMapCostCenters(response.data.cost_centers ?? []));
            }
        }).then();
    };

    const reMapCostCenters = (costCenters: any) => {
        return costCenters.map((costCenter: any) => {
            return {
                id: costCenter.id,
                type: costCenter.type,
                cost_code_id: costCenter.cost_code_id,
                name: costCenter.name,
                society_name: getSocietyName(costCenter.society_name),
                commune: costCenter.commune.name,
                address: costCenter.address,
                remote_relation_id: costCenter.remote_relation_id,
            };
        });
    }

    const getSocietyName = (societyName: string) => {
        let societyNameMapped = '';

        switch (societyName) {
            case 'MELT_PIZZA':
                societyNameMapped = 'Melt Pizza';
                break;
            case 'UNDER_PIZZA':
                societyNameMapped = 'Under Pizza';
                break;
            case 'NIU_SUSHI':
                societyNameMapped = 'Niu Sushi';
                break;
            case 'GUACAMOLE':
                societyNameMapped = 'Guacamole';
                break;
            case 'KAO':
                societyNameMapped = 'Kao';
                break;
            default:
                societyNameMapped = societyName;
        }

        return societyNameMapped
    };

    const showEditView = (id: number) => {
        setShowingEditView(true);
        setIdToEdit(id);
    };

    const closeEditView = () => {
        setShowingEditView(false);
        setIdToEdit(0);
    };

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        });
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                header: 'Tipo',
                accessorKey: 'type',
            },
            {
                header: 'Razón Social',
                accessorKey: 'cost_code_id',
            },
            {
                header: 'Nombre',
                accessorKey: 'name',
            },
            {
                header: 'Sociedad',
                accessorKey: 'society_name',
            },
            {
                header: 'Comuna',
                accessorKey: 'commune',
            },

            {
                header: 'Dirección',
                accessorKey: 'address',
            },
            {
                header: 'Id Externo',
                accessorKey: 'remote_relation_id',
            },
            {
                header: 'Acciones',
                accessorKey: 'id',
                Cell: ({ cell }: { cell: any }) => {
                    return (
                        <div className="btn-group btn-group-sm">
                            <ButtonTableEdit onClick={() => showEditView(cell.getValue())} />
                        </div>
                    );
                },
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }
        ],
        [history]
    );

    return (
        <>
            <Breadcrumbs pageSection="Razón Social" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            onClick={() => setShowingCreateView(true)}
                            title="Nueva Razón Social"
                        />
                    }
                />

                <DefaultTable
                    data={costCenters}
                    tableLoaded={fetchingGetCostCenters}
                    columns={columns}
                />

            </DefaultCard>

            {showingCreateView && (
                <ModalCreateCostCenter
                    show={showingCreateView}
                    onClose={() => setShowingCreateView(false)}
                    onSuccess={() => {
                        toast.success('Razón Social creado con éxito');
                        setShowingCreateView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al crear el razón social');
                    }}
                    disabled={fetchingCreateCostCenter}
                />
            )}

            {showingEditView && (
                <ModalEditCostCenter
                    id={idToEdit}
                    show={showingEditView}
                    onClose={closeEditView}
                    onSuccess={() => {
                        toast.success('Razón Social editado con éxito');
                        setShowingEditView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al editar el razón social');
                    }}
                    handleChange={handleChange}
                    disabled={fetchingEditCostCenter}
                />
            )}
        </>
    );
};

export default CostCentersPage;
