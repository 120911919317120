import React from 'react';
import Template from '../index';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <Template withWrapper={false}>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3">
                        <span className="text-danger">Ops!</span> Página no encontrada
                    </p>
                    <p className="lead">No hemos podido encontrar la página que estás buscando.</p>
                    <Link to={'/'} className="btn btn-primary">
                        Volver al Inicio
                    </Link>
                </div>
            </div>
        </Template>
    );
};

export default PageNotFound;
