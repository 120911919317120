import React, { useEffect, useState } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import { initialLessor, Lessor } from '../../../app/models/Lessor';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useLessorsService from '../../../hooks/services/useLessorsService';
import LessorDataForm from './LessorDataForm';
import useRut from '../../../hooks/useRut';
import { Bank } from '../../../app/models/Bank';

type Props = {
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
};

const ModalCreateLessor = ({ show, onClose, onSuccess, onError }: Props) => {
    const { fetchingStoreLessor, storeLessor, fetchingCreateLessor, createLessor } =
        useLessorsService();
    const { validateRut, formatRut, isValidRut } = useRut();

    const [dataForm, setDataForm] = useState<Lessor>(initialLessor);
    const [accountTypes, setAccountTypes] = useState<any[]>([]);
    const [errors, setErrors] = useState<object>({});
    const [banks, setBanks] = useState<Bank[]>([]);

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createLessor({
            onSuccess: (response) => {
                setBanks(response.data.banks);
                setAccountTypes(response.data.account_types);
            }
        });
    };

    const store = () => {
        storeLessor(dataForm, {
            onSuccess: (response) => {
                onSuccess();
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        if (e.target.name == 'id_number') {
            if (isValidRut(e.target.value)) {
                setDataForm({
                    ...dataForm,
                    id_number: formatRut(e.target.value)
                });
                return;
            }
            setErrors({
                ...errors,
                [e.target.name]: ''
            });
        }

        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    };

    return (
        <DefaultModal
            show={show}
            handleClose={onClose}
            title="Crear arrendatario"
            centered={true}
            actionButton={<ButtonSaveForm onClick={store} loading={fetchingStoreLessor} />}
        >
            <LessorDataForm
                dataForm={dataForm}
                handleChange={handleChange}
                banks={banks}
                account_types={accountTypes}
                validateRut={validateRut}
                changePassword={true}
                disabled={false}
                errors={errors}
            />
        </DefaultModal>
    );
};

export default ModalCreateLessor;
