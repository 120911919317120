import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { ServiceCreateForm, ServiceUpdateForm } from './Service';

export type User = {
    id: number;
    first_name: string;

    id_number: string;
    last_name: string;
    email: string;
    password: string;

    password_confirmation: string;
};

export type UserRepository = {
    all(events: ServiceEvents): Promise<ServiceResponse>;
    store(service: User, events: ServiceEvents): Promise<ServiceResponse>;
    edit(id: number, events: ServiceEvents): Promise<ServiceResponse>;
    update(id: number, service: User, events: ServiceEvents): Promise<ServiceResponse>;
    delete(id: number, events: ServiceEvents): Promise<ServiceResponse>;
};

export const initialUser: User = {
    id: 0,
    id_number: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: ''
};
