import React, { useState } from 'react';
import FieldInput from '../../components/fields/FieldInput';
import Wrapper from './components/Wrapper';
import { ResetPasswordCredentials } from '../../app/models/Auth';
import useAuthService from '../../hooks/services/useAuthService';
import ButtonSaveForm from '../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const ResetPasswordPage = () => {
    const history = useHistory();

    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const { fetchingResetPassword, resetPassword } = useAuthService();

    const [credentials, setCredentials] = useState<ResetPasswordCredentials>({
        email: '',
        password: '',
        password_confirmation: '',
        recovery_pin: ''
    });

    const [errors, setErrors] = useState<object>({});
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const reset = () => {
        resetPassword(credentials, {
            onSuccess: (response) => {
                setShowSuccess(true);
            },
            onError: (response) => {
                toast.error(response.message);
            },
            onFieldError: (response) => setErrors(response.data.errors)
        }).then();
    };

    return (
        <>
            <Wrapper
                title="Cambiar contraseña"
                description="Ingresa tu código de recuperación y tu nueva contraseña"
            >
                {showSuccess ? (
                    <>
                        <div className="alert alert-success">
                            <p>Tu contraseña ha sido cambiada con éxito</p>
                        </div>
                        <br />
                        <ButtonSaveForm
                            onClick={() => history.push('/login')}
                            title="Iniciar sesión"
                            className="w-100"
                            icon="fas fa-sign-in-alt"
                        />
                    </>
                ) : (
                    <>
                        <FieldInput
                            label="Código de recuperación"
                            name="recovery_pin"
                            type="text"
                            placeholder="Código de recuperación"
                            value={credentials.recovery_pin}
                            onChange={onChange}
                            errors={errors}
                            required={true}
                            disabled={fetchingResetPassword}
                        />

                        <FieldInput
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={credentials.email}
                            onChange={onChange}
                            errors={errors}
                            required={true}
                            disabled={fetchingResetPassword}
                        />

                        <FieldInput
                            label="Contraseña"
                            name="password"
                            type="password"
                            placeholder="********"
                            value={credentials.password}
                            onChange={onChange}
                            errors={errors}
                            required={true}
                            disabled={fetchingResetPassword}
                        />
                        <FieldInput
                            label="Repita contraseña"
                            name="password_confirmation"
                            type="password"
                            placeholder="********"
                            value={credentials.password_confirmation}
                            onChange={onChange}
                            errors={errors}
                            required={true}
                            disabled={fetchingResetPassword}
                        />
                        <br />
                        <ButtonSaveForm
                            title="Resetear Contraseña"
                            onClick={reset}
                            locked={fetchingResetPassword}
                            className="w-100"
                            icon="fas fa-plus"
                        />
                        <div className="text-center mt-2">
                            <Link to="/login" className="btn btn-link btn-block ">
                                ¿Ya tienes una cuenta? Inicia sesión
                            </Link>
                        </div>
                    </>
                )}
            </Wrapper>
        </>
    );
};

export default ResetPasswordPage;
