import React, { ChangeEvent, useEffect, useState } from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import { Currency } from '../../../app/models/Currency';
import FieldDate from '../../../components/fields/FieldDate';
import { RuleRent } from '../../../app/models/RuleRent';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';
import FieldInput from '../../../components/fields/FieldInput';
import FieldSwitch from '../../../components/fields/FieldSwitch';

type Props = {
    currencies: Currency[];
    ruleRents: RuleRent[];
    onUpdateRuleRents: (updatedRuleRents: RuleRent[]) => void;
    disabled: boolean;
    errors: any;
    reformatNumber: number;
};

const RuleRentDataForm = ({
    onUpdateRuleRents,
    currencies,
    ruleRents,
    disabled,
    errors,
    reformatNumber
}: Props) => {
    const [localRuleRents, setLocalRuleRents] = useState(ruleRents);

    useEffect(() => {
        setLocalRuleRents(
            ruleRents.map((ruleRent) => {
                const value = String(ruleRent.amount);
                const [integerPart, decimalPart] = value.split('.');
                const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                    Number(integerPart)
                );
                ruleRent.amount = String(
                    value.split('.').length > 1
                        ? `${formattedIntegerPart},${decimalPart}`
                        : formattedIntegerPart
                );
                return ruleRent;
            })
        );
    }, [reformatNumber]);

    const addRuleRent = () => {
        const newRuleRent: RuleRent = {
            amount: '',
            contract_id: 0,
            expiration_date: '',
            id: 0,
            currency: '',
            name: 'Arriendo',
            is_subject_to_vat: false
        };

        const updatedRuleRents = [newRuleRent, ...localRuleRents];
        setLocalRuleRents(updatedRuleRents);
        onUpdateRuleRents(updatedRuleRents);
    };

    const handleRuleRentChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        index: number,
        isUploadDataForm = true
    ) => {
        let value = event.target.value;
        const name = event.target.name;

        const updatedRuleRents = localRuleRents.map((ruleRent, i) => {
            if (i === index) {
                if (name === 'amount' && value !== '') {
                    if (!/^[0-9,.]+$/.test(value)) {
                        value = value.replace(/[^0-9,.]/g, '');
                    }
                    const [integerPart, decimalPart] = value.split(',');
                    // Limpiar y formatear la parte entera
                    const cleanedIntegerPart = integerPart.replace(/\./g, '');
                    const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                        Number(cleanedIntegerPart)
                    );

                    // Combinar las partes formateadas
                    const formattedValue =
                        value.split(',').length > 1
                            ? `${formattedIntegerPart},${decimalPart}`
                            : formattedIntegerPart;

                    return { ...ruleRent, [name]: String(formattedValue) };
                }
                const target = event.target as HTMLInputElement; // type assertion
                return {
                    ...ruleRent,
                    [name]: target.type === 'checkbox' ? target.checked : target.value
                };
            }
            return ruleRent;
        });

        setLocalRuleRents(updatedRuleRents);

        if (isUploadDataForm) {
            onUpdateRuleRents(updatedRuleRents);
        }
    };

    const handleDeleteRuleRent = (index: number) => {
        const updatedRuleRents = [...localRuleRents];
        updatedRuleRents.splice(index, 1);
        setLocalRuleRents(updatedRuleRents);
        onUpdateRuleRents(updatedRuleRents);
    };

    const changeBlurred = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | undefined
    ) => {
        if (e) {
            onUpdateRuleRents(localRuleRents);
        }
    };

    return (
        <>
            <div className="row mb-2">
                <div className="col-md-12">
                    {!disabled ? (
                        <button
                            type="button"
                            className="btn btn-primary float-end"
                            disabled={disabled}
                            onClick={addRuleRent}
                        >
                            Agregar Arriendo
                        </button>
                    ) : null}
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Moneda</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Vigencia Hasta</th>
                        <th scope="col">Es afecto a IVA</th>
                        <th scope="col">Acciones</th>
                        {/* Agrega más encabezados de columna aquí según sea necesario */}
                    </tr>
                </thead>
                <tbody>
                    {localRuleRents.map((ruleRent, index) => (
                        <tr key={index}>
                            <td>
                                <p>{ruleRent.name ?? 'Arriendo'}</p>
                            </td>
                            <td>
                                <FieldSelect
                                    name={'currency'}
                                    value={ruleRent.currency}
                                    errors={errors}
                                    nameFieldError={'rule_rents.' + index + '.currency'}
                                    disabled={disabled}
                                    options={currencies.map(
                                        (currency) =>
                                            ({
                                                value: currency.value,
                                                label: currency.label
                                            } as FieldSelectOption)
                                    )}
                                    onChange={(event) => handleRuleRentChange(event, index)}
                                />
                            </td>
                            <td>
                                <FieldInput
                                    name={'amount'}
                                    value={String(ruleRent.amount)}
                                    errors={errors}
                                    nameFieldError={'rule_rents.' + index + '.amount'}
                                    disabled={disabled}
                                    onBlur={changeBlurred}
                                    onChange={(event) => handleRuleRentChange(event, index, false)}
                                />
                            </td>
                            <td>
                                <FieldDate
                                    name={'expiration_date'}
                                    value={ruleRent.expiration_date}
                                    errors={errors}
                                    nameFieldError={'rule_rents.' + index + '.expiration_date'}
                                    disabled={disabled}
                                    onChange={(event) => handleRuleRentChange(event, index)}
                                />
                            </td>
                            <td>
                                <FieldSwitch
                                    name={'is_subject_to_vat'}
                                    value={ruleRent.is_subject_to_vat}
                                    errors={errors}
                                    nameFieldError={
                                        'other_expenses.' + index + '.is_subject_to_vat'
                                    }
                                    disabled={disabled}
                                    onChange={(event) => handleRuleRentChange(event, index, true)}
                                />
                            </td>
                            <td>
                                <ButtonTableDestroy
                                    onClick={() => handleDeleteRuleRent(index)}
                                    locked={disabled}
                                    loading={disabled}
                                />
                            </td>
                            {/* Renderiza otras celdas con campos de RuleRent aquí */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default RuleRentDataForm;
