import React from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_Localization,
    type MRT_ColumnDef,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NoRecords from '../../template/components/ui/NoRecords';
import Loading from '../../template/components/ui/Loading';

type TableProps = {
    data: any[];
    columns: MRT_ColumnDef<any>[];
    tableLoaded?: boolean;
    pagination?: boolean;
};

const DefaultTable = ({
    data,
    columns,
    tableLoaded = true,
    pagination = true,
}: TableProps) => {
    const table = useMaterialReactTable({
        columns,
        data,
        localization: MRT_Localization_ES as MRT_Localization,
        enablePagination: pagination,
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        enableGlobalFilterModes: true,
        positionGlobalFilter: 'left',
        initialState: { 
            density: 'compact',
            showGlobalFilter: true,
        },
        enableGrouping: false,
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 25, 50, 100],
            shape: 'rounded',
            variant: 'outlined',
        },
    });

    return tableLoaded ? (
        <Loading height={300} />
    ) : (
        data.length === 0 ? (
            <NoRecords />
        ) : (
            <MaterialReactTable
                table={table}
            />
        )
    );
};

export default DefaultTable;
