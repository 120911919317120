import React from 'react';
import NavbarBrand from './components/NavbarBrand';
import ToggleSidebar from './components/ToggleSidebar';
import ChangeModeTheme from './components/ChangeModeTheme';
import UserMenu from './components/UserMenu';
import ToggleFullScreen from './components/ToggleFullScreen';
import NotificationMenu from './components/NotificationMenu';

const Header = () => {
    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <NavbarBrand />
                    <ToggleSidebar />
                </div>
                <div className="d-flex">
                    <NotificationMenu />
                    <ToggleFullScreen />
                    <ChangeModeTheme />
                    <UserMenu />
                </div>
            </div>
        </header>
    );
};

export default Header;
