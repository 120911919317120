import React, { useEffect, useState } from 'react';
import { DocumentContract } from '../../../app/models/DocumentContract';
import InputButtonFile from '../../../components/fields/InputButtonFile';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';

interface Props {
    contractId: number;
    contractDocuments: DocumentContract[];
    setContractDocuments: (contractDocuments: DocumentContract[]) => void;
    disabled: boolean;
    errors: any;
}

const ContractDocumentsDataForm = ({
    contractDocuments,
    setContractDocuments,
    contractId,
    disabled,
    errors
}: Props) => {
    const handleDeleteDocument = (index: number) => {
        const newDocumentList = [...contractDocuments];
        newDocumentList.splice(index, 1);
        setContractDocuments(newDocumentList);
    };

    const handleChangeDocumentFile = (e: FileList | null, index: number) => {
        if (!e) {
            return;
        }

        const newDocument = {
            ...contractDocuments[index],
            file: e[0],
            contract_id: contractId
        };

        const newDocumentList = [...contractDocuments];
        newDocumentList[index] = newDocument;

        setContractDocuments(newDocumentList);
    };

    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
                    {!disabled ? (
                        <button
                            type="button"
                            className="btn btn-primary mb-2 float-end"
                            disabled={disabled}
                            onClick={() =>
                                setContractDocuments([
                                    ...contractDocuments,
                                    {
                                        contract_id: contractId
                                    }
                                ])
                            }
                        >
                            Agregar documento
                        </button>
                    ) : null}
                </div>
            </div>

            {contractDocuments.map((_, index) => (
                <div key={index} className="row">
                    <div className="col-md">
                        <InputButtonFile
                            name={`document${index}`}
                            handleChangeDocumentFile={(e) => handleChangeDocumentFile(e, index)}
                            objectFile={contractDocuments[index]?.file}
                            disabled={disabled}
                        />
                        {/*                <div className="invalid-feedback" style={{ display: 'flex' }}>*/}
                        {/*                    {errors && <div className="invalid-feedback">{errors}</div>}*/}

                        {/*                </div>*/}
                    </div>
                    <div className="col-auto">
                        <ButtonTableDestroy
                            onClick={() => handleDeleteDocument(index)}
                            locked={disabled}
                            loading={disabled}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export default ContractDocumentsDataForm;
