import React, { useEffect, useState, useMemo } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import { toast } from 'react-toastify';
import { SystemConfiguration } from '../../../app/models/SystemConfiguration';
import DefaultTable from '../../../components/default/DefaultTable';
import ModalEditSystemConfiguration from '../components/ModalEditSystemConfiguration';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import useSystemConfigurationService from '../../../hooks/services/useSystemConfigurationService';
import {
    type MRT_ColumnDef,
} from 'material-react-table';


const SystemConfigurationPage = () => {
    const breadcrumbs = [
        {
            name: 'Configuraciones del Sistema',
            url: '/system-configurations',
            isActive: true
        }
    ];

    const {
        fetchingGetSystemConfigurations,
        getSystemConfigurations,
    } = useSystemConfigurationService();

    const [systemConfiguration, setSystemConfiguration] = useState<SystemConfiguration[]>([]);

    const [showingEditView, setShowingEditView] = useState(false);
    const [idToEdit, setIdToEdit] = useState(0);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getSystemConfigurations({
            onSuccess: (response) => {
                setSystemConfiguration(response.data.system_configurations);
            }
        }).then();
    };

    const showEditView = (id: number) => {
        setShowingEditView(true);
        setIdToEdit(id);
    };

    const closeEditView = () => {
        setShowingEditView(false);
        setIdToEdit(0);
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                header: 'Días de alerta de notificación de pago',
                accessorKey: 'days_payment_date_alert',
            },
            {
                header: 'Días de alerta de término de contrato',
                accessorKey: 'days_contract_end_alert',
            },
            {
                header: 'Acciones',
                accessorKey: 'id',
                Cell: ({ cell }: { cell: any }) => {
                    return (
                        <div className="btn-group btn-group-sm">
                            <ButtonTableEdit onClick={() => showEditView(cell.getValue())} />
                        </div>
                    );
                },
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }
        ],
        [history]
    );

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Configuración del Sistema" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultTable
                    data={systemConfiguration}
                    tableLoaded={fetchingGetSystemConfigurations}
                    columns={columns}
                />
            </DefaultCard>

            {showingEditView && (
                <ModalEditSystemConfiguration
                    id={idToEdit}
                    show={showingEditView}
                    onClose={closeEditView}
                    onSuccess={() => {
                        toast.success('Configuración del Sistema editado con éxito');
                        setShowingEditView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al editar la configuración del sistema');
                    }}
                />
            )}
        </>
    );
};

export default SystemConfigurationPage;
