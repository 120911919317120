import { User } from './User';
import { Bank } from './Bank';

export type Lessor = {
    id: number;
    phone: string;
    id_number: string;
    phone_code: string;
    bank_account_number: string;
    email: string;
    address: string;
    business_name: string;
    id_number_type: string;
    bank_account_type: string;
    bank_id: number;

    bank?: Bank;
};
export const initialLessor: Lessor = {
    id: 0,
    phone: '',
    id_number: '',
    phone_code: '+56',
    bank_account_number: '',
    email: '',
    address: '',
    business_name: '',
    id_number_type: '',
    bank_account_type: '',
    bank_id: 0
};
