import { ServiceHeaders, ServiceParams, ServiceResponse } from './types';

import { Method, ResponseStatus } from './enums';
import { BearerToken } from './BearerToken';

export class GenericFetch extends BearerToken {
    constructor(storageNameBearerToken?: string) {
        super(storageNameBearerToken);
    }

    doGet = (serviceParams: ServiceParams) => {
        return this.abstractFetch(Method.GET, serviceParams);
    };

    doPost = (serviceParams: ServiceParams) => {
        return this.abstractFetch(Method.POST, serviceParams);
    };

    doPut = (serviceParams: ServiceParams) => {
        return this.abstractFetch(Method.PUT, serviceParams);
    };

    doDelete = (serviceParams: ServiceParams) => {
        return this.abstractFetch(Method.DELETE, serviceParams);
    };

    doCreate = (serviceParams: ServiceParams) => {
        return this.abstractFetch(Method.GET, serviceParams);
    };

    private abstractFetch = async (method: Method, serviceParams: ServiceParams) => {
        const {
            url,
            body,
            headers,
            requestType,
            responseBlob,
            onSuccess,
            onError,
            onInfo,
            onWarning,
            onFieldError
        } = serviceParams;

        let httpStatus = 0;
        return fetch(url, {
            method: method,
            headers: { ...this.fetchDefaultHeaders, ...headers },
            body: body
                ? requestType == 'multipart' || requestType == 'form-data'
                    ? this.getFormData(body)
                    : JSON.stringify(body)
                : null
        })
            .then((response) => {
                httpStatus = response.status;
                if (responseBlob) {
                    return response.blob();
                }
                return response.json();
            })
            .then((response) => {
                if (responseBlob) {
                    response = {
                        data: response,
                        status: httpStatus == 200 ? ResponseStatus.SUCCESS : ResponseStatus.ERROR,
                        title: '',
                        message: ''
                    };
                }
                response.http_status = httpStatus;
                this.handleResponse(response, onSuccess, onError, onInfo, onWarning, onFieldError);
                return Promise.resolve(response);
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };

    private handleResponse = (
        response: ServiceResponse,
        onSuccess?: (response: ServiceResponse) => void,
        onError?: (response: ServiceResponse) => void,
        onInfo?: (response: ServiceResponse) => void,
        onWarning?: (response: ServiceResponse) => void,
        onFieldError?: (response: ServiceResponse) => void
    ) => {
        switch (response.status) {
            case ResponseStatus.SUCCESS:
                if (onSuccess) {
                    return onSuccess(response);
                }
                break;
            case ResponseStatus.ERROR:
                if (onError) {
                    return onError(response);
                }
                break;
            case ResponseStatus.INFO:
                if (onInfo) {
                    return onInfo(response);
                }
                break;
            case ResponseStatus.WARNING:
                if (onWarning) {
                    return onWarning(response);
                }
                break;
            case ResponseStatus.FIELDS_VALIDATION:
                if (onFieldError) {
                    return onFieldError(response);
                }
                break;
            default:
                if (onError) {
                    onError(response);
                }
                break;
        }
    };

    private getFormData = (object: any) => {
        return this.objectToFormData(object);
    };

    private objectToFormData = (data: any, formData: FormData = new FormData()) => {
        let indexFiles = 0;
        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
                return;
            }
            if (data[key] instanceof File) {
                formData.append(key, data[key]);
            } else if (Array.isArray(data[key]) || data[key] instanceof Object) {
                if (Array.isArray(data[key])) {
                    data[key].forEach((item: any) => {
                        if (item instanceof Object) {
                            Object.keys(item).forEach((_key: any) => {
                                formData.append(`${key}[${indexFiles}]${_key}`, item[_key]);
                            });
                            indexFiles++;
                            // objectToFormData(item, formData.);
                        } else if (item instanceof File) {
                            formData.append(key + '[]', item);
                        } else {
                            formData.append(key, JSON.stringify(item));
                        }
                    });
                } else {
                    Object.keys(data[key]).forEach((subKey) => {
                        if (data[key][subKey] instanceof File) {
                            formData.append(`${key}[${subKey}]`, data[key][subKey]);
                        } else {
                            formData.append(`${key}[${subKey}]`, JSON.stringify(data[key][subKey]));
                        }
                    });
                }
            } else {
                formData.append(key, data[key]);
            }
        });
        return formData;
    };
}
