import React, { useContext, useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { LOCAL_STORAGE } from '../template/config';
import useAuthService from '../hooks/services/useAuthService';
import { AuthContext } from '../template/contexts/auth';
import Loading from '../template/components/ui/Loading';

interface Props {
    children: React.ReactNode;
    path: string;
    exact?: boolean;
}

const PublicRouteMiddleware = ({ children, ...rest }: Props) => {
    const history = useHistory();
    const { fetchingIsAuthenticated, isAuthenticated } = useAuthService();

    useEffect(() => {
        const localAuthToken = localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN);
        if (localAuthToken) {
            isAuthenticated({
                onSuccess: () => {
                    localStorage.removeItem(LOCAL_STORAGE.AUTH_USER);
                    history.push('/');
                }
            }).then();
        }
    }, []);

    if (fetchingIsAuthenticated) {
        return <Loading />;
    }

    return <Route {...rest} render={(props) => children} />;
};

export default PublicRouteMiddleware;
