import React, { useRef } from 'react';

type Props = {
    handleChangeDocumentFile: (files: any, type: string) => void;
    objectFile: any;
    name: string;

    disabled?: boolean;
};

const InputButtonFile = ({
    handleChangeDocumentFile,
    objectFile,
    name,
    disabled = false
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const download = () => {
        if (typeof objectFile === 'string' && objectFile !== '') {
            window.open(objectFile, '_blank');
        } else {
            const url = window.URL.createObjectURL(objectFile);
            const a = document.createElement('a');
            // Obtener el nombre del archivo
            const fileName = objectFile.name;
            a.href = url;
            a.download = fileName;
            a.click();
        }
    };

    return (
        <>
            <input
                ref={inputRef}
                onChange={(e) => handleChangeDocumentFile(e.target.files, name)}
                className="d-none"
                type="file"
                accept=".docx,.xls,.pdf"
                name={name}
                disabled={disabled}
                id={name}
            />

            <div className={'input-group mb-3'}>
                <span
                    onClick={download}
                    className={`input-group-text ${
                        objectFile
                            ? 'text-white bg-success border-success pointer m-0'
                            : 'text-white border-secondary bg-secondary secondary'
                    }`}
                    id="basic-addon1"
                >
                    <i className={`${objectFile ? 'bx bx-download' : 'bx bx-file'}`} />
                </span>

                <button
                    style={{ zIndex: 0 }}
                    onClick={handleUpload}
                    disabled={disabled}
                    className={`form-control btn btn-outline-${
                        objectFile ? 'success' : 'secondary'
                    }`}
                >
                    {objectFile
                        ? (
                              objectFile?.name ??
                              objectFile.split('/')[objectFile.split('/').length - 1]
                          )
                              .substring(0, 19)
                              .toLowerCase()
                        : 'Cargar Archivo'}
                </button>
            </div>
        </>
    );
};

export default InputButtonFile;
