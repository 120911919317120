import React, { useEffect, useState } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import { initialLessor, Lessor } from '../../../app/models/Lessor';
import FieldInput from '../../../components/fields/FieldInput';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import Loading from '../../../template/components/ui/Loading';
import useLessorsService from '../../../hooks/services/useLessorsService';
import LessorDataForm from './LessorDataForm';
import useRut from '../../../hooks/useRut';
import { Bank } from '../../../app/models/Bank';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';

type Props = {
    id: number;
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
};

const ModalEditLessor = ({ id, show, onClose, onSuccess, onError }: Props) => {
    const { fetchingEditLessor, editLessor, fetchingUpdateLessor, updateLessor } =
        useLessorsService();
    const { validateRut, formatRut, isValidRut } = useRut();

    const [dataForm, setDataForm] = useState<Lessor>(initialLessor);
    const [accountTypes, setAccountTypes] = useState<any[]>([]);
    const [errors, setErrors] = useState<object>({});
    const [banks, setBanks] = useState<Bank[]>([]);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editLessor(id, {
            onSuccess: (response) => {
                setDataForm(response.data.lessor);
                setBanks(response.data.banks);
                setAccountTypes(response.data.account_types);
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };
    const update = () => {
        updateLessor(id, dataForm, {
            onSuccess: (response) => {
                onSuccess();
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        if (e.target.name == 'id_number') {
            if (isValidRut(e.target.value)) {
                setDataForm({
                    ...dataForm,
                    id_number: formatRut(e.target.value)
                });
                return;
            }
            setErrors({
                ...errors,
                [e.target.name]: ''
            });
        }

        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    };

    return (
        <DefaultModal
            show={show}
            handleClose={onClose}
            title="Editar arrendatario"
            centered={true}
            actionButton={<ButtonSaveForm onClick={update} loading={fetchingUpdateLessor} />}
        >
            {fetchingEditLessor ? (
                <Loading height={200} />
            ) : (
                <LessorDataForm
                    dataForm={dataForm}
                    handleChange={handleChange}
                    banks={banks}
                    account_types={accountTypes}
                    validateRut={validateRut}
                    disabled={fetchingEditLessor}
                    errors={errors}
                />
            )}
        </DefaultModal>
    );
};

export default ModalEditLessor;
