import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    onClick: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
};

const ButtonTableDestroy = ({
    onClick,
    title = 'Eliminar',
    loading = false,
    locked = false,
    message = 'No se puede eliminar este registro'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !loading ? (
        <span
            onClick={locked ? showLockedMessage : onClick}
            className="text-danger icon-pointer mx-2 my-auto"
            title={title}
        >
            <i className="bx bx-trash-alt font-size-18" />
        </span>
    ) : (
        <span className="text-danger mx-2 my-auto disabled" title={title}>
            <i className="bx bx-trash-alt font-size-18" />
        </span>
    );
};

export default ButtonTableDestroy;
