import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    onClick: () => void;
    title: string;
    icon: string;
    variant?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
};

const ButtonTable = ({
    onClick,
    title,
    icon = 'bx bx-group font-size-18',
    variant = 'primary',
    loading = false,
    locked = false,
    message = 'No se puede editar este registro'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !loading ? (
        <span
            onClick={locked ? showLockedMessage : onClick}
            className={`text-${variant} icon-pointer mx-2 my-auto`}
            title={title}
        >
            <i className={`font-size-18 ${icon}`} />
        </span>
    ) : (
        <span className={`text-${variant} icon-pointer mx-2 my-auto disabled`} title={title}>
            <i className={`font-size-18 ${icon}`} />
        </span>
    );
};

export default ButtonTable;
