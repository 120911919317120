import React from 'react';
import useFieldError from '../../hooks/useFieldError';

type Props = {
    label?: string;
    name: string;
    nameFieldError?: string;
    id?: string;
    max?: number;
    min?: number;
    step?: number;
    placeholder?: string;
    value?: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

    onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
    errors?: object;
    disabled?: boolean;
    required?: boolean;
    className?: string;
};

const FieldNumber = ({
    label,
    id,
    name,
    nameFieldError,

    max,
    min,
    step = 1,
    placeholder,
    value,
    onChange,
    onBlur,
    errors,
    disabled,
    required,
    className
}: Props) => {
    const { error, clearError } = useFieldError(nameFieldError ?? name, errors);
    function formatAmount(amount: number) {
        if (String(amount).split('.')[1] === '00') {
            return Math.round(amount);
        }
        return Number(amount);
    }
    return (
        <div className={`${className ? className : ''} ${error ? 'has-danger' : ''}`}>
            {label && (
                <label htmlFor={id ?? name}>
                    {label} {required ? '*' : ''}
                </label>
            )}

            <input
                type="number"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={id ?? name}
                name={name}
                placeholder={placeholder}
                value={value && formatAmount(value)}
                onChange={onChange}
                onBlur={(event) => {
                    clearError();
                    onBlur && onBlur(event);
                }}
                disabled={disabled}
                required={required}
                max={max}
                min={min}
                step={step}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export default FieldNumber;
