import React from 'react';
import wrapper from '../../../template/assets/images/defaults/auth-wrapper.jpg';

type Props = {
    children: React.ReactNode;
    title: string;
    description: string;
};

const Wrapper = ({ children, title, description }: Props) => {
    return (
        <div className="row g-0 ">
            <div className="col-md-4">
                <div className="d-flex vh-100 p-5">
                    <div
                        className="m-auto"
                        style={{
                            width: '100%',
                            maxWidth: '400px'
                        }}
                    >
                        <h1 className="text-center">{title}</h1>
                        <p className="text-center">{description}</p>

                        <br />
                        <hr />
                        <br />
                        {children}
                    </div>
                </div>
            </div>
            <div className="col-md-8 bg-white">
                <img
                    src={wrapper}
                    alt="Login"
                    className="img-fluid"
                    style={{
                        height: '100vh',
                        width: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                />
            </div>
        </div>
    );
};

export default Wrapper;
