import { useEffect, useState } from 'react';

const useFieldError = (name: string, errors: any) => {
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (errors) {
            setError(fieldErrors(name));
        }
    }, [errors, name]);

    const fieldErrors = (name: string) => {
        if (errors && typeof errors === 'object' && name in (errors as any)) {
            const value = (errors as any)[name];
            if (Array.isArray(value) && value.length > 0) {
                return value[0];
            }
        }
        return undefined;
    };

    const clearError = () => {
        if (error) {
            setError(null);
        }
    };

    return { error, clearError };
};

export default useFieldError;
