import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import useAuthService from '../hooks/services/useAuthService';
import Loading from '../template/components/ui/Loading';
import { AuthContext } from '../template/contexts/auth';

interface Props {
    children: React.ReactNode;
    path: string;
    exact?: boolean;
}

const PrivateRouteMiddleware = ({ children, ...rest }: Props) => {
    const history = useHistory();
    const { fetchingIsAuthenticated, isAuthenticated } = useAuthService();
    const { authUser, flushAuthUser } = useContext(AuthContext);

    useEffect(() => {
        isAuthenticated({
            onError: () => {
                flushAuthUser();
                history.push('/login');
            }
        })
            .then()
            .catch(() => {
                flushAuthUser();
                history.push('/login');
            });
    }, []);

    if (fetchingIsAuthenticated) {
        return <Loading />;
    }

    return <Route {...rest} render={(props) => children} />;
};

export default PrivateRouteMiddleware;
