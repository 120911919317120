import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    onClick: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
};

const ButtonTableEdit = ({
    onClick,
    title = 'Editar',
    loading = false,
    locked = false,
    message = 'No se puede editar este registro'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !loading ? (
        <span
            onClick={locked ? showLockedMessage : onClick}
            className="text-warning icon-pointer mx-2 my-auto"
            title={title}
        >
            <i className="bx bx-edit-alt font-size-18" />
        </span>
    ) : (
        <span className="text-warning mx-2 my-auto disabled" title={title}>
            <i className="bx bx-edit-alt font-size-18" />
        </span>
    );
};

export default ButtonTableEdit;
