import React, { ChangeEvent, useEffect, useState } from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { Currency } from '../../../app/models/Currency';
import FieldDate from '../../../components/fields/FieldDate';
import { WarrantyType } from '../../../app/models/WarrantyType';
import { Bank } from '../../../app/models/Bank';
import FieldInput from '../../../components/fields/FieldInput';

type Props = {
    currencies: Currency[];
    banks: Bank[];
    warrantyTypes: WarrantyType[];
    onUpdateWarrantyTypes: (updatedWarrantyTypes: WarrantyType[]) => void;
    disabled: boolean;
    errors: any;
    reformatNumber: number;
};

const WarrantyTypeDataForm = ({
    onUpdateWarrantyTypes,
    currencies,
    banks,
    warrantyTypes,
    disabled,
    errors,
    reformatNumber,
}: Props) => {
    const [localWarrantyTypes, setLocalWarrantyTypes] = useState(warrantyTypes);

    useEffect(() => {
        setLocalWarrantyTypes(
            warrantyTypes.map((warrantyType) => {
                if (!warrantyType.amount) {
                    return warrantyType;
                }
                const value = String(warrantyType.amount);
                const [integerPart, decimalPart] = value.split('.');
                const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                    Number(integerPart)
                );
                warrantyType.amount = String(
                    value.split('.').length > 1
                        ? `${formattedIntegerPart},${decimalPart}`
                        : formattedIntegerPart
                );
                return warrantyType;
            })
        );
    }, [reformatNumber]);
    const handleWarrantyTypeChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        index: number,
        isUploadDataForm = true
    ) => {
        let value = event.target.value;
        const name = event.target.name;
        const updatedWarrantyTypes = localWarrantyTypes.map((warrantyType, i) => {
            if (i === index) {
                if (name === 'amount' && value !== '') {
                    if (!/^[0-9,.]+$/.test(value)) {
                        value = value.replace(/[^0-9,.]/g, '');
                    }
                    const [integerPart, decimalPart] = value.split(',');
                    // Limpiar y formatear la parte entera
                    const cleanedIntegerPart = integerPart.replace(/\./g, '');
                    const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                        Number(cleanedIntegerPart)
                    );

                    // Combinar las partes formateadas
                    const formattedValue =
                        value.split(',').length > 1
                            ? `${formattedIntegerPart},${decimalPart}`
                            : formattedIntegerPart;

                    return { ...warrantyType, [name]: String(formattedValue) };
                }
                return {
                    ...warrantyType,
                    [event.target.name]: event.target.value
                };
            }
            return warrantyType;
        });

        setLocalWarrantyTypes(updatedWarrantyTypes);
        if (isUploadDataForm) {
            onUpdateWarrantyTypes(updatedWarrantyTypes);
        }
    };

    const changeBlurred = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | undefined
    ) => {
        if (e) {
            onUpdateWarrantyTypes(localWarrantyTypes);
        }
    };

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Moneda</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Fecha de emisión</th>
                        <th scope="col">Fecha de vencimiento</th>
                        <th scope="col">Banco</th>
                        {/* Agrega más encabezados de columna aquí según sea necesario */}
                    </tr>
                </thead>
                <tbody>
                    {localWarrantyTypes.map((warrantyType, index) => (
                        <tr key={index}>
                            <td>
                                <p>{warrantyType.type.toUpperCase()}</p>
                            </td>
                            <td>
                                <FieldSelect
                                    name={'currency'}
                                    value={warrantyType.currency}
                                    errors={errors}
                                    nameFieldError={'warranty_types.' + index + '.currency'}
                                    disabled={disabled}
                                    options={currencies.map(
                                        (currency) =>
                                            ({
                                                value: currency.value,
                                                label: currency.label
                                            } as FieldSelectOption)
                                    )}
                                    onChange={(event) => handleWarrantyTypeChange(event, index)}
                                />
                            </td>
                            <td>
                                <FieldInput
                                    name={'amount'}
                                    value={warrantyType.amount}
                                    errors={errors}
                                    nameFieldError={'warranty_types.' + index + '.amount'}
                                    disabled={disabled}
                                    onBlur={changeBlurred}
                                    onChange={(event) =>
                                        handleWarrantyTypeChange(event, index, false)
                                    }
                                />
                            </td>
                            <td>
                                <FieldDate
                                    name={'emission_date'}
                                    value={warrantyType.emission_date}
                                    errors={errors}
                                    nameFieldError={'warranty_types.' + index + '.emission_date'}
                                    disabled={disabled}
                                    onChange={(event) =>
                                        handleWarrantyTypeChange(event, index, false)
                                    }
                                />
                            </td>
                            <td>
                                <FieldDate
                                    name={'expiration_date'}
                                    value={warrantyType.expiration_date}
                                    errors={errors}
                                    nameFieldError={'warranty_types.' + index + '.expiration_date'}
                                    disabled={disabled}
                                    onChange={(event) => handleWarrantyTypeChange(event, index)}
                                />
                            </td>
                            <td>
                                <FieldSelect
                                    name={'bank_id'}
                                    value={warrantyType.bank_id}
                                    errors={errors}
                                    nameFieldError={'warranty_types.' + index + '.bank_id'}
                                    disabled={disabled}
                                    options={banks.map((bank) => ({
                                        value: bank.id,
                                        label: bank.name
                                    }))}
                                    onChange={(event) => handleWarrantyTypeChange(event, index)}
                                />
                            </td>
                            {/* Renderiza otras celdas con campos de WarrantyType aquí */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default WarrantyTypeDataForm;
