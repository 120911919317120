import React from 'react';
import Template from './template';
import { Router, Route, Switch } from 'react-router-dom';
import PageNotFound from './template/layouts/PageNotFound';
import Dashboard from './pages/dashboard';
import LoginPage from './pages/auth/Login';
import RegisterPage from './pages/auth/Register';
import ConfirmEmailPage from './pages/auth/ConfirmEmail';
import PrivateRouteMiddleware from './routes/private-middleware';
import PublicRouteMiddleware from './routes/public-middleware';
import TemplateProvider from './template/contexts/template';
import AuthProvider from './template/contexts/auth';
import NotificationsProvider from './template/contexts/notification';
import ForgotPasswordPage from './pages/auth/ForgotPassword';
import ResetPasswordPage from './pages/auth/ResetPassword';
import ResetPasswordStep2 from './pages/auth/ResetPasswordStep2';
import ContractsPage from './pages/contracts/pages/ContractsPage';
import ProvidersPage from './pages/providers/pages/ProvidersPage';
import ContractEditPage from './pages/contracts/pages/ContractEditPage';
import CostCentersPage from './pages/cost_centers/pages/CostCentersPage';
import CostCenterPaymentsPage from './pages/cost_centers/pages/CostCenterPaymentsPage';
// import ContractPaymentsPage from './pages/contracts/pages/ContractPaymentsPage';
// import ContractPaymentServicesPage from './pages/contracts/pages/ContractPaymentServicesPage';
import ContractCreatePage from './pages/contracts/pages/ContracCreatePage';
import ContractHistoryPage from './pages/contracts/pages/ContracHistoryPage';
import UsersPage from './pages/users/pages/UsersPage';
import LessorsPage from './pages/lessors/pages/LessorsPage';
import NotificationsPage from './pages/notifications/pages/NotificationsPage';
import NotificationShow from './pages/notifications/pages/NotificationShow';
// import ContractsPaymentsPage from './pages/contracts/pages/ContractsPaymentsPage';
import SystemConfigurationPage from './pages/system_configurations/pages/SystemConfiguratioPage';
import { history } from './instrument';

const App = () => {
    //add to body data-sidebar="dark" data-layout-mode="light"

    // const body = document.getElementsByTagName('body')[0];
    // body.setAttribute('data-sidebar', 'dark');
    // body.setAttribute('data-layout-mode', 'light');

    return (
        <AuthProvider>
            <TemplateProvider>
                <NotificationsProvider>
                    <Router history={history}>
                        <Switch>
                            {/*<Route path="/unauthorized" component={PageUnauthorized} />*/}

                            <PublicRouteMiddleware exact path="/confirm/:token?">
                                <Template withWrapper={false}>
                                    <ConfirmEmailPage />
                                </Template>
                            </PublicRouteMiddleware>
                            <PublicRouteMiddleware exact path="/reset-password">
                                <Template withWrapper={false}>
                                    <ResetPasswordPage />
                                </Template>
                            </PublicRouteMiddleware>
                            <PublicRouteMiddleware exact path="/reset-password-step-two">
                                <Template withWrapper={false}>
                                    <ResetPasswordStep2 />
                                </Template>
                            </PublicRouteMiddleware>
                            <PublicRouteMiddleware exact path="/forgot-password">
                                <Template withWrapper={false}>
                                    <ForgotPasswordPage />
                                </Template>
                            </PublicRouteMiddleware>
                            <PublicRouteMiddleware exact path="/login">
                                <Template withWrapper={false}>
                                    <LoginPage />
                                </Template>
                            </PublicRouteMiddleware>
                            {/*<PublicRouteMiddleware exact path="/register">*/}
                            {/*    <Template withWrapper={false}>*/}
                            {/*        <RegisterPage />*/}
                            {/*    </Template>*/}
                            {/*</PublicRouteMiddleware>*/}

                            {/*<PrivateRouteMiddleware*/}
                            {/*    exact*/}
                            {/*    path="/contracts/:contract_id/contract-payments"*/}
                            {/*>*/}
                            {/*    <Template>*/}
                            {/*        <ContractPaymentsPage />*/}
                            {/*    </Template>*/}
                            {/*</PrivateRouteMiddleware>*/}
                            {/*<PrivateRouteMiddleware*/}
                            {/*    exact*/}
                            {/*    path="/contracts/:contract_id/contract-payments/:contract_payment_id/contract-payment-services"*/}
                            {/*>*/}
                            {/*    <Template>*/}
                            {/*        <ContractPaymentServicesPage />*/}
                            {/*    </Template>*/}
                            {/*</PrivateRouteMiddleware>*/}
                            <PrivateRouteMiddleware exact path="/contracts/:contract_id/edit">
                                <Template>
                                    <ContractEditPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/contracts/:app_log_id/history">
                                <Template>
                                    <ContractHistoryPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/contracts/create">
                                <Template>
                                    <ContractCreatePage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/contracts">
                                <Template>
                                    <ContractsPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            {/* <PrivateRouteMiddleware exact path="/contracts/:contract_id/payments">
                                <Template>
                                    <ContractPaymentsPage />
                                </Template>
                            </PrivateRouteMiddleware> */}
                            {/* <PrivateRouteMiddleware exact path="/contracts-payments">
                                <Template>
                                    <ContractsPaymentsPage />
                                </Template>
                            </PrivateRouteMiddleware> */}
                            <PrivateRouteMiddleware exact path="/providers">
                                <Template>
                                    <ProvidersPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/lessors">
                                <Template>
                                    <LessorsPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/notifications/:notification_id">
                                <Template>
                                    <NotificationShow />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/notifications">
                                <Template>
                                    <NotificationsPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/system-configurations">
                                <Template>
                                    <SystemConfigurationPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware exact path="/users">
                                <Template>
                                    <UsersPage />
                                </Template>
                            </PrivateRouteMiddleware>

                            <PrivateRouteMiddleware exact path="/cost_centers">
                                <Template>
                                    <CostCentersPage />
                                </Template>
                            </PrivateRouteMiddleware>
                            <PrivateRouteMiddleware
                                exact
                                path="/cost_centers/:cost_center_id/payments">
                                <Template>
                                    <CostCenterPaymentsPage />
                                </Template>
                            </PrivateRouteMiddleware>

                            <PrivateRouteMiddleware exact path="/">
                                <Template>
                                    <Dashboard />
                                </Template>
                            </PrivateRouteMiddleware>

                            <Route path="*" component={PageNotFound} />
                        </Switch>
                    </Router>
                </NotificationsProvider>
            </TemplateProvider>
        </AuthProvider>
    );
};

export default App;
