import React from 'react';

export type ThemeMode = ThemeType;

export enum ThemeType {
    LIGHT = 'light',
    DARK = 'dark'
}

export type SidebarItem = {
    name: string;
    icon?: string;
    path?: string;
    type: 'title' | 'item' | 'collapse' | 'group';
    children?: SidebarItem[];
};

export type Page = {
    name: string;
    path: string;
    component: React.ComponentType;
    middleware: React.JSXElementConstructor<any>;
    exact?: boolean;
    withWrapper?: boolean;
    forceMobile?: boolean;
};

export type Middleware = {
    name: string;
    path: string;
    component: React.ComponentType;
    exact?: boolean;
    withWrapper?: boolean;
    forceMobile?: boolean;
};

export type BreadcrumbsItem = {
    name: string;
    url: string;
    isActive: boolean;
};

export type Variant =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';

export type TemplateConfig = {
    APP: {
        NAME: string;
        VERSION: string;
        AUTHOR: string;
        AUTHOR_URL: string;
        COPYRIGHT: string;
        COPYRIGHT_URL: string;
        DESCRIPTION: string;
        KEYWORDS: string;
        FAVICON: string;
        LOGO_BIG_LIGHT: string;
        LOGO_BIG_DARK: string;
        LOGO_SMALL_LIGHT: string;
        LOGO_SMALL_DARK: string;
        LOGO: string;
    };
    THEME_MODE: ThemeMode;
    SIDEBAR: SidebarItem[];
};

export type LocalStorageConfig = {
    AUTH_USER: string;
    AUTH_TOKEN: string;
    AUTH_ROLES: string;
    AUTH_PERMISSIONS: string;
    TEMPLATE_CONFIG: string;
};
