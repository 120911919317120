import React from 'react';

type NoRecordsProps = {
    type?: string;
    message?: string;
};

const NoRecords = ({ type = 'primary', message = 'No existen registros.' }: NoRecordsProps) => {
    return (
        <div className={`mb-0 alert alert-${type} fade show text-center`} role="alert">
            <span className="font-12">{message}</span>
        </div>
    );
};

export default NoRecords;
