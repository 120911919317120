import { ServiceHeaders } from './types';
import { LOCAL_STORAGE } from '../../template/config';

export class BearerToken {
    private readonly storageNameBearerToken: string = LOCAL_STORAGE.AUTH_TOKEN || '';
    public token = '';

    constructor(storageNameBearerToken?: string) {
        if (storageNameBearerToken) {
            this.storageNameBearerToken = storageNameBearerToken;
        }
        this.token = localStorage.getItem(this.storageNameBearerToken) || '';
    }

    get fetchDefaultHeaders(): ServiceHeaders {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
        };
    }

    setToken(token: string) {
        this.token = token;
        localStorage.setItem(this.storageNameBearerToken, token);
    }

    removeToken() {
        this.token = '';
        localStorage.removeItem(this.storageNameBearerToken);
    }

    getToken() {
        return this.token;
    }
}
