import React, { useState } from 'react';
import FieldSwitch from '../../../components/fields/FieldSwitch';
import FieldNumber from '../../../components/fields/FieldNumber';
import { ContractCreateForm, ContractUpdateForm } from '../../../app/models/Contract';
import { CostCenter } from '../../../app/models/CostCenter';
import { Lessor } from '../../../app/models/Lessor';
import { Currency } from '../../../app/models/Currency';
import FieldDate from '../../../components/fields/FieldDate';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/fields/SelectTwo';
import moment from 'moment';

type CustomChangeEvent = {
    target: {
        name: string;
        value: any;
    };
};

type Props = {
    costCenters: CostCenter[];
    lessors: Lessor[];
    currencies: Currency[];
    dataForm: ContractCreateForm | ContractUpdateForm;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | CustomChangeEvent
    ) => void;
    disabled: boolean;
    errors: any;
};

const ContractDataForm = ({
    costCenters,
    lessors,
    dataForm,
    handleChange,
    disabled,
    errors
}: Props) => {
    const [localDataForm, setLocalDataForm] = React.useState(dataForm);

    const changeBlurred = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | undefined
    ) => {
        if (e) {
            handleChange(e);
        }
    };

    const localHandleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setLocalDataForm({
            ...localDataForm,
            [e.target.name]: e.target.value
        });
    };

    const handleCostCenterChange = (selectedOption: any) => {
        const event: CustomChangeEvent = {
            target: {
                name: 'cost_center_id',
                value: selectedOption ? selectedOption.value : ''
            }
        };
        handleChange(event);
    };

    const handleLessorChange = (selectedOption: any) => {
        const event: CustomChangeEvent = {
            target: {
                name: 'lessor_id',
                value: selectedOption ? selectedOption.value : ''
            }
        };
        handleChange(event);
    };

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const event: CustomChangeEvent = {
            target: {
                name: e.target.name,
                value: e.target.checked
            }
        };
        handleChange(event);
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-4">
                    <FieldDate
                        label={'Fecha de inicio'}
                        name={'start_date'}
                        value={dataForm.start_date}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
                <div className="col-md-4">
                    <FieldDate
                        label={'Fecha de termino'}
                        name={'end_date'}
                        value={dataForm.end_date}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>

                <div className="col-md-4">
                    <FieldDate
                        label={'Fecha de entrega'}
                        name={'remodeling_date'}
                        value={dataForm.remodeling_date}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                {
                    localDataForm.grace_days != null ? (
                        <>
                            <div className="col-md-6">
                                <FieldNumber
                                    name={'grace_days'}
                                    label={'Dias de gracia'}
                                    value={localDataForm.grace_days}
                                    onBlur={changeBlurred}
                                    onChange={localHandleChange}
                                    disabled={disabled}
                                    errors={errors}
                                    min={0}
                                />
                            </div>
                            <div className="col-md-6">
                                <FieldDate
                                    label={'Fecha de pago inicial de arriendo'}
                                    name={'initial_payment_date'}
                                    value={moment(dataForm.start_date)
                                        .add(dataForm.grace_days, 'days')
                                        .format('YYYY-MM-DD')}
                                    onChange={handleChange}
                                    disabled={true}
                                    errors={errors}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-md-6">
                                <FieldNumber
                                    name={'grace_months'}
                                    label={'Meses de gracia'}
                                    value={localDataForm.grace_months}
                                    onBlur={changeBlurred}
                                    onChange={localHandleChange}
                                    disabled={disabled}
                                    errors={errors}
                                    min={0}
                                />
                            </div>
                            <div className="col-md-6">
                                <FieldDate
                                    label={'Fecha de pago inicial de arriendo'}
                                    name={'initial_payment_date'}
                                    value={moment(dataForm.start_date)
                                        .add(dataForm.grace_months, 'months')
                                        .format('YYYY-MM-DD')}
                                    onChange={handleChange}
                                    disabled={true}
                                    errors={errors}
                                />
                            </div>
                        </>
                    )
                }
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="mb-1" htmlFor="end_date">
                        Razón Social
                    </label>
                    <SelectTwo
                        name="cost_center_id"
                        inputValue={dataForm.cost_center_id}
                        onChange={handleCostCenterChange}
                        options={SelectTwoMapperOptions(costCenters, 'id', ['type', 'name', 'cost_code_id', 'society_name', 'address'])}
                        isMulti={false}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-6">
                    <label className="mb-1" htmlFor="lessor_id">
                        Arrendatario
                    </label>
                    <SelectTwo
                        name="lessor_id"
                        inputValue={dataForm.lessor_id}
                        onChange={handleLessorChange}
                        options={SelectTwoMapperOptions(lessors, 'id', ['id_number', 'business_name'])}
                        isMulti={false}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <FieldSwitch
                        label="Renovación Automática"
                        name="is_renewable"
                        value={dataForm.is_renewable}
                        onChange={handleSwitchChange}
                        errors={errors}
                    />
                </div>
            </div>
        </>
    );
};

export default ContractDataForm;
