import { CHANGE_THEME_MODE, SHOW_FULL_SCREEN_LOADER } from './types';
import { ReducerActionInterface, TemplateContextStateInterface } from './interfaces';

const reducerApp = (state: TemplateContextStateInterface, action: ReducerActionInterface) => {
    switch (action.type) {
        case CHANGE_THEME_MODE:
            return {
                ...state,
                themeMode: action.payload
            };
        case SHOW_FULL_SCREEN_LOADER:
            return {
                ...state,
                showingFullScreenLoader: action.payload
            };
        default:
            return state;
    }
};
export default reducerApp;
