import React, { useContext } from 'react';
import { TemplateContext } from '../../contexts/template';
import { ThemeMode, ThemeType } from '../../types';

const ChangeModeTheme = () => {
    const { themeMode, setThemeMode } = useContext(TemplateContext);

    const handleTheme = (theme: ThemeMode) => {
        if (setThemeMode) {
            setThemeMode(theme);
        }
    };

    return (
        <button
            type="button"
            className="btn header-item noti-icon right-bar-toggle"
            title="Modo Claro"
            onClick={() =>
                handleTheme(themeMode === ThemeType.DARK ? ThemeType.LIGHT : ThemeType.DARK)
            }
        >
            <i className={`bx ${themeMode === ThemeType.DARK ? 'bx-sun' : 'bx-moon'}`} />
        </button>
    );
};

export default ChangeModeTheme;
