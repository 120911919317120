import { useState } from 'react';
import { Lessor } from '../../app/models/Lessor';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import { LessorFetchRepository } from '../../app/services/LessorFetchRepository';

const useLessorsService = () => {
    const repository = new LessorFetchRepository();

    const [fetchingGetLessors, setFetchingGetLessors] = useState(false);
    const [fetchingStoreLessor, setFetchingStoreLessor] = useState(false);
    const [fetchingEditLessor, setFetchingEditLessor] = useState(false);
    const [fetchingUpdateLessor, setFetchingUpdateLessor] = useState(false);
    const [fetchingDeleteLessor, setFetchingDeleteLessor] = useState(false);
    const [fetchingCreateLessor, setFetchingCreateLessor] = useState(false);
    const getLessors = async (events: ServiceEvents = {}) => {
        setFetchingGetLessors(true);
        try {
            return repository.all(events).finally(() => {
                setFetchingGetLessors(false);
            });
        } catch (error) {
            setFetchingGetLessors(false);
        }
    };

    const storeLessor = async (lessor: Lessor, events: ServiceEvents = {}) => {
        setFetchingStoreLessor(true);
        try {
            return repository.store(lessor, events).finally(() => {
                setFetchingStoreLessor(false);
            });
        } catch (error) {
            setFetchingStoreLessor(false);
        }
    };

    const editLessor = async (id: number, events: ServiceEvents = {}) => {
        setFetchingEditLessor(true);
        try {
            return repository.edit(id, events).finally(() => {
                setFetchingEditLessor(false);
            });
        } catch (error) {
            setFetchingEditLessor(false);
        }
    };

    const createLessor = async (events: ServiceEvents = {}) => {
        setFetchingCreateLessor(true);
        try {
            return repository.create(events).finally(() => {
                setFetchingCreateLessor(false);
            });
        } catch (error) {
            setFetchingCreateLessor(false);
        }
    };

    const updateLessor = async (id: number, service: Lessor, events: ServiceEvents = {}) => {
        setFetchingUpdateLessor(true);
        try {
            return repository.update(id, service, events).finally(() => {
                setFetchingUpdateLessor(false);
            });
        } catch (error) {
            setFetchingUpdateLessor(false);
        }
    };

    const deleteLessor = async (id: number, events: ServiceEvents = {}) => {
        setFetchingDeleteLessor(true);
        try {
            return repository.delete(id, events).finally(() => {
                setFetchingDeleteLessor(false);
            });
        } catch (error) {
            setFetchingDeleteLessor(false);
        }
    };

    return {
        fetchingGetLessors,
        fetchingStoreLessor,
        fetchingEditLessor,
        fetchingUpdateLessor,
        fetchingDeleteLessor,
        fetchingCreateLessor,
        getLessors,
        storeLessor,
        editLessor,
        updateLessor,
        deleteLessor,
        createLessor
    };
};

export default useLessorsService;
