import React, { useEffect, useState, useMemo } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import ModalCreateContract from '../components/ModalCreateContract';
import { toast } from 'react-toastify';
import { Service } from '../../../app/models/Service';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import DefaultTable from '../../../components/default/DefaultTable';
import useContractsService from '../../../hooks/services/useContractsService';
import { Contract } from '../../../app/models/Contract';
import useSweetAlert from '../../../hooks/useSweetAlert';
import ButtonTable from '../../../components/buttons/ButtonTable';
import moment from 'moment';
import { CostCenter } from '../../../app/models/CostCenter';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/fields/SelectTwo';
import useCostCentersService from '../../../hooks/services/useCostCentersService';
import FieldNumber from '../../../components/fields/FieldNumber';
import { formatAmount } from '../utils';
import { Lessor } from "../../../app/models/Lessor";
import { OtherExpense } from "../../../app/models/OtherExpense";
import { ExitClause } from "../../../app/models/ExitClause";
import {
    type MRT_ColumnDef,
} from 'material-react-table';

const ContractsPage = () => {
    const history = useHistory();

    const breadcrumbs = [
        {
            name: 'Contratos',
            url: '/contracts',
            isActive: true
        }
    ];

    const {
        fetchingGetContracts,
        getContracts,
        deleteContract,
        getContractsPDF,
        getContractsExcel
    } = useContractsService();

    const { fetchingGetCostCenters, getCostCenters } = useCostCentersService();

    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [month, setMonth] = useState<string>(moment().format('YYYY-MM'));
    const [showingCreateView, setShowingCreateView] = useState(false);
    const [currency, setCurrency] = useState<string>('UF');
    const [costCenterSelected, setCostCenterSelectedSelected] = useState<any[]>([]);
    const [year, setYear] = useState<string>(moment().format('YYYY'));

    useEffect(() => {
        init();
        getCostCenters({
            onSuccess: (response) => {
                setCostCenters(response.data.cost_centers);
            }
        }).then();
    }, []);

    const init = () => {
        const costCenterArray = costCenterSelected.map((costCenter) => {
            return costCenter.label;
        });
        getContracts(currency, month, year, costCenterArray, {
            onSuccess: (response) => {
                setContracts(remapContractForColumns(response.data.contracts));
            }
        }).then();
    };

    const remapContractForColumns = (contracts: any) => {
        return contracts.map((contract: any) => {
            return {
                id: contract.id,
                business_name: contract.cost_center.cost_code_id + ' - ' + contract.cost_center.name,
                society_name: getSocietyName(contract.cost_center.society_name),
                lessor: contract.lessor.id_number + ' | ' + contract.lessor.business_name,
                start_date: moment(contract.start_date).format('DD-MM-YYYY'),
                end_date: moment(contract.end_date).format('DD-MM-YYYY'),
                exit_date: getExitDate(contract.exit_clauses) ? moment(getExitDate(contract.exit_clauses)).format('DD-MM-YYYY') : '',
                active_rule_rent_amount: formatAmount(contract.active_rule_rent_amount, contract.active_rule_rent_currency),
                vpm: contract.active_rule_monthly_sale_percentage ? contract.active_rule_monthly_sale_percentage + '%' : '0%',
                vpm_clp: formatAmount(contract.vpm_clp, 'CLP'),
                total_sales: formatAmount(contract.total_sale, contract.active_rule_rent_currency),
                common_expense_amount: formatAmount(getCommonExpenseAmount(contract.other_expenses), getCommonExpenseCurrency(contract.other_expenses)),
                total_pay_for_rent_uf: formatAmount(contract.total_to_pay_for_rent_uf, 'UF'),
                total_pay_for_rent_clp: formatAmount(contract.total_to_pay_for_rent_clp, 'CLP'),
                is_rent_from_vpm: contract.is_rent_from_vpm ? 'VPM' : 'CANON',
                is_renewable: contract.is_renewable ? 'Sí' : 'No',
            };
        });
    }

    const getSocietyName = (societyName: string) => {
        let societyNameMapped = '';

        switch (societyName) {
            case 'MELT_PIZZA':
                societyNameMapped = 'Melt Pizza';
                break;
            case 'UNDER_PIZZA':
                societyNameMapped = 'Under Pizza';
                break;
            case 'NIU_SUSHI':
                societyNameMapped = 'Niu Sushi';
                break;
            case 'GUACAMOLE':
                societyNameMapped = 'Guacamole';
                break;
            case 'KAO':
                societyNameMapped = 'Kao';
                break;
            default:
                societyNameMapped = societyName;
        }

        return societyNameMapped
    };

    const getExitDate = (exitClauses: ExitClause[]) => {
        if (!exitClauses || exitClauses.length === 0) {
            return '';
        }
        const sortedExitClauses = exitClauses.sort((a: ExitClause, b: ExitClause) => moment(a.end_date).diff(moment(b.end_date)));
        return sortedExitClauses[0].end_date;
    };

    const getCommonExpenseAmount = (otherExpenses: OtherExpense[]) => {
        if (!otherExpenses || otherExpenses.length === 0) {
            return 0;
        }

        const index = otherExpenses.findIndex((expense: OtherExpense) => expense.name === 'gastos comunes');

        if (index === -1) {
            return 0;
        }

        return parseFloat(otherExpenses[index].amount.toString());
    };

    const getCommonExpenseCurrency = (otherExpenses: OtherExpense[]) => {
        if (!otherExpenses || otherExpenses.length === 0) {
            return '';
        }

        const index = otherExpenses.findIndex((expense: OtherExpense) => expense.name === 'gastos comunes');

        if (index === -1) {
            return '';
        }

        return otherExpenses[index].currency;
    }

    const destroy = (id: number) => {
        const { requestConfirmation } = useSweetAlert();
        requestConfirmation({
            title: '¿Estás seguro?',
            text: 'No podrás revertir esta acción',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteContract(id, {
                    onSuccess: (response) => {
                        toast.success(response.message ?? 'Proveedor eliminado con éxito');
                        init();
                    }
                }).then();
            }
        });
    };

    const downloadPDF = () => {
        const costCenterArray = costCenterSelected.map((costCenter) => {
            return costCenter.label;
        });
        getContractsPDF(currency, month, year, costCenterArray, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'contratos.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };

    const downloadExcel = () => {
        const costCenterArray = costCenterSelected.map((costCenter) => {
            return costCenter.label;
        });
        getContractsExcel(currency, month, year, costCenterArray, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'contratos.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                header: 'Razón Social',
                accessorKey: 'business_name',
            },
            {
                header: 'Marca',
                accessorKey: 'society_name',
            },
            {
                header: 'Arrendador',
                accessorKey: 'lessor',
            },
            {
                header: 'Fecha de inicio',
                accessorKey: 'start_date',
                sortingFn: (rowA, rowB) => {
                    const dateA = moment(rowA.getValue('start_date'), 'DD-MM-YYYY');
                    const dateB = moment(rowB.getValue('start_date'), 'DD-MM-YYYY');

                    if (dateA.isBefore(dateB)) {
                        return -1;
                    }
                    if (dateA.isAfter(dateB)) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                header: 'Fecha de término',
                accessorKey: 'end_date',
                sortingFn: (rowA, rowB) => {
                    const dateA = moment(rowA.getValue('end_date'), 'DD-MM-YYYY');
                    const dateB = moment(rowB.getValue('end_date'), 'DD-MM-YYYY');

                    if (dateA.isBefore(dateB)) {
                        return -1;
                    }
                    if (dateA.isAfter(dateB)) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                header: 'Fecha Anticipada de Salida',
                accessorKey: 'exit_date',
                sortingFn: (rowA, rowB) => {
                    const dateA = rowA.getValue('exit_date');
                    const dateB = rowB.getValue('exit_date');

                    if (!dateA && !dateB) {
                        return 0;
                    }
                    if (!dateA) {
                        return 1;
                    }
                    if (!dateB) {
                        return -1;
                    }

                    const momentDateA = moment(dateA, 'DD-MM-YYYY');
                    const momentDateB = moment(dateB, 'DD-MM-YYYY');

                    if (momentDateA.isBefore(momentDateB)) {
                        return -1;
                    }
                    if (momentDateA.isAfter(momentDateB)) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                header: 'Arriendo Vigente',
                accessorKey: 'active_rule_rent_amount',
            },
            {
                accessorKey: 'common_expense_amount',
                header: 'Gasto Común',
            },
            {
                accessorKey: 'total_pay_for_rent_uf',
                header: 'Total a Pagar por Arriendo (UF)',
            },
            {
                accessorKey: 'total_pay_for_rent_clp',
                header: 'Total a Pagar por Arriendo',
            },
            {
                header: 'VPM',
                accessorKey: 'vpm',
            },
            {
                header: 'VPM en Pesos',
                accessorKey: 'vpm_clp',
            },
            {
                header: 'Ventas Totales',
                accessorKey: 'total_sales',
            },
            {
                header: 'Arriendo a Pagar',
                accessorKey: 'is_rent_from_vpm',
            },
            {
                accessorKey: 'is_renewable',
                header: 'Renovación Automática',
            },
            {
                header: 'Acciones',
                accessorKey: 'id',
                Cell: ({ cell }: { cell: any }) => {
                    return (
                        <div className="btn-group btn-group-sm">
                            <ButtonTableEdit
                                onClick={() => history.push(`contracts/${cell.getValue()}/edit`)}
                            />
                            <ButtonTable
                                onClick={() =>
                                    history.push(`/contracts/${cell.getValue()}/payments`)
                                }
                                title="Ver pagos"
                                icon="bx bx-dollar"
                                variant="info"
                            />
                        </div>
                    );
                },
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }
        ],
        [history]
    );

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Contratos" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <>
                            <div className="row">
                                <div className="col mt-4">
                                    <ButtonCreate
                                        onClick={() => history.push(`contracts/create`)}
                                        title="Nuevo Contrato"
                                        className={'btn btn-primary me-4'}
                                    />
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-danger me-4`}
                                        onClick={() => downloadPDF()}
                                    >
                                        <i className="fa fa-file-pdf" /> Descargar PDF
                                    </button>
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-success`}
                                        onClick={() => downloadExcel()}
                                    >
                                        <i className="fa fa-file-excel" /> Descargar Excel
                                    </button>
                                </div>

                                <div className="col-md-3">
                                    <label className="mb-1" htmlFor="end_date">
                                        Razones sociales
                                    </label>
                                    <SelectTwo
                                        name="cost_center_id"
                                        inputValue={costCenterSelected}
                                        onChange={(selectedOptions) => {
                                            setCostCenterSelectedSelected(selectedOptions);
                                        }}
                                        options={SelectTwoMapperOptions(costCenters, 'id', [
                                            'cost_code_id', 'name'
                                        ])}
                                        isMulti={true}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label className="mb-1" htmlFor="year">
                                        Contratos del año
                                    </label>

                                    <div className="col-auto">
                                        <FieldNumber
                                            name={'year'}
                                            value={Number(year)}
                                            onChange={(e) => setYear(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-auto mt-4">
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-primary btn-block`}
                                        onClick={init}
                                    >
                                        <i className="fa fa-filter" /> Filtrar
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                />

                <DefaultTable
                    data={contracts}
                    tableLoaded={fetchingGetContracts}
                    columns={columns}
                />

                {showingCreateView && (
                    <ModalCreateContract
                        showingView={showingCreateView}
                        closeView={() => setShowingCreateView(false)}
                        onSuccess={(response) => {
                            setShowingCreateView(false);
                            toast.success(response.message ?? 'Contrato creado con éxito');
                            init();
                        }}
                    />
                )}
            </DefaultCard>
        </>
    );
};

export default ContractsPage;
