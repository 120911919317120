import React, { ChangeEvent, useEffect, useState } from 'react';
import { ContractCreateForm, initialContractCreateForm } from '../../../app/models/Contract';
import { CostCenter } from '../../../app/models/CostCenter';
import { Currency } from '../../../app/models/Currency';
import useContractsService from '../../../hooks/services/useContractsService';
import { Lessor } from '../../../app/models/Lessor';
import DefaultModal from '../../../components/default/DefaultModal';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ServiceResponse } from '../../../packages/generic-fetch/types';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { useHistory } from 'react-router-dom';
import ContractDataForm from './ContractDataForm';
import { ContractEndpoint } from '../../../app/services/APIConnection';
import Loading from '../../../template/components/ui/Loading';

type Props = {
    showingView: boolean;
    closeView: () => void;
    onSuccess?: (response: ServiceResponse) => void;
    onError?: (response: ServiceResponse) => void;
};

const ModalCreateContract = ({ showingView, closeView, onSuccess, onError }: Props) => {
    const history = useHistory();

    const { fetchingCreateContract, createContract } = useContractsService();

    const [fetchingStoreContract, setFetchingStoreContract] = useState<boolean>(false);

    const [errors, setErrors] = useState<object>({});
    const [dataForm, setDataForm] = useState<ContractCreateForm>(initialContractCreateForm);
    const [file, setFile] = useState<File>();

    const [costCenters, setLocales] = useState<CostCenter[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [lessors, setLessors] = useState<Lessor[]>([]);

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createContract({
            onSuccess: (response) => {
                setLocales(response.data.costCenters);
                setCurrencies(response.data.currencies);
                setLessors(response.data.lessors);
            }
        }).then();
    };

    const store = () => {
        setFetchingStoreContract(true);
        const formData = new FormData();

        if (file) {
            formData.append('file', file as Blob);
        }

        try {
            fetch(ContractEndpoint.store(), {
                method: 'POST',
                body: formData
            })
                .then(async (response) => {
                    const result: ServiceResponse = await response.json();

                    if (result.status == 'success') {
                        showRedirect(result);
                    }

                    if (result.status == 'error') {
                        onError && onError(result);
                    }

                    if (result.status == 'fields_validation') {
                        setErrors(result.data.errors);
                    }
                })
                .then(() => {
                    setFetchingStoreContract(false);
                });
        } catch (e) {
            setFetchingStoreContract(false);
        }
    };

    const showRedirect = (response: ServiceResponse) => {
        const { requestConfirmation } = useSweetAlert();

        requestConfirmation({
            title: 'Contrato creado con éxito',
            text: '¿Desea continuar editándolo contrato?',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            onConfirm: () => {
                history.push(`/contracts/${response.data.contract.id}/edit`);
            },
            onCancel: () => {
                onSuccess && onSuccess(response);
            }
        });
    };

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
            // setDataForm({
            //     ...dataForm,
            //     file: event.target.files[0].name
            // });
        }
    };

    return (
        <DefaultModal
            title="Nuevo Contrato"
            show={showingView}
            handleClose={() => {
                closeView();
            }}
            actionButton={<ButtonSaveForm onClick={store} loading={fetchingStoreContract} />}
        >
            {/*{fetchingCreateContract ? (*/}
            {/*    <Loading height={300} />*/}
            {/*) : (*/}
            {/*    <ContractDataForm*/}
            {/*        dataForm={dataForm}*/}
            {/*        errors={errors}*/}
            {/*        costCenters={costCenters}*/}
            {/*        currencies={currencies}*/}
            {/*        lessors={lessors}*/}
            {/*        handleChange={handleChange}*/}
            {/*        disabled={fetchingCreateContract}*/}
            {/*    />*/}
            {/*)}*/}
        </DefaultModal>
    );
};

export default ModalCreateContract;
