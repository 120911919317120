import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { ProviderEndpoint } from './APIConnection';
import { ProviderCreateForm, ProviderRepository } from '../models/Provider';

export class ProvidersFetchRepository extends GenericFetch implements ProviderRepository {
    all(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ProviderEndpoint.all()
        });
    }

    store(service: ProviderCreateForm, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: ProviderEndpoint.store(),
            body: service
        });
    }

    edit(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ProviderEndpoint.edit(id)
        });
    }

    create(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ProviderEndpoint.create()
        });
    }

    update(
        id: number,
        service: ProviderCreateForm,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doPut({
            ...events,
            url: ProviderEndpoint.update(id),
            body: service
        });
    }

    delete(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doDelete({
            ...events,
            url: ProviderEndpoint.delete(id)
        });
    }
}
