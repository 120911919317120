import React, { ChangeEvent, useEffect, useState } from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { Currency } from '../../../app/models/Currency';
import FieldDate from '../../../components/fields/FieldDate';
import { ContractService } from '../../../app/models/ContractService';
import { Bank } from '../../../app/models/Bank';
import FieldSwitch from '../../../components/fields/FieldSwitch';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/fields/SelectTwo';

type Props = {
    contractServices: ContractService[];
    onUpdateContractServices: (updatedContractServices: ContractService[]) => void;
    disabled: boolean;
    errors: any;
};

const ContractServiceDataForm = ({
    onUpdateContractServices,
    contractServices,
    disabled,
    errors
}: Props) => {
    const [localContractServices, setLocalContractServices] = useState(contractServices);

    useEffect(() => {
        setLocalContractServices(contractServices);
    }, [contractServices]);

    const handleContractServiceChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        index: number,
        isUploadDataForm = true
    ) => {
        const updatedContractServices = localContractServices.map((contractService, i) => {
            if (i === index) {
                const target = event.target as HTMLInputElement; // type assertion

                const value = target.type === 'checkbox' ? target.checked : target.value;
                return {
                    ...contractService,
                    [target.name]: value
                };
            }
            return contractService;
        });

        setLocalContractServices(updatedContractServices);
        if (isUploadDataForm) {
            onUpdateContractServices(updatedContractServices);
        }
    };

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Proveedores</th>
                        <th scope="col">Numero de cliente</th>
                        <th scope="col">Incluido en los gastos comunes</th>
                        {/* Agrega más encabezados de columna aquí según sea necesario */}
                    </tr>
                </thead>
                <tbody>
                    {localContractServices.map((contractService, index) => (
                        <tr key={index}>
                            <td>
                                <p>{contractService.service_name.toUpperCase()}</p>
                            </td>
                            {/*<td>*/}
                            {/*    <SelectTwo*/}
                            {/*        options={SelectTwoMapperOptions(*/}
                            {/*            contractService.providers ?? []*/}
                            {/*        )}*/}
                            {/*        inputValue={contractService.provider_id}*/}
                            {/*        onChange={(selectedOption) => {*/}
                            {/*            const syntheticEvent = {*/}
                            {/*                target: {*/}
                            {/*                    name: 'provider_id',*/}
                            {/*                    value: selectedOption.value,*/}
                            {/*                    type: 'select-one' // Específico para elementos HTMLSelectElement*/}
                            {/*                },*/}
                            {/*                currentTarget: {*/}
                            {/*                    name: 'provider_id',*/}
                            {/*                    value: selectedOption.value,*/}
                            {/*                    type: 'select-one' // Específico para elementos HTMLSelectElement*/}
                            {/*                },*/}
                            {/*                type: 'change'*/}
                            {/*            } as ChangeEvent<HTMLSelectElement>;*/}
                            {/*            handleContractServiceChange(syntheticEvent, index);*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</td>*/}

                            <td>
                                <FieldSelect
                                    name={'provider_id'}
                                    value={contractService.provider_id}
                                    onChange={(event) => handleContractServiceChange(event, index)}
                                    options={SelectTwoMapperOptions(
                                        contractService.providers ?? []
                                    )}
                                    errors={errors}
                                    nameFieldError={'contract_services.' + index + '.provider_id'}
                                    disabled={disabled}
                                />
                            </td>

                            <td>
                                <SelectTwo
                                    options={SelectTwoMapperOptions([])}
                                    inputValue={contractService.client_number}
                                    onChange={(selectedOption) => {
                                        const syntheticEvent = {
                                            target: {
                                                name: 'client_number',
                                                value: selectedOption,
                                                type: 'select-one' // Específico para elementos HTMLSelectElement
                                            },
                                            currentTarget: {
                                                name: 'client_number',
                                                value: selectedOption,
                                                type: 'select-one' // Específico para elementos HTMLSelectElement
                                            },
                                            type: 'change'
                                        } as ChangeEvent<HTMLSelectElement>;
                                        handleContractServiceChange(syntheticEvent, index);
                                    }}
                                    disabled={disabled}
                                    isAddableOption={true}
                                    isMulti={true}
                                />
                            </td>

                            <td>
                                <FieldSwitch
                                    name={'is_included_common_expenses'}
                                    value={contractService.is_included_common_expenses}
                                    errors={errors}
                                    disabled={disabled}
                                    onChange={(event) => handleContractServiceChange(event, index)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default ContractServiceDataForm;
