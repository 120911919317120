import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import { ServiceResponse } from '../../../packages/generic-fetch/types';
import { toast } from 'react-toastify';
import useContractsService from '../../../hooks/services/useContractsService';
import { Contract } from '../../../app/models/Contract';
import moment from 'moment/moment';
import DefaultTable from '../../../components/default/DefaultTable';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    type MRT_ColumnDef,
} from 'material-react-table';

type Props = {
    contractId: number;
    showingView: boolean;
    closeView: () => void;
    onSuccess?: (response: ServiceResponse) => void;
    onError?: (response: ServiceResponse) => void;
};
type Params = {
    app_log_id: string;
};
const ModalContractHistory = ({
    contractId,
    showingView,
    closeView,
    onSuccess,
    onError
}: Props) => {
    const { getContractHistories, fetchingGetContractHistories } = useContractsService();
    const history = useHistory();
    const params = useParams<Params>();

    const [errors, setErrors] = useState<object>({});
    const [dataForm, setDataForm] = useState<[]>();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getContractHistories(contractId, {
            onSuccess: (response) => {
                setDataForm(reMapData(response.data));
            },
            onError: (response) => {
                toast.error('Error al cargar los datos');
                closeView();
            }
        }).then();
    };

    const reMapData = (data: any) => {
        return data.map((item: any) => {
            return {
                id: item.id,
                created_at: moment(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
                action: item.action === 'created' ? 'Creación' : 'Actualización',
                lessor: item.lessor.full_name + ' ' + item.lessor.last_name,
            };
        });
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                header: 'Fecha del registro',
                accessorKey: 'created_at',
                Cell: ({ cell, row }: { cell: any, row: any }) => {
                    return (
                        <b
                            className={'pointer'}
                            onClick={() => history.push(`/contracts/${row.id}/history`)}
                        >
                            {cell.getValue()}
                        </b>
                    );
                },
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            },
            {
                header: 'Acción',
                accessorKey: 'action',
            },
            {
                header: 'Arrendador',
                accessorKey: 'lessor',
            },
        ],
        [history]
    );

    return (
        <DefaultModal
            title="Historial de Contrato"
            show={showingView}
            handleClose={() => {
                closeView();
            }}
            centered={true}
        >

            <DefaultTable
                data={dataForm ?? []}
                tableLoaded={fetchingGetContractHistories}
                columns={columns}
            />

        </DefaultModal>
    );
};

export default ModalContractHistory;
