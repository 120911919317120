import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import { UserNotification } from '../../../app/models/Notification';
import useNotificationsService from '../../../hooks/services/useNotificationService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

type Params = {
    notification_id: string;
};

const NotificationShow = () => {
    const params = useParams<Params>();

    const PAYMENT_DATE_ALERT = 'PAYMENT_DATE_ALERT';
    const CONTRACT_END_ALERT = 'CONTRACT_END_ALERT';
    const CONTRACT_CLAUSE_ALERT = 'CONTRACT_CLAUSE_ALERT';

    const {
        showNotification
    } = useNotificationsService();

    const breadcrumbs = [
        {
            name: 'Notificaciones',
            url: '/notifications',
            isActive: false
        },
        {
            name: `Notificación ${params.notification_id}`,
            url: `/notifications/${params.notification_id}`,
            isActive: true
        }
    ];

    const [userNotification, setUserNotification] = useState<UserNotification | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsLoading(true);
        showNotification(parseInt(params.notification_id), {
            onSuccess: (response) => {
                setUserNotification(response.data);
                setIsLoading(false);
            },
            onError: (error) => {
                console.error(error);
                setIsLoading(false);
            }
        });
    };



    if (isLoading) {
        return (
            <>
                <Breadcrumbs pageSection="Bandeja de Notificaciones" breadcrumbs={breadcrumbs} />
                <DefaultCard>
                    <div className="card-body">
                        <div className="text-center mt-5">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </div>
                    </div>
                </DefaultCard>
            </>

        );
    }

    const buildContent = () => {

        if (!userNotification) {
            return null;
        }

        switch (userNotification.notification.type) {
            case PAYMENT_DATE_ALERT:
                return (
                    userNotification?.notification.contract_notifications?.map((item, index) => (
                        <React.Fragment key={index}>
                            <div className="notification-item module mt-3">
                                <Link to={`/contracts/${item.contract.id}/payments`} className="media-body text-reset mt-2">
                                    <h6 className="mt-0 mb-1">{item.contract.cost_center.cost_code_id} - {item.contract.cost_center.name}</h6>
                                    <p className="text-muted mb-0"> Fecha de Inicio: {moment(item.contract.start_date).format('DD-MM-YYYY')} </p>
                                    <p className="text-muted mb-0"> Arrendatario: {item.contract.lessor.id_number} - {item.contract.lessor.business_name} </p>
                                </Link>
                            </div>
                        </React.Fragment>
                    ))
                )
            case CONTRACT_END_ALERT:
                return (
                    userNotification?.notification.contract_notifications?.map((item, index) => (
                        <React.Fragment key={index}>
                            <div className="notification-item module mt-3">
                                <Link to={`/contracts/${item.contract.id}/edit`} className="media-body text-reset mt-2">
                                    <h6 className="mt-0 mb-1">{item.contract.cost_center.cost_code_id} - {item.contract.cost_center.name}</h6>
                                    <p className="text-muted mb-0"> Arrendatario: {item.contract.lessor.id_number} - {item.contract.lessor.business_name} </p>
                                    <p className="text-muted mb-0"> Fecha de fin de contrato: {moment(item.contract.end_date).format('DD-MM-YYYY')}   </p>
                                </Link>
                            </div>
                        </React.Fragment>
                    ))
                )
            case CONTRACT_CLAUSE_ALERT:
                return (
                    userNotification?.notification.contract_notifications?.map((item, index) => (
                        <React.Fragment key={index}>
                            <div className="notification-item module mt-3">
                                <Link to={`/contracts/${item.contract.id}/edit`} className="media-body text-reset mt-2">
                                    <h6 className="mt-0 mb-1">{item.contract.cost_center.cost_code_id} - {item.contract.cost_center.name}</h6>
                                    <p className="text-muted mb-0"> Arrendatario: {item.contract.lessor.id_number} - {item.contract.lessor.business_name} </p>
                                    <p className="text-muted mb-0"> Cláusula: {item.exit_clause.name} </p>
                                    <p className="text-muted mb-0"> Fecha de fin de cláusula: {moment(item.exit_clause.end_date).format('DD-MM-YYYY')} </p>
                                </Link>
                            </div>
                        </React.Fragment>
                    ))
                )

            default:
                return null;
        }
    };

    return (
        <>
            <Breadcrumbs pageSection="Bandeja de Notificaciones" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="card-body">
                    <div className="notification-header d-flex justify-content-between align-items-start">
                        <div>
                            <h3 className="notification-title">{userNotification?.notification.title}</h3>
                            <p className="notification-message">{userNotification?.notification.message}</p>
                        </div>
                        <p className="notification-date text-muted mb-0">
                            <i className="mdi mdi-clock-outline" /> {moment(userNotification?.notification.created_at).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </div>

                <div className="card-body">
                    {buildContent()}
                </div>
            </DefaultCard>
        </>
    );
};

export default NotificationShow;
