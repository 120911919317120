import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    onClick: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
    className?: string;
};

const ButtonCreate = ({
    onClick,
    title = 'Nuevo',
    loading = false,
    locked = false,
    message = 'No se puede crear un registro.',
    className = 'btn-primary'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !loading ? (
        <button
            type="button"
            className={`waves-effect btn btn-smm ${className}`}
            onClick={locked ? showLockedMessage : onClick}
        >
            <i className="fa fa-plus" /> {title}
        </button>
    ) : (
        <button type="button" className={`waves-effect btn btn-smm ${className} disabled`}>
            <i className="fa fa-plus" /> {title}
        </button>
    );
};

export default ButtonCreate;
