import React, { useEffect, useState, useMemo } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import { Lessor } from '../../../app/models/Lessor';
import DefaultTable from '../../../components/default/DefaultTable';
import ModalCreateLessor from '../components/ModalCreateLessor';
import ModalEditLessor from '../components/ModalEditLessor';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import useSweetAlert from '../../../hooks/useSweetAlert';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';
import useLessorsService from '../../../hooks/services/useLessorsService';
import {
    type MRT_ColumnDef,
} from 'material-react-table';

const LessorsPage = () => {
    const breadcrumbs = [
        {
            name: 'Arrendatarios',
            url: '/lessors',
            isActive: true
        }
    ];

    const {
        fetchingGetLessors,
        getLessors,
        fetchingDeleteLessor,
        deleteLessor,
        fetchingEditLessor
    } = useLessorsService();

    const [lessors, setLessors] = useState<Lessor[]>([]);

    const [showingCreateView, setShowingCreateView] = useState(false);
    const [showingEditView, setShowingEditView] = useState(false);
    const [idToEdit, setIdToEdit] = useState(0);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getLessors({
            onSuccess: (response) => {
                setLessors(reMapLessors(response.data.lessors ?? []));
            }
        }).then();
    };

    const reMapLessors = (lessors: any) => {
        return lessors.map((lessor: any) => {
            return {
                id: lessor.id,
                id_number: lessor.id_number,
                business_name: lessor.business_name,
                address: lessor.address,
                email: lessor.email,
                phone: lessor.phone,
                phone_code: lessor.phone_code,
                bank: lessor.bank?.name,
                account_number_type: lessor.bank_account_type,
                bank_account_number: lessor.bank_account_number,
            };
        });
    };

    const showEditView = (id: number) => {
        setShowingEditView(true);
        setIdToEdit(id);
    };

    const closeEditView = () => {
        setShowingEditView(false);
        setIdToEdit(0);
    };

    const destroy = (id: number) => {
        const { requestConfirmation } = useSweetAlert();

        requestConfirmation({
            title: '¿Estás seguro?',
            text: 'No podrás revertir esta acción',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            onConfirm: () => {
                deleteLessor(id, {
                    onSuccess: () => {
                        toast.success('Arrendatario eliminado con éxito');
                        init();
                    },
                    onError: () => {
                        toast.error('Ocurrió un error al eliminar el arrendatario');
                    }
                }).then();
            }
        });
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                header: 'RUT',
                accessorKey: 'id_number',
            },
            {
                header: 'Razón Social',
                accessorKey: 'business_name',
            },
            {
                header: 'Dirección',
                accessorKey: 'address',
            },
            {
                header: 'Email',
                accessorKey: 'email',
            },
            {
                header: 'Teléfono',
                accessorKey: 'phone',
            },
            {
                header: 'Código Telefónico',
                accessorKey: 'phone_code',
            },
            {
                header: 'Banco',
                accessorKey: 'bank',
            },
            {
                header: 'Cuenta',
                accessorKey: 'account_number_type',
            },
            {
                header: 'Número de Cuenta Bancaria',
                accessorKey: 'bank_account_number',
            },
            {
                header: 'Acciones',
                accessorKey: 'id',
                Cell: ({ cell }: { cell: any }) => {
                    return (
                        <div className="btn-group btn-group-sm">
                            <ButtonTableEdit onClick={() => showEditView(cell.getValue())} />
                        </div>
                    );
                },
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }
        ],
        [history]
    );

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Arrendatarios" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            onClick={() => setShowingCreateView(true)}
                            title="Nuevo Arrendatario"
                        />
                    }
                />

                <DefaultTable
                    data={lessors}
                    tableLoaded={fetchingGetLessors}
                    columns={columns}
                />


            </DefaultCard>
            {showingCreateView && (
                <ModalCreateLessor
                    show={showingCreateView}
                    onClose={() => setShowingCreateView(false)}
                    onSuccess={() => {
                        toast.success('Arrendatario creado con éxito');
                        setShowingCreateView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al crear el arrendatario');
                    }}
                />
            )}

            {showingEditView && (
                <ModalEditLessor
                    id={idToEdit}
                    show={showingEditView}
                    onClose={closeEditView}
                    onSuccess={() => {
                        toast.success('Arrendatario editado con éxito');
                        setShowingEditView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al editar el arrendatario');
                    }}
                />
            )}
        </>
    );
};

export default LessorsPage;
