import React, { useState } from 'react';
import FieldInput from '../../components/fields/FieldInput';
import Wrapper from './components/Wrapper';
import { ValidateRecoveryPinCredentials } from '../../app/models/Auth';
import useAuthService from '../../hooks/services/useAuthService';
import ButtonSaveForm from '../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const ResetPasswordPage = () => {
    const history = useHistory();

    const { fetchingValidatePin, validateRecoveryPin } = useAuthService();

    const [credentials, setCredentials] = useState<ValidateRecoveryPinCredentials>({
        email: '',
        recovery_pin: ''
    });

    const [errors, setErrors] = useState<object>({});
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const validatePin = () => {
        validateRecoveryPin(credentials, {
            onSuccess: (response) => {
                const encryptedId = response.data;
                sessionStorage.setItem('encryptedUserId', encryptedId);
                history.push(`/reset-password-step-two`);
            },
            onError: (response) => {
                toast.error(response.message);
            },
            onFieldError: (response) => setErrors(response.data.errors)
        });
    };

    return (
        <>
            <Wrapper
                title="Cambiar contraseña"
                description="Ingresa tu código de recuperación y tu correo electrónico">
                <FieldInput
                    label="Código de recuperación"
                    name="recovery_pin"
                    type="text"
                    placeholder="Código de recuperación"
                    value={credentials.recovery_pin}
                    onChange={onChange}
                    errors={errors}
                    required={true}
                    disabled={fetchingValidatePin}
                />

                <FieldInput
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={credentials.email}
                    onChange={onChange}
                    errors={errors}
                    required={true}
                    disabled={fetchingValidatePin}
                />
                <br />
                <ButtonSaveForm
                    title="Validar Código"
                    onClick={validatePin}
                    locked={fetchingValidatePin}
                    className="w-100"
                    icon="fas fa-check"
                />
                <div className="text-center mt-2">
                    <Link to="/login" className="btn btn-link btn-block ">
                        ¿Ya tienes una cuenta? Inicia sesión
                    </Link>
                </div>
            </Wrapper>
        </>
    );
};
export default ResetPasswordPage;
