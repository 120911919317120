import React from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { User } from '../../../app/models/User';
import FieldDate from '../../../components/fields/FieldDate';
import moment from 'moment';
import FieldInput from '../../../components/fields/FieldInput';

type Props = {
    dataForm: User;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => void;

    validateRut(value: string): boolean;
    disabled: boolean;
    errors: any;

    changePassword?: boolean;
};

const UserDataForm = ({
    dataForm,
    handleChange,
    disabled,
    errors,
    validateRut,
    changePassword = false
}: Props) => {
    return (
        <>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label={'Email'}
                        name={'email'}
                        type={'email'}
                        value={dataForm.email}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label={'Nombre'}
                        name={'first_name'}
                        value={dataForm.first_name}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label={'Apellido'}
                        name={'last_name'}
                        value={dataForm.last_name}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            {changePassword && (
                <>
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <FieldInput
                                label={'Contraseña'}
                                name={'password'}
                                type={'password'}
                                value={dataForm.password}
                                onChange={handleChange}
                                disabled={disabled}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <FieldInput
                                label={'Repetir Contraseña'}
                                name={'password_confirmation'}
                                type={'password'}
                                value={dataForm.password_confirmation}
                                onChange={handleChange}
                                disabled={disabled}
                                errors={errors}
                            />
                        </div>
                    </div>
                </>
            )}

            {/*<div className="row mb-3">*/}
            {/*    <div className="col-md-12">*/}
            {/*        <FieldInput*/}
            {/*            label="Rut"*/}
            {/*            name="rut"*/}
            {/*            type="text"*/}
            {/*            placeholder="Rut"*/}
            {/*            value={dataForm.id_number}*/}
            {/*            onChange={handleChange}*/}
            {/*            errors={errors}*/}
            {/*            // onFocusOut={() => {*/}
            {/*            //     if (!validateRut(dataForm.rut ?? '0')) {*/}
            {/*            //         setDataForm({*/}
            {/*            //             ...dataForm,*/}
            {/*            //             rut: ''*/}
            {/*            //         });*/}
            {/*            //*/}
            {/*            //         setErrors({*/}
            {/*            //             ...errors,*/}
            {/*            //             rut: ['El rut no es válido']*/}
            {/*            //         });*/}
            {/*            //     }*/}
            {/*            // }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

export default UserDataForm;
