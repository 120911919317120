import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { Commune } from './Commune';

export type CostCenter = {
    id: number;
    type: string;
    name: string;
    cost_code_id: string;
    society_name: string;
    address: string;
    remote_relation_id?: number;
    commune: Commune;
};

export type CostCenterCreateForm = {
    commune_id: number;
    remote_relation_id: string;
    address: string;
    name: string;
    cost_code_id: string;
    type: string;
    society_name: string;
};

export type CostCenterRuleUpdateForm = {
    commune_id: number;
    remote_relation_id: string;
    address: string;
    cost_code_id: string;
    type: string;
    society_name: string;
};

export const initialCostCenterRuleUpdateForm: CostCenterRuleUpdateForm = {
    commune_id: 0,
    remote_relation_id: '',
    address: '',
    cost_code_id: '',
    type: '',
    society_name: ''
};

export const initialCostCenterCreateForm: CostCenterCreateForm = {
    commune_id: 0,
    remote_relation_id: '',
    name: '',
    address: '',
    cost_code_id: '',
    type: '',
    society_name: ''
};

export type CostCenterUpdateForm = {
    id: number;
    commune_id: number;
    remote_relation_id: string;
    name: string;
    address: string;
    cost_code_id: string;
    type: string;
    society_name: string;
};

export const initialCostCenterUpdateForm: CostCenterUpdateForm = {
    id: 0,
    commune_id: 0,
    name: '',
    remote_relation_id: '',
    address: '',
    cost_code_id: '',
    type: '',
    society_name: ''
};

export type CostCenterRepository = {
    all(events: ServiceEvents): Promise<ServiceResponse>;
};
