import React, { useEffect, useState } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import { initialSystemConfiguration, SystemConfiguration } from '../../../app/models/SystemConfiguration';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import Loading from '../../../template/components/ui/Loading';
import useSystemConfigurationService from '../../../hooks/services/useSystemConfigurationService';
import SystemConfigurationDataForm from './SystemConfigurationDataForm';

type Props = {
    id: number;
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
};

const ModalEditSystemConfiguration = ({ id, show, onClose, onSuccess, onError }: Props) => {
    const { fetchingEditSystemConfiguration, editSystemConfiguration, fetchingUpdateSystemConfiguration, updateSystemConfiguration } =
        useSystemConfigurationService();

    const [dataForm, setDataForm] = useState<SystemConfiguration>(initialSystemConfiguration);
    const [errors, setErrors] = useState<object>({});

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editSystemConfiguration(id, {
            onSuccess: (response) => {
                setDataForm(response.data.system_configuration);
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };
    const update = () => {
        updateSystemConfiguration(id, dataForm, {
            onSuccess: (response) => {
                onSuccess();
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    };

    return (
        <DefaultModal
            show={show}
            handleClose={onClose}
            title="Editar Configuración del Sistema"
            centered={true}
            actionButton={<ButtonSaveForm onClick={update} loading={fetchingUpdateSystemConfiguration} />}
        >
            {fetchingEditSystemConfiguration ? (
                <Loading height={200} />
            ) : (
                <SystemConfigurationDataForm
                    dataForm={dataForm}
                    handleChange={handleChange}
                    errors={errors}
                />
            )}
        </DefaultModal>
    );
};

export default ModalEditSystemConfiguration;
