import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { Service } from './Service';

export type Provider = {
    id: number;
    name: string;
    service_id: number;
    service_name: string;
    service: Service;
};

export type ProviderCreateForm = {
    name: string;
    service_id: number;
};

export const initialProviderCreateForm: ProviderCreateForm = {
    name: '',
    service_id: 0
};

export type ProviderUpdateForm = {
    id: number;
    name: string;
    service_id: number;
};

export const initialProviderUpdateForm: ProviderUpdateForm = {
    id: 0,
    name: '',
    service_id: 0
};

export type ProviderRepository = {
    all(events: ServiceEvents): Promise<ServiceResponse>;
};
