import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { NotificationEndpoint } from './APIConnection';
// import { Notification } from '../models/Notification';

export class NotificationFetchRepository extends GenericFetch {
    all(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: NotificationEndpoint.all()
        });
    }

    show(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: NotificationEndpoint.show(id)
        });
    }

    getNotifications(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: NotificationEndpoint.getNotifications()
        });
    }

    markAsRead(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: NotificationEndpoint.markAsRead(id)
        });
    }

    markAllAsRead(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: NotificationEndpoint.markAllAsRead()
        });
    }
}
