import React, { ChangeEvent, useEffect, useState } from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { Currency } from '../../../app/models/Currency';
import FieldDate from '../../../components/fields/FieldDate';
import { RuleMonthlySale } from '../../../app/models/RuleMonthlySale';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';
import FieldInput from '../../../components/fields/FieldInput';
import { numberToInputText } from '../utils';

type Props = {
    currencies: Currency[];

    typeMonthlySales: any[];
    ruleMonthlySales: RuleMonthlySale[];
    onUpdateRuleMonthlySales: (updatedRuleMonthlySales: RuleMonthlySale[]) => void;
    disabled: boolean;
    errors: any;
    reformatNumber: number;
};

const RuleMonthlySaleDataForm = ({
    onUpdateRuleMonthlySales,
    currencies,
    typeMonthlySales,
    ruleMonthlySales,
    disabled,
    errors,
    reformatNumber
}: Props) => {
    const [localRuleMonthlySales, setLocalRuleMonthlySales] = useState(ruleMonthlySales);

    useEffect(() => {
        setLocalRuleMonthlySales(
            ruleMonthlySales.map((ruleMonthlySale) => {
                const amount = String(ruleMonthlySale.amount);
                const monthly_rent_percentage = String(ruleMonthlySale.monthly_rent_percentage);
                ruleMonthlySale.amount = numberToInputText(amount);
                ruleMonthlySale.monthly_rent_percentage =
                    numberToInputText(monthly_rent_percentage);
                return ruleMonthlySale;
            })
        );
    }, [reformatNumber]);

    const addRuleMonthlySales = () => {
        const newRuleMonthlySales: RuleMonthlySale = {
            amount: '',
            contract_id: 0,
            expiration_date: '',
            id: 0,
            currency: '',
            monthly_rent_percentage: '8',
            type: 'total_amount'
        };

        const updatedRuleMonthlySales = [newRuleMonthlySales, ...localRuleMonthlySales];
        setLocalRuleMonthlySales(updatedRuleMonthlySales);
        onUpdateRuleMonthlySales(updatedRuleMonthlySales);
    };

    const handleRuleMonthlySalesChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        index: number,
        isUploadDataForm = true
    ) => {
        let value = event.target.value;
        const name = event.target.name;

        const updatedRuleMonthlySales = localRuleMonthlySales.map((ruleRent, i) => {
            if (i === index) {
                if ((name === 'amount' || name === 'monthly_rent_percentage') && value !== '') {
                    if (!/^[0-9,.]+$/.test(value)) {
                        value = value.replace(/[^0-9,.]/g, '');
                    }
                    const [integerPart, decimalPart] = value.split(',');
                    // Limpiar y formatear la parte entera
                    const cleanedIntegerPart = integerPart.replace(/\./g, '');
                    const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                        Number(cleanedIntegerPart)
                    );

                    // Combinar las partes formateadas
                    const formattedValue =
                        value.split(',').length > 1
                            ? `${formattedIntegerPart},${decimalPart}`
                            : formattedIntegerPart;

                    return { ...ruleRent, [name]: String(formattedValue) };
                }
                return { ...ruleRent, [name]: value };
            }
            return ruleRent;
        });

        setLocalRuleMonthlySales(updatedRuleMonthlySales);
        if (isUploadDataForm) {
            onUpdateRuleMonthlySales(updatedRuleMonthlySales);
        }
    };

    const changeBlurred = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | undefined
    ) => {
        if (e) {
            onUpdateRuleMonthlySales(localRuleMonthlySales);
        }
    };

    const handleDeleteRuleMonthlySales = (index: number) => {
        const updatedRuleMonthlySales = [...localRuleMonthlySales];
        updatedRuleMonthlySales.splice(index, 1);
        setLocalRuleMonthlySales(updatedRuleMonthlySales);
        onUpdateRuleMonthlySales(updatedRuleMonthlySales);
    };

    return (
        <>
            <div className="row mb-2">
                <div className="col-md-12">
                    {!disabled ? (
                        <button
                            type="button"
                            className="btn btn-primary float-end"
                            disabled={disabled}
                            onClick={addRuleMonthlySales}
                        >
                            Agregar Arriendo Variable
                        </button>
                    ) : null}
                </div>
            </div>
            <table className="table ">
                <thead>
                    <tr>
                        <th scope="col">Porcentaje</th>
                        <th scope="col">Tipo venta</th>
                        <th scope="col">Vigencia Hasta</th>
                        <th scope="col">Acciones</th>
                        {/* Agrega más encabezados de columna aquí según sea necesario */}
                    </tr>
                </thead>
                <tbody>
                    {localRuleMonthlySales.map((ruleRent, index) => (
                        <tr key={index}>
                            <td>
                                <FieldInput
                                    name={'monthly_rent_percentage'}
                                    value={ruleRent.monthly_rent_percentage}
                                    errors={errors}
                                    nameFieldError={
                                        'rule_monthly_sales.' + index + '.monthly_rent_percentage'
                                    }
                                    disabled={disabled}
                                    onBlur={changeBlurred}
                                    onChange={(event) =>
                                        handleRuleMonthlySalesChange(event, index, false)
                                    }
                                />
                            </td>
                            <td>
                                <FieldSelect
                                    name={'type'}
                                    value={ruleRent.type}
                                    errors={errors}
                                    nameFieldError={'rule_monthly_sales.' + index + '.type'}
                                    disabled={disabled}
                                    options={typeMonthlySales.map(
                                        (type) =>
                                            ({
                                                value: type.value,
                                                label: type.label
                                            } as FieldSelectOption)
                                    )}
                                    onChange={(event) => handleRuleMonthlySalesChange(event, index)}
                                />
                            </td>
                            {/*<td>*/}
                            {/*    <FieldSelect*/}
                            {/*        name={'currency'}*/}
                            {/*        value={ruleRent.currency}*/}
                            {/*        errors={errors}*/}
                            {/*        nameFieldError={'rule_monthly_sales.' + index + '.currency'}*/}
                            {/*        disabled={disabled}*/}
                            {/*        options={currencies.map(*/}
                            {/*            (currency) =>*/}
                            {/*                ({*/}
                            {/*                    value: currency.value,*/}
                            {/*                    label: currency.label*/}
                            {/*                } as FieldSelectOption)*/}
                            {/*        )}*/}
                            {/*        onChange={(event) => handleRuleMonthlySalesChange(event, index)}*/}
                            {/*    />*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    <FieldInput*/}
                            {/*        name={'amount'}*/}
                            {/*        value={ruleRent.amount}*/}
                            {/*        errors={errors}*/}
                            {/*        nameFieldError={'rule_monthly_sales.' + index + '.amount'}*/}
                            {/*        disabled={disabled}*/}
                            {/*        onBlur={changeBlurred}*/}
                            {/*        onChange={(event) =>*/}
                            {/*            handleRuleMonthlySalesChange(event, index, false)*/}
                            {/*        }*/}
                            {/*    />*/}
                            {/*</td>*/}
                            <td>
                                <FieldDate
                                    name={'expiration_date'}
                                    value={ruleRent.expiration_date}
                                    errors={errors}
                                    nameFieldError={
                                        'rule_monthly_sales.' + index + '.expiration_date'
                                    }
                                    disabled={disabled}
                                    onChange={(event) => handleRuleMonthlySalesChange(event, index)}
                                />
                            </td>
                            <td>
                                <ButtonTableDestroy
                                    onClick={() => handleDeleteRuleMonthlySales(index)}
                                    locked={disabled}
                                    loading={disabled}
                                />
                            </td>
                            {/* Renderiza otras celdas con campos de RuleMonthlySales aquí */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default RuleMonthlySaleDataForm;
