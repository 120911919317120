import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import Loading from '../../../template/components/ui/Loading';
import { ContractUpdateForm, initialContractUpdateForm } from '../../../app/models/Contract';
import { CostCenter } from '../../../app/models/CostCenter';
import { Currency } from '../../../app/models/Currency';
import { Lessor } from '../../../app/models/Lessor';
import useContractsService from '../../../hooks/services/useContractsService';
import DefaultCard from '../../../components/default/DefaultCard';
import ContractDataForm from '../components/ContractDataForm';
import RuleRentDataForm from '../components/RuleRentDataForm';
import RuleMonthlySaleDataForm from '../components/RuleMonthlySaleDataForm';
import WarrantyTypeDataForm from '../components/WarrantyTypeDataForm';
import OtherExpenseDataForm from '../components/OtherExpenseDataForm';
import ContractServiceDataForm from '../components/ContractServiceDataForm';
import ExitClauseDataForm from '../components/ExitClauseDataForm';
import ContractDocumentsDataForm from '../components/ContractDocumentDataForm';

type Params = {
    app_log_id: string;
};

type CustomChangeEvent = {
    target: {
        name: string;
        value: any;
    };
};

const ContractHistoryPage = () => {
    const params = useParams<Params>();

    const breadcrumbs = [
        {
            name: 'Contratos',
            url: '/contracts',
            isActive: false
        },
        {
            name: 'Historial',
            url: `/contracts/${params.app_log_id}/edit`,
            isActive: true
        }
    ];
    const { fetchingEditContract, getContractHistory } = useContractsService();

    const [errors, setErrors] = useState<object>({});

    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [lessors, setLessors] = useState<Lessor[]>([]);
    const [services, setServices] = useState<any[]>([]);
    const [typeMonthlySales, setTypeMonthlySales] = useState<any[]>([]);

    const [banks, setBanks] = useState<any[]>([]);
    const [dataForm, setDataForm] = useState<ContractUpdateForm>(initialContractUpdateForm);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        getContractHistory(parseInt(params.app_log_id), {
            onSuccess: (response) => {
                setCurrencies(response.data.currencies);
                setLessors(response.data.lessors);
                setServices(response.data.services);
                setDataForm(response.data.contract);
                setCostCenters(response.data.cost_centers);
                setBanks(response.data.banks);
                setTypeMonthlySales(response.data.type_monthly_sales);
            }
        }).then();
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | CustomChangeEvent
    ) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        });
    };

    const handleArrayChange = (nameAttribute: string, updatedArray: any[]) => {
        setDataForm({
            ...dataForm,
            [nameAttribute]: updatedArray
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Contratos" breadcrumbs={breadcrumbs} />

            {fetchingEditContract ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="DATOS DEL CONTRATO">
                                <ContractDataForm
                                    costCenters={costCenters}
                                    lessors={lessors}
                                    currencies={currencies}
                                    dataForm={dataForm}
                                    handleChange={handleChange}
                                    disabled={true}
                                    errors={errors}
                                />
                            </DefaultCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="ACTUALIZACIÓN DE ARRIENDO POR PERIODO ">
                                <RuleRentDataForm
                                    onUpdateRuleRents={(updatedRuleRents) =>
                                        handleArrayChange('rule_rents', updatedRuleRents)
                                    }
                                    currencies={currencies}
                                    ruleRents={dataForm.rule_rents ?? []}
                                    disabled={true}
                                    errors={errors}
                                    reformatNumber={1}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="ACTUALIZACIÓN DE VPM ">
                                <RuleMonthlySaleDataForm
                                    onUpdateRuleMonthlySales={(onUpdateRuleMonthlySales) =>
                                        handleArrayChange(
                                            'rule_monthly_sales',
                                            onUpdateRuleMonthlySales
                                        )
                                    }
                                    typeMonthlySales={typeMonthlySales ?? []}
                                    currencies={currencies}
                                    ruleMonthlySales={dataForm.rule_monthly_sales ?? []}
                                    disabled={true}
                                    errors={errors}
                                    reformatNumber={1}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="TIPOS DE GARANTÍA">
                                <WarrantyTypeDataForm
                                    onUpdateWarrantyTypes={(updateWarrantyTypes) =>
                                        handleArrayChange('warranty_types', updateWarrantyTypes)
                                    }
                                    currencies={currencies}
                                    banks={banks}
                                    warrantyTypes={dataForm.warranty_types ?? []}
                                    disabled={true}
                                    errors={errors}
                                    reformatNumber={1}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="OTROS GASTOS">
                                <OtherExpenseDataForm
                                    onUpdateOtherExpenses={(updateOtherExpenses) =>
                                        handleArrayChange('other_expenses', updateOtherExpenses)
                                    }
                                    currencies={currencies}
                                    otherExpenses={dataForm.other_expenses ?? []}
                                    disabled={true}
                                    errors={errors}
                                    reformatNumber={1}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="GASTOS SERVICIOS">
                                <ContractServiceDataForm
                                    onUpdateContractServices={(updateContractServices) =>
                                        handleArrayChange(
                                            'contract_services',
                                            updateContractServices
                                        )
                                    }
                                    contractServices={dataForm.contract_services ?? []}
                                    disabled={true}
                                    errors={errors}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="CLAUSULAS DE SALIDA">
                                <ExitClauseDataForm
                                    onUpdateExitClauses={(updateExitClauses) =>
                                        handleArrayChange('exit_clauses', updateExitClauses)
                                    }
                                    currencies={currencies}
                                    exitClauses={dataForm.exit_clauses ?? []}
                                    disabled={true}
                                    errors={errors}
                                    reformatNumber={1}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="DOCUMENTOS">
                                <ContractDocumentsDataForm
                                    contractDocuments={dataForm.document_contracts ?? []}
                                    disabled={true}
                                    contractId={-1}
                                    errors={errors}
                                    setContractDocuments={(updateDocuments) =>
                                        handleArrayChange('document_contracts', updateDocuments)
                                    }
                                />
                            </DefaultCard>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ContractHistoryPage;
