import React, { useEffect, useState } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import useProvidersService from '../../../hooks/services/useProvidersService';
import { initialProviderCreateForm, ProviderCreateForm } from '../../../app/models/Provider';
import FieldInput from '../../../components/fields/FieldInput';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import Loading from '../../../template/components/ui/Loading';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import { Service } from '../../../app/models/Service';

type Props = {
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
    disabled: boolean;
};

const ModalCreateProvider = ({ show, onClose, disabled, onSuccess, onError }: Props) => {
    const { fetchingStoreProvider, storeProvider, fetchingCreateProvider, createProvider } =
        useProvidersService();
    const [services, setServices] = useState<Service[]>([]);

    const [dataForm, setDataForm] = useState<ProviderCreateForm>(initialProviderCreateForm);
    const [errors, setErrors] = useState<object>({});

    const changeDataForm = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        setDataForm({
            ...dataForm,
            [e.target.id]: e.target.value
        });
    };

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createProvider({
            onSuccess: (response) => {
                setServices(response.data.services);
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    const store = () => {
        storeProvider(dataForm, {
            onSuccess: (response) => {
                onSuccess();
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    return (
        <DefaultModal
            show={show}
            handleClose={onClose}
            title="Crear proveedor"
            centered={true}
            actionButton={<ButtonSaveForm onClick={store} loading={fetchingStoreProvider} />}
        >
            {fetchingCreateProvider ? (
                <Loading height={200} />
            ) : (
                <>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldInput
                                name={'name'}
                                label={'Nombre'}
                                value={dataForm.name}
                                onChange={changeDataForm}
                                errors={errors}
                                disabled={fetchingStoreProvider}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldSelect
                                name={'service_id'}
                                value={dataForm.service_id.toString()}
                                errors={errors}
                                label="Servicios"
                                disabled={disabled}
                                options={services.map(
                                    (service) =>
                                        ({
                                            value: service.id,
                                            label: service.name
                                        } as FieldSelectOption)
                                )}
                                onChange={changeDataForm}
                            />
                        </div>
                    </div>
                </>
            )}
        </DefaultModal>
    );
};

export default ModalCreateProvider;
