import React, { useContext } from 'react';
import { TemplateContext } from '../../contexts/template';

const ToggleSidebarButton = () => {
    const { toggleFullScreen } = useContext(TemplateContext);

    return (
        <button
            type="button"
            onClick={toggleFullScreen}
            className="btn header-item noti-icon "
            data-toggle="fullscreen"
        >
            <i className="bx bx-fullscreen" />
        </button>
    );
};

export default ToggleSidebarButton;
