import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import defaultAvatar from '../../assets/images/defaults/avatar.png';
import { AuthContext } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';

const UserMenu = () => {
    const history = useHistory();

    const { authUser, flushAuthUser } = useContext(AuthContext);

    const logout = () => {
        flushAuthUser();
        window.location.href = '/login';
    };

    if (!authUser) {
        return null;
    }

    return (
        <Dropdown className="d-inline-block menu-auth-top">
            <Dropdown.Toggle
                className="btn header-item "
                id="page-header-authUser-dropdown"
                variant="btn header-item"
            >
                <img
                    className="rounded-circle header-profile-user"
                    src={authUser.avatar ?? defaultAvatar}
                    alt={authUser.name}
                />
                <span className="d-none d-xl-inline-block ms-2 me-1">{authUser.name}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-end">
                {/*<Dropdown.Item tag="a" href="/">*/}
                {/*    <i className="bx bxs-dashboard font-size-16 align-middle me-1" />*/}
                {/*    Dashboard*/}
                {/*</Dropdown.Item>*/}
                {/*<div className="dropdown-divider" />*/}
                <div onClick={logout} className="dropdown-item pointer">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    Salir
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserMenu;
