import { useState } from 'react';
import { SystemConfiguration } from '../../app/models/SystemConfiguration';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import { SystemConfigurationFetchRepository } from '../../app/services/SystemConfigurationFetchRepository';

const useSystemConfigurationsService = () => {
    const repository = new SystemConfigurationFetchRepository();

    const [fetchingGetSystemConfigurations, setFetchingGetSystemConfigurations] = useState(false);
    const [fetchingEditSystemConfiguration, setFetchingEditSystemConfiguration] = useState(false);
    const [fetchingUpdateSystemConfiguration, setFetchingUpdateSystemConfiguration] = useState(false);
    const getSystemConfigurations = async (events: ServiceEvents = {}) => {
        setFetchingGetSystemConfigurations(true);
        try {
            return repository.all(events).finally(() => {
                setFetchingGetSystemConfigurations(false);
            });
        } catch (error) {
            setFetchingGetSystemConfigurations(false);
        }
    };



    const editSystemConfiguration = async (id: number, events: ServiceEvents = {}) => {
        setFetchingEditSystemConfiguration(true);
        try {
            return repository.edit(id, events).finally(() => {
                setFetchingEditSystemConfiguration(false);
            });
        } catch (error) {
            setFetchingEditSystemConfiguration(false);
        }
    };

    const updateSystemConfiguration = async (id: number, service: SystemConfiguration, events: ServiceEvents = {}) => {
        setFetchingUpdateSystemConfiguration(true);
        try {
            return repository.update(id, service, events).finally(() => {
                setFetchingUpdateSystemConfiguration(false);
            });
        } catch (error) {
            setFetchingUpdateSystemConfiguration(false);
        }
    };


    return {
        fetchingGetSystemConfigurations,
        fetchingEditSystemConfiguration,
        fetchingUpdateSystemConfiguration,
        getSystemConfigurations,
        editSystemConfiguration,
        updateSystemConfiguration,
    };
};

export default useSystemConfigurationsService;
