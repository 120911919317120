import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import reducerAuth from './reducer';
import {
    AuthContextState,
    AuthUser,
    InitAuthUser,
    SET_AUTH,
    SET_AUTH_TOKEN,
    SET_PERMISSIONS,
    SET_ROLES
} from './types';
import { LOCAL_STORAGE } from '../../config';

const voidFn = () => {
    // do nothing
};

const initialState: AuthContextState = {
    authUser: undefined,
    authToken: undefined,
    roles: [],
    permissions: [],
    setAuthUser: voidFn,
    setAuthToken: voidFn,
    setRoles: voidFn,
    setPermissions: voidFn,
    hasRole: () => false,
    hasPermission: () => false,
    hasAnyRole: () => false,
    hasAnyPermission: () => false,
    initAuthUser: voidFn,
    flushAuthUser: voidFn
};

export const AuthContext = createContext<AuthContextState>(initialState);

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducerAuth, initialState);

    useEffect(() => {
        const authUser = localStorage.getItem(LOCAL_STORAGE.AUTH_USER);
        const authToken = localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN);
        const roles = localStorage.getItem(LOCAL_STORAGE.AUTH_ROLES);
        const permissions = localStorage.getItem(LOCAL_STORAGE.AUTH_PERMISSIONS);
        if (authUser && authToken) {
            initAuthUser({
                authUser: JSON.parse(authUser) as AuthUser,
                authToken: authToken,
                roles: JSON.parse(roles ?? '[]') as string[],
                permissions: JSON.parse(permissions ?? '[]') as string[]
            });
        }
    }, []);

    const setAuthUser = (authUser?: AuthUser) => {
        localStorage.setItem(LOCAL_STORAGE.AUTH_USER, JSON.stringify(authUser));
        dispatch({ type: SET_AUTH, payload: authUser });
    };

    const setAuthToken = (authToken?: string) => {
        localStorage.setItem(LOCAL_STORAGE.AUTH_TOKEN, authToken ?? '');
        dispatch({ type: SET_AUTH_TOKEN, payload: authToken });
    };

    const setRoles = (roles?: string[]) => {
        localStorage.setItem(LOCAL_STORAGE.AUTH_ROLES, JSON.stringify(roles ?? []));
        dispatch({ type: SET_ROLES, payload: roles ?? [] });
    };

    const setPermissions = (permissions?: string[]) => {
        localStorage.setItem(LOCAL_STORAGE.AUTH_PERMISSIONS, JSON.stringify(permissions ?? []));
        dispatch({ type: SET_PERMISSIONS, payload: permissions ?? [] });
    };

    const hasRole = (role: string) => state.roles?.includes(role) || false;

    const hasPermission = (permission: string) => state.permissions?.includes(permission) || false;

    const hasAnyRole = (roles: string[]) => roles.some((role) => hasRole(role));

    const hasAnyPermission = (permissions: string[]) =>
        permissions.some((permission) => hasPermission(permission));

    const initAuthUser = (initAuthUser: InitAuthUser) => {
        setAuthUser(initAuthUser.authUser);
        setAuthToken(initAuthUser.authToken);
        setRoles(initAuthUser.roles);
        setPermissions(initAuthUser.permissions);
    };

    const flushAuthUser = () => {
        setAuthUser(undefined);
        setAuthToken(undefined);
        setRoles([]);
        setPermissions([]);
    };

    return (
        <AuthContext.Provider
            value={{
                authUser: state.authUser,
                authToken: state.authToken,
                roles: state.roles ?? [],
                permissions: state.permissions ?? [],
                setAuthUser,
                setAuthToken,
                setRoles,
                setPermissions,
                hasRole,
                hasPermission,
                hasAnyRole,
                hasAnyPermission,
                initAuthUser,
                flushAuthUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
