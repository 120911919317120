import { AuthCredentials, AuthRegisterCredentials, ResetPasswordCredentials } from '../models/Auth';
import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { AuthEndpoint } from './APIConnection';

export class AuthFetchRepository extends GenericFetch {
    login(credentials: AuthCredentials, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: AuthEndpoint.login(),
            requestType: 'json',
            body: credentials
        });
    }

    register(
        credentials: AuthRegisterCredentials,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: AuthEndpoint.register(),
            requestType: 'json',
            body: credentials
        });
    }

    confirmEmail(token: string, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: AuthEndpoint.confirmEmail(token),
            requestType: 'json'
        });
    }

    isAuthenticated(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: AuthEndpoint.isAuthenticated(),
            requestType: 'json'
        });
    }

    forgotPassword(email: string, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: AuthEndpoint.forgotPassword(),
            requestType: 'json',
            body: { email }
        });
    }

    resetPassword(
        credentials: ResetPasswordCredentials,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: AuthEndpoint.resetPassword(),
            requestType: 'json',
            body: credentials
        });
    }

    validateRecoveryPin(
        credentials: { email: string; recovery_pin: string },
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: AuthEndpoint.validateRecoveryPin(),
            requestType: 'json',
            body: credentials
        });
    }
}
