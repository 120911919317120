import React, { useState, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { NotificationContext } from '../../contexts/notification';

const NotificationMenu = () => {
    const { notificationsNotViewed, markNotificationAsRead, markAllAsReadNotifications } = useContext(NotificationContext);
    const [isShowNotification, setIsShowNotification] = useState(false);


    const toggleNotification = () => {
        setIsShowNotification(!isShowNotification);
    };

    const markAsReadNotification = (id: number) => {
        markNotificationAsRead(id);
    };

    return (
        <>
            <button
                type="button"
                onClick={toggleNotification}
                className="btn header-item noti-icon "
                data-toggle="dropdown"
            >
                <i className={`bx bx-bell ${notificationsNotViewed.length > 0 ? 'bx-tada' : ''}`}>
                    {
                        notificationsNotViewed.length > 0 && (
                            <span className="badge bg-danger rounded-pill" style={{ fontSize: '0.5em', padding: '4px', position: 'absolute', top: '0px', right: '0px' }}>
                                {notificationsNotViewed.length}
                            </span>
                        )
                    }
                </i>

            </button>

            <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-end pt-0 px-3 ${isShowNotification ? 'show' : ''}`}>
                <div className="py-3">
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 className="m-0"> Notificaciones </h6>
                        </div>
                        <div className="col-auto">
                            <a className="font-12" onClick={markAllAsReadNotifications}>
                                Marcar todas como leídas
                            </a>
                        </div>
                    </div>
                </div>
                <div data-simplebar className='notification-box'>
                    {
                        notificationsNotViewed.length > 0 ? (
                            notificationsNotViewed.map((item, index) => (
                                <React.Fragment key={item.id}>
                                    <div className="notification-item">
                                        <Link
                                            to={`/notifications/${item.id}`}
                                            className="media-body text-reset"
                                            onClick={() => markAsReadNotification(item.id)}
                                        >
                                            <h6 className="mt-0 mb-1"> {item.title} </h6>
                                            <div className="font-size-12 text-muted">
                                                <p className="mb-1"> {item.message} </p>
                                                <p className="mb-0">
                                                    <i className="mdi mdi-clock-outline" /> {moment(item.created_at).format('DD-MM-YYYY')}
                                                </p>
                                            </div>
                                        </Link>
                                        <button
                                            className="read-button btn btn-sm btn-link p-0"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                markAsReadNotification(item.id);
                                            }}
                                            aria-label="Marcar como leída"
                                        >
                                            <i className="mdi mdi-eye-check-outline font-16" />
                                        </button>
                                    </div>
                                    {index !== notificationsNotViewed.length - 1 && <div className="dropdown-divider" />}
                                </React.Fragment>

                            ))
                        ) : (
                            <div className="text-center text-muted py-3">
                                No tienes notificaciones
                            </div>
                        )
                    }
                </div>

                <div className="p-2 border-top text-center mt-2">
                    <Link to="/notifications" className="btn btn-sm btn-link font-size-14 btn-block" onClick={toggleNotification}>
                        <i className="mdi mdi-arrow-right-circle mr-1" /> Ver todas las notificaciones
                    </Link>
                </div>
            </div>

        </>
    );
};

export default NotificationMenu;
