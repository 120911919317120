export const SET_AUTH = 'SET_AUTH';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_ROLES = 'SET_ROLES';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export type ReducerAction = {
    type: string;
    payload?: any;
};

export type AuthContextState = {
    authUser: AuthUser | undefined;
    setAuthUser: (authUser?: AuthUser) => void;
    authToken?: string;
    setAuthToken: (token?: string) => void;

    roles: string[];
    setRoles: (roles?: string[]) => void;
    hasRole: (role: string) => boolean;
    hasAnyRole: (role: string[]) => boolean;

    permissions: string[];
    setPermissions: (permissions?: string[]) => void;
    hasPermission: (permission: string) => boolean;
    hasAnyPermission: (permission: string[]) => boolean;
    initAuthUser: (initAuthUser: InitAuthUser) => void;
    flushAuthUser: () => void;
};

export type InitAuthUser = {
    authUser: AuthUser;
    authToken: string;
    roles?: string[];
    permissions?: string[];
};

export type AuthUser = {
    id?: string;
    name: string;
    email?: string;
    token: string;
    last_login_at?: string;
    avatar?: string;
    data?: any;
};
