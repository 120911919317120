import React from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { Lessor } from '../../../app/models/Lessor';
import FieldDate from '../../../components/fields/FieldDate';
import moment from 'moment';
import FieldInput from '../../../components/fields/FieldInput';
import { Bank } from '../../../app/models/Bank';

type Props = {
    dataForm: Lessor;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => void;

    validateRut(value: string): boolean;
    disabled: boolean;
    account_types: any[];
    errors: any;
    banks: Bank[];
    changePassword?: boolean;
};

const LessorDataForm = ({
    dataForm,
    handleChange,
    disabled,
    errors,
    banks,
    account_types,
    validateRut,
    changePassword = false
}: Props) => {
    return (
        <>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label="Rut"
                        name="id_number"
                        type="text"
                        value={dataForm.id_number}
                        onChange={handleChange}
                        errors={errors}
                        // onFocusOut={() => {
                        //     if (!validateRut(dataForm.rut ?? '0')) {
                        //         setDataForm({
                        //             ...dataForm,
                        //             rut: ''
                        //         });
                        //
                        //         setErrors({
                        //             ...errors,
                        //             rut: ['El rut no es válido']
                        //         });
                        //     }
                        // }}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label={'Razón social'}
                        name={'business_name'}
                        value={dataForm.business_name}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label={'Dirección'}
                        name={'address'}
                        value={dataForm.address}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label={'Email'}
                        name={'email'}
                        type={'email'}
                        value={dataForm.email}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    <FieldSelect
                        name={'phone_code'}
                        label={'Código'}
                        options={[{ value: '+56', label: '+56' }]}
                        value={dataForm.phone_code}
                        onChange={handleChange}
                        errors={errors}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-9">
                    <FieldInput
                        label={'Teléfono'}
                        name={'phone'}
                        value={dataForm.phone}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldSelect
                        label={'Banco'}
                        name={'bank_id'}
                        options={banks.map((bank) => ({
                            value: bank.id,
                            label: bank.name
                        }))}
                        value={dataForm.bank_id}
                        onChange={handleChange}
                        errors={errors}
                        disabled={disabled}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldSelect
                        label={'Tipos de Cuentas'}
                        name={'bank_account_type'}
                        options={account_types.map(
                            (account_type) =>
                                ({
                                    value: account_type.id,
                                    label: account_type.value
                                } as FieldSelectOption)
                        )}
                        value={dataForm.bank_account_type.toString()}
                        onChange={handleChange}
                        errors={errors}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldInput
                        label={'Número de Cuenta Bancaria'}
                        name={'bank_account_number'}
                        value={dataForm.bank_account_number}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
        </>
    );
};

export default LessorDataForm;
