import { useState } from 'react';
import { AuthFetchRepository } from '../../app/services/AuthFetchRepository';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import {
    AuthCredentials,
    AuthRegisterCredentials,
    ResetPasswordCredentials
} from '../../app/models/Auth';

const useAuthService = () => {
    const repository = new AuthFetchRepository();

    const [fetchingLoginAuth, setFetchingLoginAuth] = useState(false);
    const [fetchingRegisterAuth, setFetchingRegisterAuth] = useState(false);
    const [fetchingConfirmEmail, setFetchingConfirmEmail] = useState(false);
    const [fetchingIsAuthenticated, setFetchingIsAuthenticated] = useState(false);
    const [fetchingForgotPassword, setFetchingForgotPassword] = useState(false);
    const [fetchingResetPassword, setFetchingResetPassword] = useState(false);

    const loginAuth = async (credentials: AuthCredentials, events: ServiceEvents = {}) => {
        setFetchingLoginAuth(true);
        try {
            return repository.login(credentials, events).finally(() => {
                setFetchingLoginAuth(false);
            });
        } catch (error) {
            setFetchingLoginAuth(false);
        }
    };

    const registerAuth = async (
        credentials: AuthRegisterCredentials,
        events: ServiceEvents = {}
    ) => {
        setFetchingRegisterAuth(true);
        try {
            return repository.register(credentials, events).finally(() => {
                setFetchingRegisterAuth(false);
            });
        } catch (error) {
            setFetchingRegisterAuth(false);
        }
    };

    const confirmEmail = async (token: string, events: ServiceEvents = {}) => {
        setFetchingConfirmEmail(true);
        try {
            return repository.confirmEmail(token, events).finally(() => {
                setFetchingConfirmEmail(false);
            });
        } catch (error) {
            setFetchingConfirmEmail(false);
        }
    };

    const isAuthenticated = async (events: ServiceEvents = {}) => {
        setFetchingIsAuthenticated(true);
        try {
            return repository.isAuthenticated(events).finally(() => {
                setFetchingIsAuthenticated(false);
            });
        } catch (error) {
            setFetchingIsAuthenticated(false);
        }
    };

    const forgotPassword = async (email: string, events: ServiceEvents = {}) => {
        setFetchingForgotPassword(true);
        try {
            return repository.forgotPassword(email, events).finally(() => {
                setFetchingForgotPassword(false);
            });
        } catch (error) {
            setFetchingForgotPassword(false);
        }
    };

    const resetPassword = async (
        credentials: ResetPasswordCredentials,
        events: ServiceEvents = {}
    ) => {
        setFetchingResetPassword(true);
        try {
            return repository.resetPassword(credentials, events).finally(() => {
                setFetchingResetPassword(false);
            });
        } catch (error) {
            setFetchingResetPassword(false);
        }
    };

    return {
        fetchingLoginAuth,
        fetchingRegisterAuth,
        fetchingConfirmEmail,
        fetchingIsAuthenticated,
        fetchingForgotPassword,
        fetchingResetPassword,
        loginAuth,
        registerAuth,
        confirmEmail,
        isAuthenticated,
        forgotPassword,
        resetPassword
    };
};

export default useAuthService;
