import React from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import NotificationsTabs from '../components/NotificationsTabs';


const NotificationsPage = () => {
    const breadcrumbs = [
        {
            name: 'Notificaciones',
            url: '/notifications',
            isActive: true
        }
    ];

    return (
        <>
            <Breadcrumbs pageSection="Bandeja de Notificaciones" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="card-body">
                    <NotificationsTabs />
                </div>
            </DefaultCard>
        </> 
    );
};

export default NotificationsPage;
