import { useState } from 'react';
import { User } from '../../app/models/User';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import { UserFetchRepository } from '../../app/services/UserFetchRepository';

const useUsersService = () => {
    const repository = new UserFetchRepository();

    const [fetchingGetUsers, setFetchingGetUsers] = useState(false);
    const [fetchingStoreUser, setFetchingStoreUser] = useState(false);
    const [fetchingEditUser, setFetchingEditUser] = useState(false);
    const [fetchingUpdateUser, setFetchingUpdateUser] = useState(false);
    const [fetchingDeleteUser, setFetchingDeleteUser] = useState(false);

    const getUsers = async (events: ServiceEvents = {}) => {
        setFetchingGetUsers(true);
        try {
            return repository.all(events).finally(() => {
                setFetchingGetUsers(false);
            });
        } catch (error) {
            setFetchingGetUsers(false);
        }
    };

    const storeUser = async (user: User, events: ServiceEvents = {}) => {
        setFetchingStoreUser(true);
        try {
            return repository.store(user, events).finally(() => {
                setFetchingStoreUser(false);
            });
        } catch (error) {
            setFetchingStoreUser(false);
        }
    };

    const editUser = async (id: number, events: ServiceEvents = {}) => {
        setFetchingEditUser(true);
        try {
            return repository.edit(id, events).finally(() => {
                setFetchingEditUser(false);
            });
        } catch (error) {
            setFetchingEditUser(false);
        }
    };

    const updateUser = async (id: number, service: User, events: ServiceEvents = {}) => {
        setFetchingUpdateUser(true);
        try {
            return repository.update(id, service, events).finally(() => {
                setFetchingUpdateUser(false);
            });
        } catch (error) {
            setFetchingUpdateUser(false);
        }
    };

    const deleteUser = async (id: number, events: ServiceEvents = {}) => {
        setFetchingDeleteUser(true);
        try {
            return repository.delete(id, events).finally(() => {
                setFetchingDeleteUser(false);
            });
        } catch (error) {
            setFetchingDeleteUser(false);
        }
    };

    return {
        fetchingGetUsers,
        fetchingStoreUser,
        fetchingEditUser,
        fetchingUpdateUser,
        fetchingDeleteUser,
        getUsers,
        storeUser,
        editUser,
        updateUser,
        deleteUser
    };
};

export default useUsersService;
