import React, { useEffect, useState } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import useProvidersService from '../../../hooks/services/useProvidersService';
import { initialProviderUpdateForm, ProviderUpdateForm } from '../../../app/models/Provider';
import FieldInput from '../../../components/fields/FieldInput';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import Loading from '../../../template/components/ui/Loading';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import { Service } from '../../../app/models/Service';

type Props = {
    id: number;
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => void;
    disabled: boolean;
};

const ModalEditProvider = ({ id, show, disabled, onClose, onSuccess, onError }: Props) => {
    const { fetchingEditProvider, editProvider, fetchingUpdateProvider, updateProvider } =
        useProvidersService();
    const [dataForm, setDataForm] = useState<ProviderUpdateForm>(initialProviderUpdateForm);

    const [services, setServices] = useState<Service[]>([]);
    const [errors, setErrors] = useState<object>({});

    const changeDataForm = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        setDataForm({
            ...dataForm,
            [e.target.id]: e.target.value
        });
    };

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editProvider(id, {
            onSuccess: (response) => {
                setDataForm(response.data.provider);
                setServices(response.data.services);
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    const update = () => {
        updateProvider(id, dataForm, {
            onSuccess: (response) => {
                onSuccess();
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    return (
        <DefaultModal
            show={show}
            handleClose={onClose}
            title="Editar proveedor"
            centered={true}
            actionButton={<ButtonSaveForm onClick={update} loading={fetchingUpdateProvider} />}
        >
            {fetchingEditProvider ? (
                <Loading height={200} />
            ) : (
                <>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldInput
                                name={'name'}
                                label={'Nombre'}
                                value={dataForm.name}
                                onChange={changeDataForm}
                                errors={errors}
                                disabled={fetchingUpdateProvider}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldSelect
                                name={'service_id'}
                                value={dataForm.service_id.toString()}
                                errors={errors}
                                label="Servicios"
                                disabled={disabled}
                                options={services.map(
                                    (service) =>
                                        ({
                                            value: service.id,
                                            label: service.name
                                        } as FieldSelectOption)
                                )}
                                onChange={changeDataForm}
                            />
                        </div>
                    </div>
                </>
            )}
        </DefaultModal>
    );
};

export default ModalEditProvider;
