import { Payment } from '../../app/models/Payment';

export const data_payments: Payment[] = [
    {
        id: 1,
        date: '2020-01-01',
        name: 'Payment 1',
        amount: 100,
        currency: 'CLP',
        file: 'file1',
        paid: true,
        contract_id: 1,
        local_id: 1,
        service_id: 1
    },
    {
        id: 2,
        date: '2020-01-02',
        name: 'Payment 2',
        amount: 200,
        currency: 'CLP',
        file: 'file2',
        paid: true,
        contract_id: 1,
        local_id: 2,
        service_id: 2
    }
];
