type Props = {
    height?: string | number;
};

const Loading = ({ height = '100vh' }: Props) => {
    return (
        <div className="w-100 d-flex" style={{ height: height }}>
            <div className="m-auto text-center">
                <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
};

export default Loading;
