import Swal from 'sweetalert2';
import { defaultSwalStyleButtons } from '../template/config';

interface requestConfirmationProps {
    title: string;
    text: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

interface alertNotificationProps {
    title: string;
    text: string;
    icon: 'success' | 'error' | 'warning' | 'info' | 'question';
    confirmButtonText?: string;
}

interface alertNotificationTypeProps {
    title: string;
    text: string;
    confirmButtonText?: string;
}

const useSweetAlert = () => {
    const requestConfirmation = ({
        title = '¿Está seguro?',
        text = 'Confirme para continuar',
        confirmButtonText = 'Si,confirmar',
        cancelButtonText = 'No, cancelar',
        showCancelButton = true,
        onConfirm = () => {
            return;
        },
        onCancel = () => {
            return;
        }
    }: requestConfirmationProps) => {
        const swalWithBootstrapButtons = Swal.mixin(defaultSwalStyleButtons);

        swalWithBootstrapButtons
            .fire({
                title: title,
                text: text,
                icon: 'warning',
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText,
                showCancelButton: showCancelButton,
                reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    onConfirm();
                } else {
                    onCancel();
                }
            });
    };

    const alertNotification = ({
        title,
        text,
        confirmButtonText = 'Ok',
        icon = 'success'
    }: alertNotificationProps) => {
        const swalWithBootstrapButtons = Swal.mixin(defaultSwalStyleButtons);

        swalWithBootstrapButtons.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: confirmButtonText,
            // cancelButtonText: 'Cancelar',
            showCancelButton: false,
            reverseButtons: true
        });
    };

    const successAlert = ({
        title,
        text,
        confirmButtonText = 'Ok'
    }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'success'
        });
    };

    const errorAlert = ({ title, text, confirmButtonText = 'Ok' }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'error'
        });
    };

    const warningAlert = ({
        title,
        text,
        confirmButtonText = 'Ok'
    }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'warning'
        });
    };

    const infoAlert = ({ title, text, confirmButtonText = 'Ok' }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'info'
        });
    };

    const questionAlert = ({
        title,
        text,
        confirmButtonText = 'Ok'
    }: alertNotificationTypeProps) => {
        alertNotification({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            icon: 'question'
        });
    };
    return {
        requestConfirmation,
        successAlert,
        errorAlert,
        warningAlert,
        infoAlert,
        questionAlert
    };
};
export default useSweetAlert;
