import React, { useState, useEffect } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import useCostCentersService from '../../../hooks/services/useCostCentersService';
import { initialCostCenterCreateForm, CostCenterCreateForm } from '../../../app/models/CostCenter';
import FieldInput from '../../../components/fields/FieldInput';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import Loading from '../../../template/components/ui/Loading';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import { Commune } from '../../../app/models/Commune';

type Props = {
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
    disabled: boolean;
};

const ModalCreateCostCenter = ({ show, onClose, disabled, onSuccess, onError }: Props) => {
    const { fetchingStoreCostCenter, storeCostCenter } = useCostCentersService();
    const { fetchingCreateCostCenter, createCostCenter } = useCostCentersService();
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [societyNames, setSocietyNames] = useState<any[]>([]);
    const [types, setTypes] = useState<any[]>([]);

    const [dataForm, setDataForm] = useState<CostCenterCreateForm>(initialCostCenterCreateForm);
    const [errors, setErrors] = useState<object>({});

    const changeDataForm = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        setDataForm({
            ...dataForm,
            [e.target.id]: e.target.value
        });
    };

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createCostCenter({
            onSuccess: (response) => {
                setCommunes(response.data.communes);
                setSocietyNames(response.data.society_names);
                setTypes(response.data.types);
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    const store = () => {
        storeCostCenter(dataForm, {
            onSuccess: (response) => {
                onSuccess();
            },
            onError: (response) => {
                onError();
            },
            onFieldError: (response) => {
                setErrors(response.data.errors);
            }
        }).then();
    };

    return (
        <DefaultModal
            show={show}
            handleClose={onClose}
            title="Crear Razón Social"
            centered={true}
            actionButton={<ButtonSaveForm onClick={store} loading={fetchingStoreCostCenter} />}
        >
            {fetchingCreateCostCenter ? (
                <Loading height={200} />
            ) : (
                <>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldInput
                                name={'cost_code_id'}
                                label={'Código de la Razón Social'}
                                value={dataForm.cost_code_id}
                                onChange={changeDataForm}
                                errors={errors}
                                disabled={fetchingCreateCostCenter}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldInput
                                name={'name'}
                                label={'Nombre'}
                                value={dataForm.name}
                                onChange={changeDataForm}
                                errors={errors}
                                disabled={fetchingCreateCostCenter}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldSelect
                                name={'society_name'}
                                value={dataForm.society_name.toString()}
                                errors={errors}
                                label="Sociedades"
                                disabled={disabled}
                                options={societyNames.map(
                                    (society_name) =>
                                        ({
                                            value: society_name.value,
                                            label: society_name.label
                                        } as FieldSelectOption)
                                )}
                                onChange={changeDataForm}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldSelect
                                name={'commune_id'}
                                value={dataForm.commune_id.toString()}
                                errors={errors}
                                label="Comunas"
                                disabled={disabled}
                                options={communes.map(
                                    (commune) =>
                                        ({
                                            value: commune.id,
                                            label: commune.name
                                        } as FieldSelectOption)
                                )}
                                onChange={changeDataForm}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldInput
                                name={'address'}
                                label={'Dirección'}
                                value={dataForm.address}
                                onChange={changeDataForm}
                                errors={errors}
                                disabled={fetchingCreateCostCenter}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldSelect
                                name={'type'}
                                value={dataForm.type.toString()}
                                errors={errors}
                                label="Tipos"
                                disabled={disabled}
                                options={types.map(
                                    (type) =>
                                        ({
                                            value: type.id,
                                            label: type.value
                                        } as FieldSelectOption)
                                )}
                                onChange={changeDataForm}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <FieldInput
                                name={'remote_relation_id'}
                                label={'Id externo'}
                                value={dataForm.remote_relation_id}
                                onChange={changeDataForm}
                                errors={errors}
                                disabled={fetchingCreateCostCenter}
                            />
                        </div>
                    </div>
                </>
            )}
        </DefaultModal>
    );
};

export default ModalCreateCostCenter;
