import React, { useState, useEffect, useContext } from 'react';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { Notification } from '../../../app/models/Notification';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { NotificationContext } from '../../../template/contexts/notification';

const NotificationsTabs = () => {

    const { notificationsNotViewed, notificationsViewed, markNotificationAsRead, markAllAsReadNotifications } = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const renderNotifications = (notifications: Notification[]) => {
        if (isLoading) {
            return (
                <div className="text-center mt-5">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Cargando...</span>
                    </Spinner>
                </div>
            );
        } else if (!Array.isArray(notifications) || notifications.length === 0) {
            return (
                <div className="text-center mt-5">
                    <h5 className="text-muted">Por el momento no tienes notificaciones</h5>
                </div>
            );
        }

        return (
            <>
                {notifications.map((notification, index) => (
                    <React.Fragment key={notification.id}>
                        <div className="notification-item module mb-1">
                            <Link
                                to={`/notifications/${notification.id}`}
                                className="media-body text-reset"
                                onClick={() => { markNotificationAsRead(notification.id) }}
                            >
                                <h6 className="mt-0 mb-1"> {notification.title} </h6>
                                <div className="font-size-12 text-muted">
                                    <p className="mb-1"> {notification.message} </p>
                                    <p className="mb-0">
                                        <i className="mdi mdi-clock-outline" /> {moment(notification.created_at).format('DD-MM-YYYY')}
                                    </p>
                                </div>
                            </Link>
                            <button
                                className="read-button btn btn-sm btn-link p-0"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    markNotificationAsRead(notification.id);
                                }}
                                aria-label="Marcar como leída"
                            >
                                <i className="mdi mdi-eye-check-outline font-16" />
                            </button>
                        </div>
                        {index !== notifications.length - 1 && <div className="dropdown-divider" />}
                    </React.Fragment>
                ))}
            </>
        );
    };

    return (
        <>
            <div className="d-flex justify-content-end mb-2">
                <Button variant="outline-secondary" size="sm" onClick={markAllAsReadNotifications}>
                    Marcar todas como leídas
                </Button>
            </div>
            <Tabs defaultActiveKey="notViewed" id="uncontrolled-tab-example" style={{ fontWeight: 800 }} className='mb-2'>
                <Tab eventKey="notViewed" title="No Vistas" >
                    {renderNotifications(notificationsNotViewed)}
                </Tab>
                <Tab eventKey="viewed" title="Vistas">
                    {renderNotifications(notificationsViewed)}
                </Tab>
            </Tabs>
        </>

    );
};


export default NotificationsTabs;
