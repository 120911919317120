import React from 'react';
import { TEMPLATE_CONFIG } from '../config';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <a
                            href={TEMPLATE_CONFIG.APP.COPYRIGHT_URL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {TEMPLATE_CONFIG.APP.COPYRIGHT}
                        </a>
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            {TEMPLATE_CONFIG.APP.NAME}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
