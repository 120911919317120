import { useState } from 'react';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import { ProvidersFetchRepository } from '../../app/services/ProvidersFetchRepository';
import { ProviderCreateForm, ProviderUpdateForm } from '../../app/models/Provider';

const useProvidersService = () => {
    const repository = new ProvidersFetchRepository();

    const [fetchingGetProviders, setFetchingGetProviders] = useState(false);
    const [fetchingStoreProvider, setFetchingStoreProvider] = useState(false);
    const [fetchingEditProvider, setFetchingEditProvider] = useState(false);
    const [fetchingUpdateProvider, setFetchingUpdateProvider] = useState(false);
    const [fetchingDeleteProvider, setFetchingDeleteProvider] = useState(false);
    const [fetchingCreateProvider, setFetchingCreateProvider] = useState(false);

    const getProviders = async (events: ServiceEvents = {}) => {
        setFetchingGetProviders(true);
        try {
            return repository.all(events).finally(() => {
                setFetchingGetProviders(false);
            });
        } catch (error) {
            setFetchingGetProviders(false);
        }
    };

    const storeProvider = async (service: ProviderCreateForm, events: ServiceEvents = {}) => {
        setFetchingStoreProvider(true);
        try {
            return repository.store(service, events).finally(() => {
                setFetchingStoreProvider(false);
            });
        } catch (error) {
            setFetchingStoreProvider(false);
        }
    };

    const editProvider = async (id: number, events: ServiceEvents = {}) => {
        setFetchingEditProvider(true);
        try {
            return repository.edit(id, events).finally(() => {
                setFetchingEditProvider(false);
            });
        } catch (error) {
            setFetchingEditProvider(false);
        }
    };

    const updateProvider = async (
        id: number,
        service: ProviderUpdateForm,
        events: ServiceEvents = {}
    ) => {
        setFetchingUpdateProvider(true);
        try {
            return repository.update(id, service, events).finally(() => {
                setFetchingUpdateProvider(false);
            });
        } catch (error) {
            setFetchingUpdateProvider(false);
        }
    };

    const deleteProvider = async (id: number, events: ServiceEvents = {}) => {
        setFetchingDeleteProvider(true);
        try {
            return repository.delete(id, events).finally(() => {
                setFetchingDeleteProvider(false);
            });
        } catch (error) {
            setFetchingDeleteProvider(false);
        }
    };

    const createProvider = async (events: ServiceEvents = {}) => {
        setFetchingCreateProvider(true);
        try {
            return repository.create(events).finally(() => {
                setFetchingCreateProvider(false);
            });
        } catch (error) {
            setFetchingCreateProvider(false);
        }
    };

    return {
        fetchingGetProviders,
        fetchingStoreProvider,
        fetchingEditProvider,
        fetchingUpdateProvider,
        fetchingDeleteProvider,
        fetchingCreateProvider,
        getProviders,
        storeProvider,
        editProvider,
        updateProvider,
        deleteProvider,
        createProvider
    };
};

export default useProvidersService;
