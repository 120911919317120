import React, { useContext, useState } from 'react';
import FieldInput from '../../components/fields/FieldInput';
import Wrapper from './components/Wrapper';
import useAuthService from '../../hooks/services/useAuthService';
import { AuthCredentials } from '../../app/models/Auth';
import { Link, useHistory } from 'react-router-dom';
import ButtonSaveForm from '../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import { AuthContext } from '../../template/contexts/auth';
// import { Subscription } from '../../app/models/Subcription';

const LoginPage = () => {
    const history = useHistory();

    const { initAuthUser } = useContext(AuthContext);

    const { fetchingLoginAuth, loginAuth } = useAuthService();

    const [credentials, setCredentials] = useState<AuthCredentials>({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState<object>({});
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const login = () => {
        loginAuth(credentials, {
            onSuccess: (response) => {
                const _authUser = response.data.auth;
                initAuthUser({
                    authUser: {
                        name: _authUser.first_name,
                        token: response.data.token,
                        data: {}
                    },
                    authToken: response.data.token
                });
                history.push('/');
            },
            onError: (response) => {
                toast.error(response.message);
            },
            onFieldError: (response) => setErrors(response.data.errors)
        })
            .then()
            .catch(() => toast.error('Error al iniciar sesión'));
    };

    return (
        <>
            <Wrapper title="Iniciar sesión" description="Inicia sesión para continuar">
                <FieldInput
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={credentials.email}
                    onChange={onChange}
                    errors={errors}
                    required={true}
                    disabled={fetchingLoginAuth}
                />

                <FieldInput
                    label="Contraseña"
                    name="password"
                    type="password"
                    placeholder="********"
                    value={credentials.password}
                    onChange={onChange}
                    errors={errors}
                    required={true}
                    disabled={fetchingLoginAuth}
                />
                <br />
                <ButtonSaveForm
                    title="Iniciar sesión"
                    onClick={login}
                    locked={fetchingLoginAuth}
                    className="w-100"
                    icon="fas fa-sign-in-alt"
                />
                {/*<div className="text-center mt-2">*/}
                {/*    <Link to="/register" className="btn btn-link btn-block ">*/}
                {/*        ¿No tienes una cuenta? Regístrate*/}
                {/*    </Link>*/}
                {/*</div>*/}
                <div className="text-center mt-2">
                    <Link to="/forgot-password" className="btn btn-link btn-block ">
                        Recuperar contraseña
                    </Link>
                </div>
            </Wrapper>
        </>
    );
};

export default LoginPage;
