import React from 'react';
import FieldNumber from '../../../components/fields/FieldNumber';
import { SystemConfiguration } from '../../../app/models/SystemConfiguration';

type Props = {
    dataForm: SystemConfiguration;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement>
    ) => void;

    errors: any;
};

const SystemConfigurationDataForm = ({
    dataForm,
    handleChange,
    errors,
}: Props) => {
    return (
        <>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldNumber
                        label="Días de alerta de notificación de pago"
                        name="days_payment_date_alert"
                        value={dataForm.days_payment_date_alert}
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    <FieldNumber
                        label={'Días de alerta de término de contrato'}
                        name={'days_contract_end_alert'}
                        value={dataForm.days_contract_end_alert}
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
            </div>
        </>
    );
};

export default SystemConfigurationDataForm;
