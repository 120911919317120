// src/contexts/notifications/reducer.ts
import { NotificationContextState, ReducerAction, SET_NOTIFICATIONS, MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_ALL_AS_READ } from './types';

const reducer = (state: NotificationContextState, action: ReducerAction) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notificationsNotViewed: action.payload.notViewed,
                notificationsViewed: action.payload.viewed
            };
        case MARK_NOTIFICATION_AS_READ: {
            const updatedNotViewed = state.notificationsNotViewed.filter(notification => notification.id !== action.payload);
            const notificationToMove = state.notificationsNotViewed.find(notification => notification.id === action.payload);
            const updatedViewed = notificationToMove ? [...state.notificationsViewed, notificationToMove] : state.notificationsViewed;

            return {
                ...state,
                notificationsNotViewed: updatedNotViewed,
                notificationsViewed: updatedViewed,
            };
        }


        case MARK_NOTIFICATION_ALL_AS_READ: {
            const updatedViewed = [...state.notificationsViewed, ...state.notificationsNotViewed];
            return {
                ...state,
                notificationsNotViewed: [],
                notificationsViewed: updatedViewed,
            };
        }

        default:
            return state;
    }
};

export default reducer;
