import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { CostCenterEndpoint } from './APIConnection';
import { CostCenterCreateForm, CostCenterRepository } from '../models/CostCenter';

export class CostCentersFetchRepository extends GenericFetch implements CostCenterRepository {
    all(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: CostCenterEndpoint.all()
        });
    }

    // store(cost_center: CostCenterCreateForm, events: ServiceEvents): Promise<ServiceResponse> {
    //     return this.doPost({
    //         ...events,
    //         url: CostCenterEndpoint.store(),
    //         data: cost_center
    //     });
    // }

    store(service: CostCenterCreateForm, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: CostCenterEndpoint.store(),
            body: service
        });
    }

    edit(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: CostCenterEndpoint.edit(id)
        });
    }

    update(
        id: number,
        service: CostCenterCreateForm,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doPut({
            ...events,
            url: CostCenterEndpoint.update(id),
            body: service
        });
    }

    delete(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doDelete({
            ...events,
            url: CostCenterEndpoint.delete(id)
        });
    }

    create(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doCreate({
            ...events,
            url: CostCenterEndpoint.create()
        });
    }
}
