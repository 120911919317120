// src/contexts/notifications/types.ts
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_NOTIFICATION_ALL_AS_READ = 'MARK_NOTIFICATION_ALL_AS_READ';

import { Notification } from '../../../app/models/Notification';

export type NotificationContextState = {
    notificationsNotViewed : Notification[];
    setNotificationsNotViewed: (notifications: Notification[]) => void;
    notificationsViewed : Notification[];
    setNotificationsViewed: (notifications: Notification[]) => void;
    markNotificationAsRead: (notificationId: number) => void;
    markAllAsReadNotifications: () => void;
};

export type ReducerAction = {
    type: string;
    payload: any;
};
