import React, { useState } from 'react';
import FieldInput from '../../components/fields/FieldInput';
import Wrapper from './components/Wrapper';
import { ResetPasswordCredentials } from '../../app/models/Auth';
import useAuthService from '../../hooks/services/useAuthService';
import ButtonSaveForm from '../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import { Link, useHistory, useLocation } from 'react-router-dom';

const ResetPasswordPageStep2 = () => {
    const history = useHistory();

    const { fetchingResetPassword, resetPassword } = useAuthService();

    const [credentials, setCredentials] = useState<ResetPasswordCredentials>({
        password: '',
        password_confirmation: ''
    });

    const [errors, setErrors] = useState<object>({});

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const reset = () => {
        const encryptedId = sessionStorage.getItem('encryptedUserId');

        if (!encryptedId) {
            toast.error('Por favor, revalide el PIN.');
            history.push('/reset-password');
            return;
        }

        const payload = {
            ...credentials,
            encrypted_id: encryptedId
        };

        resetPassword(payload, {
            onSuccess: () => {
                toast.success('Contraseña actualizada con éxito');
                sessionStorage.removeItem('encryptedUserId');
                history.push('/login');
            },
            onError: (response) => {
                if (response.status === 401) {
                    toast.error('Debe validar el PIN de recuperación');
                    history.push('/reset-password');
                } else {
                    toast.error(response.message);
                }
            },
            onFieldError: (response) => setErrors(response.data.errors)
        });
    };

    return (
        <>
            <Wrapper
                title="Cambiar contraseña"
                description="Ingresa tu nueva contraseña y confírmala">
                <>
                    <FieldInput
                        label="Contraseña"
                        name="password"
                        type="password"
                        placeholder="********"
                        value={credentials.password}
                        onChange={onChange}
                        errors={errors}
                        required={true}
                        disabled={fetchingResetPassword}
                    />
                    <FieldInput
                        label="Repita contraseña"
                        name="password_confirmation"
                        type="password"
                        placeholder="********"
                        value={credentials.password_confirmation}
                        onChange={onChange}
                        errors={errors}
                        required={true}
                        disabled={fetchingResetPassword}
                    />
                    <br />
                    <ButtonSaveForm
                        title="Resetear Contraseña"
                        onClick={reset}
                        locked={fetchingResetPassword}
                        className="w-100"
                        icon="fas fa-plus"
                    />
                    <div className="text-center mt-2">
                        <Link to="/login" className="btn btn-link btn-block ">
                            ¿Ya tienes una cuenta? Inicia sesión
                        </Link>
                    </div>
                </>
            </Wrapper>
        </>
    );
};

export default ResetPasswordPageStep2;
