import React from 'react';
import { Link } from 'react-router-dom';

// const logoSmallDark =  require(`../../${TEMPLATE_CONFIG.APP.LOGO_SMALL_DARK}`);
// const logoSmallLight =  require(`../../${TEMPLATE_CONFIG.APP.LOGO_SMALL_LIGHT}`);
//
// const logoBigDark =  require(`../../${TEMPLATE_CONFIG.APP.LOGO_BIG_DARK}`);
// const logoBigLight =  require(`../../${TEMPLATE_CONFIG.APP.LOGO_BIG_LIGHT}`);
import logoSmallDark from '../../assets/images/logo.png';
import logoBigDark from '../../assets/images/logo.png';
import logoSmallLight from '../../assets/images/logo.png';
import logoBigLight from '../../assets/images/logo.png';

const SidebarBrand = () => {
    return (
        <div className="navbar-brand-box d-lg-block d-md-none">
            <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                    <img src={logoSmallDark} alt="" height="22" />
                </span>

                <span className="logo-lg">
                    <img src={logoBigDark} alt="" height="45" />
                </span>
            </Link>

            <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                    <img src={logoSmallLight} alt="" height="22" />
                </span>
                <span className="logo-lg">
                    <img src={logoBigLight} alt="" height="45" />
                </span>
            </Link>
        </div>
    );
};

export default SidebarBrand;
