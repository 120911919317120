import React, { useState } from 'react';
import FieldInput from '../../components/fields/FieldInput';
import Wrapper from './components/Wrapper';
import useAuthService from '../../hooks/services/useAuthService';
import ButtonSaveForm from '../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const ForgotPasswordPage = () => {
    const history = useHistory();

    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const { fetchingForgotPassword, forgotPassword } = useAuthService();

    const [email, setEmail] = useState<string>('');

    const [errors, setErrors] = useState<object>({});
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEmail(value);
    };

    const send = () => {
        forgotPassword(email, {
            onSuccess: (response) => {
                setShowSuccess(true);
            },
            onError: (response) => {
                toast.error(response.message);
            },
            onFieldError: (response) => setErrors(response.data.errors)
        }).then();
    };

    return (
        <>
            <Wrapper
                title="Recuperar contraseña"
                description="Ingresa tu correo electrónico y te enviaremos un enlace para que puedas recuperar tu contraseña."
            >
                {showSuccess ? (
                    <>
                        <div className="alert alert-success">
                            <p>Se ha enviado un correo de confirmación a tu cuenta de correo.</p>
                        </div>
                        <br />
                        <ButtonSaveForm
                            onClick={() => history.push('/reset-password')}
                            title="Ingresar código de recuperación"
                            className="w-100"
                            icon="fa fa-key"
                        />
                    </>
                ) : (
                    <>
                        <FieldInput
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={onChange}
                            errors={errors}
                            required={true}
                            disabled={fetchingForgotPassword}
                        />

                        <br />
                        <ButtonSaveForm
                            title="Recuperar contraseña"
                            onClick={send}
                            locked={fetchingForgotPassword}
                            className="w-100"
                            icon="fa fa-envelope"
                        />
                        <div className="text-center mt-2">
                            <Link to="/login" className="btn btn-link btn-block ">
                                ¿Ya tienes una cuenta? Inicia sesión
                            </Link>
                        </div>
                    </>
                )}
            </Wrapper>
        </>
    );
};

export default ForgotPasswordPage;
