import React, { useContext } from 'react';
import { TemplateContext } from '../../contexts/template';

const ToggleSidebar = () => {
    const { toggleSidebar } = useContext(TemplateContext);

    return (
        <button
            type="button"
            onClick={toggleSidebar}
            className="btn btn-smm px-3 font-size-16 header-item"
            id="vertical-menu-btn"
        >
            <i className="fa fa-fw fa-bars" />
        </button>
    );
};

export default ToggleSidebar;
