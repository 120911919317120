import React from 'react';
import useFieldError from '../../hooks/useFieldError';

type Props = {
    label?: string;
    name: string;
    id?: string;
    max?: string;
    min?: string;
    placeholder?: string;

    nameFieldError?: string;
    value?: string | Date;
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>
    ) => void;
    errors?: object;
    disabled?: boolean;
    required?: boolean;
    className?: string;
    isMonth?: boolean;
};

const FieldDate = ({
    label,
    id,
    name,
    nameFieldError,
    max,
    min,
    placeholder,
    value,
    onChange,
    errors,
    disabled,
    required,
    className,
    isMonth = false
}: Props) => {
    const { error, clearError } = useFieldError(nameFieldError ?? name, errors);

    const _value = typeof value === 'string' ? value : value?.toISOString().split('T')[0];

    return (
        <div className={`${className ? className : ''} ${error ? 'has-danger' : ''}`}>
            {label && (
                <label htmlFor={id ?? name}>
                    {label} {required ? '*' : ''}
                </label>
            )}
            <input
                type={isMonth ? 'month' : 'date'}
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={id ?? name}
                name={name}
                placeholder={placeholder}
                value={_value}
                onChange={onChange}
                onBlur={clearError}
                disabled={disabled}
                required={required}
                max={max}
                min={min}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export default FieldDate;
