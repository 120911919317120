export const HOST = process.env.REACT_APP_API_URL;
export const API_VERSION = 'v1';
export const buildEndpoint = (path: string) => `${HOST}/${API_VERSION}/${path}`;

const AUTH_PATH = buildEndpoint('auth');
const CONTRACTS_PATH = buildEndpoint('contracts');
const PROVIDERS_PATH = buildEndpoint('providers');
const SERVICES_PATH = buildEndpoint('services');
const USERS_PATH = buildEndpoint('users');
const COST_CENTERS_PATH = buildEndpoint('cost-centers');
const CONTRACT_RULES_PATH = 'contract-rules';
const CONTRACT_PAYMENT_PATH = 'contract-payments';
const CONTRACT_PAYMENT_SERVICE_PATH = 'contract-payment-services';
const LESSORS_PATH = buildEndpoint('lessors');
const NOTIFICATIONS_PATH = buildEndpoint('notifications');
const SYSTEM_CONFIGURATIONS_PATH = buildEndpoint('system-configurations');

export const AuthEndpoint = {
    login: () => `${AUTH_PATH}/login`,
    register: () => `${AUTH_PATH}/register`,
    confirmEmail: (token: string) => `${AUTH_PATH}/confirm-email/${token}`,
    isAuthenticated: () => `${AUTH_PATH}/is-authenticated`,

    logout: () => `${AUTH_PATH}/logout`,
    forgotPassword: () => `${AUTH_PATH}/forgot-password`,
    resetPassword: () => `${AUTH_PATH}/reset-password`,
    me: () => `${AUTH_PATH}/me`,
    updatePassword: () => `${AUTH_PATH}/update-password`,
    updateProfile: () => `${AUTH_PATH}/update-profile`,
    updateAvatar: () => `${AUTH_PATH}/update-avatar`,
    validateRecoveryPin: () => `${AUTH_PATH}/validate-recovery-pin`
};

export const ContractEndpoint = {
    all: () => `${CONTRACTS_PATH}`,
    allContractPayments: (id: number) => `${CONTRACTS_PATH}/${id}/payments`,
    allContractsPayments: () => `${CONTRACTS_PATH}/payments`,
    excelContractsPayments: () => `${CONTRACTS_PATH}/payments/excel`,
    pdfContractsPayments: () => `${CONTRACTS_PATH}/payments/pdf`,
    allDocuments: (id: number) => `${CONTRACTS_PATH}/${id}/documents`,
    allHistory: (id: number) => `${CONTRACTS_PATH}/${id}/histories`,
    create: () => `${CONTRACTS_PATH}/create`,
    pdf: () => `${CONTRACTS_PATH}/pdf`,
    excel: () => `${CONTRACTS_PATH}/excel`,
    pdfContractPayments: (id: number) => `${CONTRACTS_PATH}/${id}/payments/pdf`,
    excelContractPayments: (id: number) => `${CONTRACTS_PATH}/${id}/payments/excel`,
    store: () => `${CONTRACTS_PATH}`,
    show: (id: number) => `${CONTRACTS_PATH}/${id}`,
    update: (id: number) => `${CONTRACTS_PATH}/${id}`,
    updateDocuments: (id: number) => `${CONTRACTS_PATH}/${id}/documents`,
    edit: (id: number) => `${CONTRACTS_PATH}/${id}/edit`,
    history: (id: number) => `${CONTRACTS_PATH}/${id}/history`,
    delete: (id: number) => `${CONTRACTS_PATH}/${id}`,
    syncServices: (id: number) => `${CONTRACTS_PATH}/${id}/sync-services`
};

export const ContractRuleEndpoint = {
    all: (contract_id: number) => `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_RULES_PATH}`,
    create: (contract_id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_RULES_PATH}/create`,
    store: (contract_id: number) => `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_RULES_PATH}`,
    show: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_RULES_PATH}/${id}`,
    update: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_RULES_PATH}/${id}`,
    edit: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_RULES_PATH}/${id}/edit`,
    delete: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_RULES_PATH}/${id}`
};

export const ContractPaymentEndpoint = {
    all: (contract_id: number) => `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}`,
    create: (contract_id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/create`,
    store: (contract_id: number) => `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}`,
    show: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${id}`,
    update: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${id}`,
    edit: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${id}/edit`,
    delete: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${id}`,
    recalculateAmountContractPayment: (contract_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${id}/recalculate-amount-contract-payment`
};

export const ContractPaymentServiceEndpoint = {
    all: (contract_id: number, contract_payment_id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${contract_payment_id}/${CONTRACT_PAYMENT_SERVICE_PATH}/edit`,
    edit: (contract_id: number, contract_payment_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${contract_payment_id}/${CONTRACT_PAYMENT_SERVICE_PATH}/${id}/edit`,
    update: (contract_id: number, contract_payment_id: number, id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${contract_payment_id}/${CONTRACT_PAYMENT_SERVICE_PATH}/${id}`,
    sync: (contract_id: number, contract_payment_id: number) =>
        `${CONTRACTS_PATH}/${contract_id}/${CONTRACT_PAYMENT_PATH}/${contract_payment_id}/${CONTRACT_PAYMENT_SERVICE_PATH}/sync`
};

export const ProviderEndpoint = {
    all: () => `${PROVIDERS_PATH}`,
    store: () => `${PROVIDERS_PATH}`,
    show: (id: number) => `${PROVIDERS_PATH}/${id}`,
    update: (id: number) => `${PROVIDERS_PATH}/${id}`,
    edit: (id: number) => `${PROVIDERS_PATH}/${id}/edit`,
    delete: (id: number) => `${PROVIDERS_PATH}/${id}`,
    create: () => `${PROVIDERS_PATH}/create`
};

export const ServiceEndpoint = {
    all: () => `${SERVICES_PATH}`,
    store: () => `${SERVICES_PATH}`,
    show: (id: number) => `${SERVICES_PATH}/${id}`,
    update: (id: number) => `${SERVICES_PATH}/${id}`,
    edit: (id: number) => `${SERVICES_PATH}/${id}/edit`,
    delete: (id: number) => `${SERVICES_PATH}/${id}`
};

export const UserEndpoint = {
    all: () => `${USERS_PATH}`,
    store: () => `${USERS_PATH}`,
    show: (id: number) => `${USERS_PATH}/${id}`,
    update: (id: number) => `${USERS_PATH}/${id}`,
    edit: (id: number) => `${USERS_PATH}/${id}/edit`,
    delete: (id: number) => `${USERS_PATH}/${id}`
};

export const LessorEndpoint = {
    all: () => `${LESSORS_PATH}`,
    store: () => `${LESSORS_PATH}`,
    show: (id: number) => `${LESSORS_PATH}/${id}`,
    update: (id: number) => `${LESSORS_PATH}/${id}`,
    edit: (id: number) => `${LESSORS_PATH}/${id}/edit`,
    delete: (id: number) => `${LESSORS_PATH}/${id}`,
    create: () => `${LESSORS_PATH}/create`
};

export const NotificationEndpoint = {
    all: () => `${NOTIFICATIONS_PATH}`,
    show: (id: number) => `${NOTIFICATIONS_PATH}/${id}`,
    getNotifications: () => `${NOTIFICATIONS_PATH}/get-notifications`,
    markAsRead: (id: number) => `${NOTIFICATIONS_PATH}/${id}/mark-as-read`,
    markAllAsRead: () => `${NOTIFICATIONS_PATH}/mark-all-as-read`,
};

export const CostCenterEndpoint = {
    all: () => `${COST_CENTERS_PATH}`,
    store: () => `${COST_CENTERS_PATH}`,
    show: (id: number) => `${COST_CENTERS_PATH}/${id}`,
    update: (id: number) => `${COST_CENTERS_PATH}/${id}`,
    edit: (id: number) => `${COST_CENTERS_PATH}/${id}/edit`,
    delete: (id: number) => `${COST_CENTERS_PATH}/${id}`,
    create: () => `${COST_CENTERS_PATH}/create`
};

export const SystemConfigurationEndpoint = {
    all: () => `${SYSTEM_CONFIGURATIONS_PATH}`,
    update: (id: number) => `${SYSTEM_CONFIGURATIONS_PATH}/${id}`,
    edit: (id: number) => `${SYSTEM_CONFIGURATIONS_PATH}/${id}/edit`,
};
