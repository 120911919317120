import React from 'react';

type CardProps = {
    children?: React.ReactNode;
    button?: any;
    title?: string;
    classTitle?: string;
};

const DefaultCard = ({ children, title, classTitle, button }: CardProps) => {
    return (
        <div className="card">
            <div className="card-body">
                {title ? (
                    <div className="row">
                        <div className="col-10">
                            <h5 className={`font-size-14 text-uppercase mb-3 ${classTitle}`}>
                                {title}
                            </h5>
                        </div>
                        <div className="col-2 text-end">{button}</div>
                    </div>
                ) : null}
                {children}
            </div>
        </div>
    );
};

export default DefaultCard;
