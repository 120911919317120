import React from 'react';

import logoIconDark from '../../assets/images/logo.png';
import logoIconLight from '../../assets/images/logo.png';

import { Link } from 'react-router-dom';

const NavbarBrand = () => {
    return (
        <div className="navbar-brand-box d-lg-none d-md-block">
            <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                    <img src={logoIconDark} alt="" height="45" />
                </span>
            </Link>

            <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                    <img src={logoIconLight} alt="" height="45" />
                </span>
            </Link>
        </div>
    );
};

export default NavbarBrand;
