import React, { useContext } from 'react';
import { TemplateContext } from '../../contexts/template';

type Props = {
    text?: string;
};

const FullScreenLoading = ({ text = 'Cargando...' }: Props) => {
    const { showingFullScreenLoader, themeMode } = useContext(TemplateContext);

    return showingFullScreenLoader ? (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: themeMode === 'dark' ? '#343a40' : '#fff'
            }}
        >
            <div className="text-center">
                <div
                    className={`spinner-border text-${themeMode === 'dark' ? 'light' : 'primary'}`}
                    role="status"
                >
                    <span className="sr-only">Loading...</span>
                </div>
                <p className={`pt-3 text-${themeMode === 'dark' ? 'light' : 'primary'}`}>{text}</p>
            </div>
        </div>
    ) : null;
};

export default FullScreenLoading;
