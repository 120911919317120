import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { SystemConfigurationEndpoint } from './APIConnection';
import { SystemConfiguration } from '../models/SystemConfiguration';

export class SystemConfigurationFetchRepository extends GenericFetch {
    all(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: SystemConfigurationEndpoint.all()
        });
    }

    edit(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: SystemConfigurationEndpoint.edit(id)
        });
    }

    update(id: number, systemConfiguration: SystemConfiguration, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPut({
            ...events,
            url: SystemConfigurationEndpoint.update(id),
            body: systemConfiguration
        });
    }
}
