
export type SystemConfiguration = {
    id: number;
    days_payment_date_alert: number;
    days_contract_end_alert: number;
};

export const initialSystemConfiguration: SystemConfiguration = {
    id: 0,
    days_payment_date_alert: 0,
    days_contract_end_alert: 0,
};