import { useState } from 'react';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import { CostCentersFetchRepository } from '../../app/services/CostCentersFetchRepository';
import { CostCenterCreateForm, CostCenterUpdateForm } from '../../app/models/CostCenter';

const useCostCentersService = () => {
    const repository = new CostCentersFetchRepository();

    const [fetchingGetCostCenters, setFetchingGetCostCenters] = useState(false);
    const [fetchingStoreCostCenter, setFetchingStoreCostCenter] = useState(false);
    const [fetchingEditCostCenter, setFetchingEditCostCenter] = useState(false);
    const [fetchingUpdateCostCenter, setFetchingUpdateCostCenter] = useState(false);
    const [fetchingDeleteCostCenter, setFetchingDeleteCostCenter] = useState(false);
    const [fetchingCreateCostCenter, setFetchingCreateCostCenter] = useState(false);

    const getCostCenters = async (events: ServiceEvents = {}) => {
        setFetchingGetCostCenters(true);
        try {
            return repository.all(events).finally(() => {
                setFetchingGetCostCenters(false);
            });
        } catch (error) {
            setFetchingGetCostCenters(false);
        }
    };

    const storeCostCenter = async (service: CostCenterCreateForm, events: ServiceEvents = {}) => {
        setFetchingStoreCostCenter(true);
        try {
            return repository.store(service, events).finally(() => {
                setFetchingStoreCostCenter(false);
            });
        } catch (error) {
            setFetchingStoreCostCenter(false);
        }
    };

    const editCostCenter = async (id: number, events: ServiceEvents = {}) => {
        setFetchingEditCostCenter(true);
        try {
            return repository.edit(id, events).finally(() => {
                setFetchingEditCostCenter(false);
            });
        } catch (error) {
            setFetchingEditCostCenter(false);
        }
    };

    const updateCostCenter = async (
        id: number,
        service: CostCenterUpdateForm,
        events: ServiceEvents = {}
    ) => {
        setFetchingUpdateCostCenter(true);
        try {
            return repository.update(id, service, events).finally(() => {
                setFetchingUpdateCostCenter(false);
            });
        } catch (error) {
            setFetchingUpdateCostCenter(false);
        }
    };

    const deleteCostCenter = async (id: number, events: ServiceEvents = {}) => {
        setFetchingDeleteCostCenter(true);
        try {
            return repository.delete(id, events).finally(() => {
                setFetchingDeleteCostCenter(false);
            });
        } catch (error) {
            setFetchingDeleteCostCenter(false);
        }
    };

    const createCostCenter = async (events: ServiceEvents = {}) => {
        setFetchingCreateCostCenter(true);
        try {
            return repository.create(events).finally(() => {
                setFetchingCreateCostCenter(false);
            });
        } catch (error) {
            setFetchingCreateCostCenter(false);
        }
    };

    return {
        fetchingGetCostCenters,
        fetchingStoreCostCenter,
        fetchingEditCostCenter,
        fetchingUpdateCostCenter,
        fetchingDeleteCostCenter,
        fetchingCreateCostCenter,
        getCostCenters,
        storeCostCenter,
        editCostCenter,
        updateCostCenter,
        deleteCostCenter,
        createCostCenter
    };
};

export default useCostCentersService;
