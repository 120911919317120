import React, { ReactNode } from 'react';
import './assets/scss/theme.scss';

import Footer from './layouts/Footer';
import TemplateProvider from './contexts/template';
import Header from './layouts/Header';
import Sidebar from './layouts/Sidebar';
import FullScreenLoading from './layouts/components/FullScreenLoading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
    children: ReactNode;
    withWrapper?: boolean;
};

const Template = ({ children, withWrapper = true }: Props) => {
    return (
        <TemplateProvider>
            <FullScreenLoading />
            {withWrapper ? (
                <div id="layout-wrapper">
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">{children}</div>
                        </div>
                    </div>
                    <Footer />
                </div>
            ) : (
                <>{children}</>
            )}
            <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </TemplateProvider>
    );
};

export default Template;
