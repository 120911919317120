export enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}

export enum RequestType {
    JSON = 'json',
    MULTIPART = 'multipart',
    FORM_DATA = 'form-data'
}

export enum ContentType {
    JSON = 'application/json',
    MULTIPART = 'multipart/form-data',
    FORM_DATA = 'application/x-www-form-urlencoded'
}

export enum ResponseStatus {
    UNKNOWN = 'unknown',
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'info',
    FIELDS_VALIDATION = 'fields_validation'
}
