// welcome to the config of this template
import { LocalStorageConfig, TemplateConfig, ThemeType } from './types';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';

export const TEMPLATE_CONFIG: TemplateConfig = {
    APP: {
        NAME: 'Sistema Melt Contratos',
        VERSION: '1.0.0',
        AUTHOR: 'innovaweb',
        AUTHOR_URL: 'https://innovaweb.cl',
        COPYRIGHT: '© ' + new Date().getFullYear() + ' Innovaweb',
        COPYRIGHT_URL: 'https://innovaweb.cl',
        DESCRIPTION: 'Sistema Melt Contratos',
        KEYWORDS: 'Sistema Melt Contratos',
        FAVICON: 'assets/images/favicon.png',
        LOGO_BIG_LIGHT: 'assets/images/logo-icon-light.svg',
        LOGO_BIG_DARK: 'assets/images/logo-icon-dark.svg',
        LOGO_SMALL_LIGHT: 'assets/images/logo-icon-light.svg',
        LOGO_SMALL_DARK: 'assets/images/logo-icon-dark.svg',
        LOGO: 'Sistema Melt Contratos'
    },
    THEME_MODE: ThemeType.LIGHT, // light | dark
    SIDEBAR: [
        {
            name: 'MENU',
            type: 'title'
        },
        // {
        //     name: 'Inicio',
        //     icon: 'bx bx-home-circle',
        //     path: '/',
        //     type: 'item'
        // },
        {
            name: 'Contratos',
            icon: 'bx bx-clipboard',
            path: '/contracts',
            type: 'item'
        },
        // {
        //     name: 'Pagos de Contratos',
        //     icon: 'bx bx-clipboard',
        //     path: '/contracts-payments',
        //     type: 'item'
        // },
        {
            name: 'Razones Sociales',
            icon: 'bx bx-store',
            path: '/cost_centers',
            type: 'item'
        },

        {
            name: 'Proveedores',
            icon: 'bx bx-bulb',
            path: '/providers',
            type: 'item'
        },
        {
            name: 'Arrendatarios',
            icon: 'bx bx-user',
            path: '/lessors',
            type: 'item'
        },
        // {
        //     name: 'ADMINISTRACIÓN',
        //     type: 'title'
        // },
        {
            name: 'Usuarios',
            icon: 'bx bx-user',
            path: '/users',
            type: 'item'
        },
        {
            name: 'Notificaciones',
            icon: 'bx bx-bell',
            path: '/notifications',
            type: 'item'
        },
        {
            name: 'Configuración del Sistema',
            icon: 'bx bx-cog',
            path: '/system-configurations',
            type: 'item'
        }
    ]
};

export const LOCAL_STORAGE: LocalStorageConfig = {
    AUTH_USER: '_MELT_CONTRACTS_PWA_AT_ec82cccc1563b15bda8748b13eba5576',
    AUTH_TOKEN: '_MELT_CONTRACTS_PWA_ATK_667e297dbf1a3b67f0adc256ad07e041',
    AUTH_ROLES: '_MELT_CONTRACTS_PWA_AR_0e1b0c3b0f1b0c3b0f1b0c3b0f1b0c3b',
    AUTH_PERMISSIONS: '_MELT_CONTRACTS_PWA_AP_0e1b0c3b0f1b0c3b0f1b0c3b0f1b0c3b',
    TEMPLATE_CONFIG: '_MELT_CONTRACTS_PWA_TC_0e1b0c3b0f1b0c3b0f1b0c3b0f1b0c3b'
};

export const select2Config = (hasError = false) =>
({
    control: (base: any) => ({
        ...base,
        // height: 33,
        minHeight: 35,
        borderRadius: '3px',
        border: hasError ? '1px solid red' : '1px solid #ced4da',
    }),
    valueContainer: (base: any) => ({
        ...base,
        minHeight: 35,
        zIndex: 2,
        // marginTop : '-2px'
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        minHeight: 35,
        padding: '5px',
        zIndex: 2,
        // marginTop : '-2px'
    }),
    multiValue: (base: any) => ({
        ...base,
        background: '#1b7ee0',
        color: 'white',
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        background: '#1b7ee0',
        color: 'white',
    })
} as StylesConfig);

export const defaultSwalStyleButtons = {
    customClass: {
        confirmButton: 'waves-effect btn btn-smm btn-success mx-1 px-3',
        cancelButton: 'waves-effect btn btn-outline-light btn-smm mx-1 px-3'
    },
    buttonsStyling: false
};
