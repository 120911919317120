import React from 'react';
import Select from 'react-select';
import { select2Config } from '../../template/config';
import Creatable from 'react-select/creatable';

interface SelectTwoProps {
    name?: string;
    id?: string;
    onFocus?: (value: any) => void;
    inputValue?: any;
    options: SelectTwoOption[];
    onChange?: (value: any) => void;
    placeholder?: string;
    hasError?: boolean;
    isMulti?: boolean;
    isAddableOption?: boolean;
    disabled?: boolean;
}

interface SelectTwoOption {
    value: string | number | null;
    label: string;
}

export const SelectTwoMapperOptions = (options: any[], valueName = 'id', labelNames = ['name']) => {
    return options.map((option: any) => {
        const labels: string[] = [];
        labelNames.map((labelName: string) => {
            labels.push(option[labelName]);
        });
        return {
            value: option[valueName],
            label: labels.join(' - ')
        };
    });
};

const selectedOption = (options: SelectTwoOption[], value: string | number | null) => {
    return options.find((option: SelectTwoOption) => option.value == value) || null;
};

const SelectTwo = ({
    name,
    id,
    options,
    onChange,
    placeholder = 'Seleccione una opción',
    inputValue = '',
    hasError = false,
    onFocus,
    isMulti = false,
    isAddableOption = false,
    disabled = false
}: SelectTwoProps) => {
    return (
        <>
            {isAddableOption ? (
                <Creatable
                    id={id}
                    name={name}
                    options={options}
                    placeholder={placeholder}
                    styles={select2Config(hasError)}
                    isMulti={isMulti}
                    onChange={onChange}
                    onFocus={onFocus}
                    isDisabled={disabled}
                    value={
                        Array.isArray(inputValue) ? inputValue : selectedOption(options, inputValue)
                    }
                />
            ) : (
                <Select
                    id={id}
                    name={name}
                    options={options}
                    placeholder={placeholder}
                    styles={select2Config(hasError)}
                    isMulti={isMulti}
                    onChange={onChange}
                    onFocus={onFocus}
                    isDisabled={disabled}
                    value={
                        Array.isArray(inputValue) ? inputValue : selectedOption(options, inputValue)
                    }
                />
            )}
        </>
    );
};

export default SelectTwo;
